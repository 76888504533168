<div class="modal-header">
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="pop-up-content section">
		<div class="resumeVideoItemPanel playingResumeVideo">
		  <div class="resumeVideoPlayerWrapper">
			<div class="html5videoPlayback">
			  <video src='{{sectionData.video_url === undefined || sectionData.video_url === "" ? "../../../../../../assets/images/profile-icon-ellipse.png" : utilityService.getCdnUrl(sectionData.video_url)}}' type="video/mp4" controls autoplay></video>
			</div>
		  </div>
		</div>
		<div class="section-header">
			<div class="section-icon">
			  <div [style.background-image]="'url(' + sectionData.logo_url + ')'" class="img"></div>
			</div>
			<!-- educations section -->
			<div class="header-title-wrapper" *ngIf="videoSection === 1">
			  <p class="time-period">{{sectionData.start_month}}/{{sectionData.start_year}} -
				{{sectionData.end_month}}/{{sectionData.end_year}}</p>
			  <p class="position">{{sectionData.university  | ellipsis: 40}}</p>
			  <p class="company">{{sectionData.major  | ellipsis: 45}}</p>
			  <p class="subtitle-one">
				{{sectionData.fieldofstudy}}{{(sectionData.gpa > 0) ?' - GPA: '+(sectionData.gpa | number:'1.2-2') : ''}}</p>
			</div>
			<!--  certification section-->
			<div class="header-title-wrapper" *ngIf="videoSection === 2">
			  <p class="time-period">{{sectionData.start_year}} - <span *ngIf="sectionData.end_year === 0">Present</span><span
				  *ngIf="sectionData.end_year !== 0">{{sectionData.end_year}}</span></p>
			  <p class="position">{{sectionData.company | ellipsis: 40}}</p>
			  <p class="company">{{sectionData.cert_name | ellipsis: 45}}</p>
			  <p class="subtitle-one">{{sectionData.license_no | ellipsis: 20}}</p>
			</div>
			<!-- exp section -->
			<div class="header-title-wrapper" *ngIf="videoSection === 3">
			  <!-- {{sectionData}} -->
			  <p class="time-period">{{sectionData.start_month}}/{{sectionData.start_year}} - <span
				  *ngIf="sectionData.end_year !== 0">{{sectionData.end_month}}/{{sectionData.end_year}}</span> <span
				  *ngIf="sectionData.end_year === 0">Present</span></p>
			  <p class="position">{{sectionData.company_name | ellipsis: 40}}</p>
			  <p class="company">{{sectionData.position | ellipsis: 45}}</p>
			</div>
		</div>
	</div>
</div>
