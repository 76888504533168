import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutocompleteService } from '../../../core/services/autocomplete.service';
import { EventService } from '../../../core/services/event.service';

@Component({
	selector: 'question-auto-complete',
	templateUrl: './question-auto-complete.component.html',
	styleUrls: ['./question-auto-complete.component.scss']
})
export class QuestionAutoCompleteComponent implements OnInit, OnChanges {

	@Input() placeholder: string = '';
	@Input() searchedQuestion: string = '';
	@Input() setvalidate: any;
	@Output() questionChoosen: EventEmitter<string> = new EventEmitter<string>();

	public foundError: any;
	public searchText: any;

	public suggestionsDropMenu: boolean = false;
	public suggestions: any = null;


	constructor(private _eventService: EventService,
		private _autoCompleteService: AutocompleteService) { }

	ngOnInit(): void {

		this.foundError = this.setvalidate;
		// set selected place
		if (this.searchedQuestion.length) {
			this.searchText = this.searchedQuestion;
		}

		this._eventService.subscribe('keyword-null', () => {
			this.foundError = true;
		});

		this.setQuestion(this.searchText);

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.setvalidate?.isFirstChange() && changes.setvalidate?.currentValue) {
			this.foundError = true;
		}
	}

	// search text call when ng change
	searchQuestion() {
		// check typed text length
		if (this.searchText?.length > 0) {
			this.setQuestion(this.searchText);
			this._autoCompleteService.searchQuestions(encodeURIComponent(this.searchText)).subscribe((response: any) => {
				// check response status
				if (response.status === 200 && response.Data.length) {
					this.suggestionsDropMenu = true;
					this.suggestions = response.Data;
				} else {
					this.clearSuggestionMenu();
				}
			});
		} else {
			this.clearSuggestionMenu();
		}
	}

	setQuestion(question: string): void {
		this.searchText = question ? question.trim() : undefined;
		this.foundError = false;
		this.suggestionsDropMenu = false;
		this.suggestions = [];
		this.questionChoosen.emit(this.searchText);
	}

	clearSuggestionMenu(): void {
		setTimeout(()=>{
			this.suggestionsDropMenu = false;
			this.suggestions = [];
		}, 500);
	}

}
