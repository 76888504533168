import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from '../../../core/services/utility.service';
import { UserService } from '../../../core/services/user.service';

@Component({
	selector: 'select-candidates',
	templateUrl: './select-candidates.component.html',
	styleUrls: ['./select-candidates.component.scss']
})
export class SelectCandidatesComponent implements OnInit {

	@Input() showStepUpWrapper: boolean = true;
	@Input() title: string = 'Whom do you want to Interview?';

	@Output() moveForward: EventEmitter<any> = new EventEmitter<any>();

	public onLoadState: boolean = true;
	public selected: any[] = [];
	public selectedItems: any[] = [];
	public emptySelectFound: boolean = true;
	public selectAllBox: any = {};
	public listData: any[] = [];
	public selectedValue: any;
	public selectListCombo: any = '';
	public candidateList: any[] = [];
	// set combo disable until data load
	public comboStatus: boolean = true;

	private searchTimer: any;
	private _scrollPage: number = 0;
	private _searchKeyword: any = null;
  private _haveMoreListdata: boolean = false;

	constructor(public utilityService: UtilityService,
		private _userService: UserService) { }

	ngOnInit(): void {
		this._getListData(this._searchKeyword, this._scrollPage);
	}

	private _getListData(keyword: any, page: any): void {
		this._userService.getListsData(false, keyword, page).subscribe((response: any) => {
			this.comboStatus = false;
			if (response.Code === 200)
			{ if (response.Data.SavedList.length > 0) {
				this._haveMoreListdata = true; }
				if (page === 0)
				{ this.listData = response.Data.SavedList;}
				else { this.listData.push(...response.Data.SavedList); }
			if (this.listData.length > 0) {
				this.selectedValue = this.listData[0].List_id;
			}
			if (this._haveMoreListdata && response.Data.SavedList.length < 10 && this._scrollPage === 0)
			{this.loadMoreListData();}
		}
		});
	}

	// select list
	selectList(): void {
		// change state view users
		this.onLoadState = false;
		this.selectAllBox.isAllSelected = false;
		this.selectedItems.length = 0;
		this.selected.length = 0;
		if (this.selectListCombo !== '') {
			this.emptySelectFound = false;
			this.listData.forEach((value: any, key: any) => {
				if (value.List_id === Number(this.selectListCombo)) {
					this.candidateList = value.Candidates;
				}
			});
		} else {
			this.emptySelectFound = true;
		}
		if (this.candidateList) {
			this.selectAllBox.isAllSelected = this.candidateList.every((candidate: any) => {
				return this.selected[candidate.id];
			});
		}
	}

	// click select all
	toggleAll(isAllSelectedStatus: boolean): void {
		this.selectAllBox.isAllSelected = isAllSelectedStatus;
		this.candidateList.forEach((candidate: any, key: any) => {
			this.selected[candidate.id] = isAllSelectedStatus;
		});
		// call add to selected items
		this._addToSelectedItem();
	}

	// click one check box
	optionToggled(): void {
		this.selectAllBox.isAllSelected = this.candidateList.every((candidate: any) => {
			return this.selected[candidate.id];
		})
		// call add to selected items
		this._addToSelectedItem();
	}

	// add to selected items
	private _addToSelectedItem(): void {
		this.selectedItems.length = 0;
		this.utilityService.convertToArray(this.selected).forEach((candidate: any) => {
			if (candidate.value) {
				this.candidateList.forEach((candidateData: any, key: any) => {
					if (Number(candidate.key) === candidateData.id) {
						if (this.selectedItems.indexOf(candidate !== -1)) {
							this.selectedItems.push(candidateData);
						}
					}
				});
			}
		});
	}
 
	loadMoreListData(): void {
		if (this._haveMoreListdata) {
			this._haveMoreListdata = false;
			this._scrollPage += 1;
			this._getListData(this._searchKeyword, this._scrollPage);
		} else {
			console.log('No list data to load');
		}
	}

	onSearch(event: { term: string; items: any[] }) {
		this.listData.length = 0;
		let keyword = !event.term ? null : event.term;
		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._getListData(keyword, 0);
		}, 500);
		this._scrollPage = 0;
		this._searchKeyword = keyword;
	}
}
