<div class="search">
	<div class="search-container">
		<textarea class="form-control"
			id="textarea"
			rows="3"
			[(ngModel)]="searchText"
			(ngModelChange)="searchQuestion()"
			[ngClass]="{'error' : foundError}"
			placeholder="{{placeholder}}"
			[maxlength]="500"
			(blur)="clearSuggestionMenu()"
			autoExpandText>
		</textarea>
		<div class="autocomplete" *ngIf="suggestionsDropMenu">
			<ul class="list-group">
				<li class="list-group-item" *ngFor="let item of suggestions" (click)="setQuestion(item)">{{item}}</li>
			</ul>
		</div>
	</div>
</div>
