import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'confirm-alert',
	templateUrl: './confirm-alert.component.html',
	styleUrls: ['./confirm-alert.component.scss']
})
export class ConfirmAlertComponent {

	@Input() header: string = 'Information';
	@Input() msg: string = '';
	@Input() contentType = 'Text';
	@Input() showMessageBox: boolean = false;

	@Input() showYesButton: boolean = true;
	@Input() showNoButton: boolean = true;

	@Input() yesButtonText: string = 'Yes';
	@Input() noButtonText: string = 'No';

	@Input() yesButtonClass: string = '';
	@Input() noButtonClass: string = '';

	public message: string = '';

	constructor(public modal: NgbActiveModal) { }

	remaining(): number {
		return 2000 - this.message.length;
	}

	yes(): void {
		if (this.showMessageBox) {
			this.modal.close(this.message);
		} else {
			this.modal.close();
		}
	}

	no(): void {
		this.modal.dismiss();
	}

}
