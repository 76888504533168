import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShowApplicantComponent } from '../components/show-applicant/show-applicant.component';

@Directive({
	selector: '[applicantsView]'
})
export class ApplicantsViewDirective implements OnInit {

	@Input() jobid: any = null;
	@Input() jobtitle: any = null;
	@Input() jobrefid: any = null;
	@Input() jobBoardWidgetData: any[] = [];

	private _vacancyID: any = null;

	public jobdata: any = {};

	constructor(private _modalService: NgbModal) {

	}

	ngOnInit(): void {
		this._vacancyID = this.jobid;

		this.jobdata.job_ref_id = this.jobrefid;
		this.jobdata.job_title = this.jobtitle;
	}

	openModal(): void {
		let modal = this._modalService.open(ShowApplicantComponent, { backdrop: 'static', keyboard: false, windowClass: 'application show-applicants-popup'});
		modal.componentInstance.jobid = this.jobid;
		modal.componentInstance.jobtitle = this.jobtitle;
		modal.componentInstance.jobdata = this.jobdata;
		modal.componentInstance.jobrefid = this.jobrefid;
		modal.componentInstance.vacancyID = this._vacancyID;
		modal.componentInstance.jobBoardWidgetData = this.jobBoardWidgetData;
	}

	@HostListener('click', ['$event']) onClick($event: any) {
		this.openModal();
	}
}
