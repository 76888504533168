import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../core/services/user.service';
import { UtilityService } from '../../../core/services/utility.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'show-job-applicant',
	templateUrl: './show-job-applicant.component.html',
	styleUrls: ['./show-job-applicant.component.scss']
})
export class ShowJobApplicantComponent implements OnInit {

	@Input() userTitle: any = null;
	@Input() vacancyID: any = null;

	public jobBoardUrl: string = environment.urls.jobboardUrl;

	public allApplicantsData: any[] = [];

	public showAllApplicantsPreloader: boolean = true;

	constructor(public utilityService: UtilityService,
		private _modal: NgbActiveModal,
		private _userService: UserService) {

	}

	// Property of the directive main popup

	ngOnInit(): void {

		// call job board service to get all apllicants
		this._userService.getAllJobPositionsData(this.vacancyID).subscribe((response: any) => {
			// hide preloader
			this.showAllApplicantsPreloader = false
			if (response.status === 200 && response.Code === 200) {
			  this.allApplicantsData = response.Data.vacancy;
			}
		});

	}



	JobPopupPage(vid: any, vtitle: any): void {
		let url = this.jobBoardUrl + 'job/' + vid + '/jid';
        // console.log(url);
		window.open(url, 'popup', 'width=1100,height=900,left=300,top=150');
	}

	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
