import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'show-settings-zoom',
	templateUrl: './show-settings-zoom.component.html',
	styleUrls: ['./show-settings-zoom.component.scss']
})
export class ShowSettingsZoomComponent {

	constructor(private _modal: NgbActiveModal) { }

	openSettings(): void {
		window.open('/settings/interviews', '_blank');
	}

	closeThisDialog() {
		this._modal.dismiss();
	}

}
