import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../core/services/user.service';

@Component({
	selector: 'delete-list',
	templateUrl: './delete-list.component.html',
	styleUrls: ['./delete-list.component.scss']
})
export class DeleteListComponent implements OnInit {

	@Input() listID: any;
	@Input() listTitle: any;
	@Input() object: any;
	@Output() onUpdateMainList: EventEmitter<any> = new EventEmitter<any>();

	constructor(private _modal: NgbActiveModal,
		private _userService: UserService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {

	}

    skip(): void {
		this.closeThisDialog();
    }

	// delete list
	delete(): void {
		// create array
		let listData: any = {};
		listData.List_id = this.listID;
		listData.name = this.listTitle;

		// call delete List service
		this._userService.deleteList(listData).subscribe((response: any) => {
			// check response
			if (response.status === 200) {
				this.onUpdateMainList.emit(true);
			}
			this._toastr.success(`List deleted`);
			this.closeThisDialog();
		});
	}

	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
