<div class="col-lg-4 col-md-4 candidate candidate-$index">
	<div class="result-details">
		<div class="profile-picture pic-one">
			<div class="img" [style.background-image]="'url(' + utilityService.getCdnUrl(candidate.image_url) + ')'"></div>
		</div>
		<div class="applier">
			<h3 class="applier-name">{{candidate.name | ellipsis:35 }}</h3>
			<p class="job-title">{{candidate.position}}</p>
		</div>
	</div>
</div>
