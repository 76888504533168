<div class="modal-header">
	<h3 class="modal-title" id="modal-title">Oops!</h3>
</div>
<div class="modal-body">
	Your file cannot exceed <span *ngIf="errorStatus === 'message'">25MB</span><span *ngIf="errorStatus === 'offers'">10MB</span><span *ngIf="errorStatus === 'update_logo'">2MB</span>.
</div>
<div class="modal-footer text-right">
	<div class="button-wrapper">
		<button type="button" class="btn yes-button" (click)="close()">OK</button>
	</div>
</div>
