import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AutocompleteService } from '../../../core/services/autocomplete.service';
import { EventService } from '../../../core/services/event.service';

@Component({
	selector: 'search-city',
	templateUrl: './search-city.component.html',
	styleUrls: ['./search-city.component.scss']
})
export class SearchCityComponent implements OnInit, OnChanges {

	@Input() placeholder: string = '';
	@Input() searchedCity: string = '';
	@Input() setvalidate: any;
	@Input() hideicon: boolean = false;
	@Input() readOnly: boolean = false;
	@Output() placeChosen: EventEmitter<string> = new EventEmitter<string>();

	public foundError: any;

	public locationsDropMenu: boolean = false;
	public locations: any = null;

	public hasToShowCitySuggestions: boolean = true;

	constructor(private _eventService: EventService,
		private _autoCompleteService: AutocompleteService) { }

	ngOnInit(): void {

		this.foundError = this.setvalidate;

		this._eventService.subscribe('keyword-null', () => {
			this.foundError = true
		});

		this.setLocation();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.setvalidate?.isFirstChange() && changes.setvalidate?.currentValue) {
			this.foundError = true;
		}

		if (changes.searchedCity.currentValue) {
			this.setLocation();
		}
	}

	formatter = (result: any) => result.description;

	search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.locationChanged())
		)

	// call when location changed
	locationChanged() {
		// check typed text length
		if (this.searchedCity?.length > 0) {
			return this._autoCompleteService.getLocations(this.searchedCity).pipe(map((response: any) => {
				// check response status
				this.locationsDropMenu = true;
				this.locations = response;
				return this.locations;
			}));
		} else {
			return of([]);
		}
	}

	setLocation(): void {
		this.searchedCity = this.searchedCity ? this.searchedCity.trim() : '';
		this.placeChosen.emit(this.searchedCity);
	}

	// location clicked
	locationClicked(event: any): void {
		event.preventDefault();
		// assigns selected value to text input
		this.locationsDropMenu = false;
		this.searchedCity = event.item.description;
		this.setLocation();
	}

	// hide city suggestions
	hideCitySuggestions(): void {
		setTimeout(() => {
			this.hasToShowCitySuggestions = false;
		}, 100);
	}

}
