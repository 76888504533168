import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'preview-candidates',
	templateUrl: './preview-candidates.component.html',
	styleUrls: ['./preview-candidates.component.scss']
})
export class PreviewCandidatesComponent implements OnInit {

	@Input() candidate: any = {};

	constructor(public utilityService: UtilityService ) { }

	ngOnInit(): void {

	}

}
