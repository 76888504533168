<div class="modal-header">
	<h4 class="modal-title" id="modal-title">
		{{userTitle}}
	</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">Close</span>
	</button>
</div>
<div class="modal-body" style="overflow: scroll;">
	<div class="header pull-left">
		<div class="title note-title pull-left" style="font-size: 18px; padding: 0px;">My Notes:
		</div>
		<span class="pull-right" style="font-size: 18px; padding: 15px;">Private
			<i class="icon-info-sign pull-right" placement="top" ngbTooltip="Do not share my notes"></i></span>
		<input class="form-check pull-right" style="margin-top: 20px;" type="checkbox" [(ngModel)]="isPrivate" />
	</div>
	<div class="content pull-left" [hidden]="!showAllApplicantsPreloader">
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
	</div>
	<div class="send-message-wrapper" [hidden]="showAllApplicantsPreloader">
		<div class="form-group col-lg-7 col-md-7" style="width: 100%">
			<textarea class="form-control" style="background-color: aliceblue;" rows="7" [(ngModel)]="showNotes"
				required="true" limit-to="15000" placeholder="Limit is 15,000 characters"></textarea>
		</div>
	</div>
	<div class="message-content-wrapper">
		<button type="button" class="popup-footer-save-button pull-right"
			style="font-size: 20px; padding-right: 28px; padding-bottom: 10px;"
			(click)="saveNotesData(showNotes, isPrivate)">
			SAVE
		</button>
	</div>
	<div class="message-content-wrapper" [hidden]="teamNotes.length === 0">
		<label class=" title pull-left" style="font-size: 16px; padding-left: 15px;">My Team Notes:</label>
		<div class="inner-title">
			<div class="form-group col-lg-7 col-md-7" style="width: 100%; font-size: 16px;"
				*ngFor="let team of teamNotes">{{team.name}}'s Notes:
				<textarea class="form-control" rows="5"
					style="width: 100%; background-color: whitesmoke;">{{team.teamnotes}}</textarea>
			</div>
		</div>
	</div>
</div>
