import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { EventService } from "../../../core/services/event.service";
import { CalendarService } from "../../../core/services/calendar.service";
import * as moment from "moment";

@Component({
	selector: 'time-duration',
	templateUrl: './time-duration.component.html',
	styleUrls: ['./time-duration.component.scss']
})
export class TimeDurationComponent implements OnInit, OnChanges {

	@Input() selectedamount: any;
	@Input() timezonelist: any;
	@Input() timezonecombo: any;
	@Input() clikedTimeSlots: any[] = [];

	@Output() selectedTimeArray: EventEmitter<any> = new EventEmitter<any>();

	public data: any = {};
	public viewSelectedTimeSlots: any[] = [];
	public selectedIntervalTime: any;
	public timeslotSelect: any;

	public minDate: any = {
		day: new Date().getDate(),
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear()
	};

	private _tempTimeSlots: any[] = [];
	private _lastSelectedDate: any;
	private _selectedIntervalTime: any;
	private _selectedTimeZoneCombo: any;
	private _defaultTimeZoneVal: any;
	private _dateAccordingtoTime: any;
	private _calendarToSync: any = {};
	private _calendarBusyTimes: any = {};
	private _todayDateSetted: any;

	constructor(private _calendarService: CalendarService,
		private _eventService: EventService) { }

	ngOnInit(): void {
		this._eventService.subscribe('sync-calendar', (data: any) => {
			if (data.sync && !this._calendarToSync.hasOwnProperty(data.calendar.id)) {
				this._calendarToSync[data.calendar.id] = data.calendar;
				this.fetchCalendarBusySlots(this._calendarToSync[data.calendar.id])
			}
			if (!data.sync && this._calendarToSync.hasOwnProperty(data.calendar.id)) {
				delete this._calendarToSync[data.calendar.id];
				if (this._calendarBusyTimes.hasOwnProperty(data.calendar.id)) {
					delete this._calendarBusyTimes[data.calendar.id];
				}
			}
		});

		// listen broadcast and remove slots
		this._eventService.subscribe('removedTImeSlots', (data: any) => {
			this.viewSelectedTimeSlots[data.index].selected = false;
		});

		this._eventService.subscribe('countTimeSlots', (data: any) => {
			this.countTimeSlots(data);
		});

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.timezonecombo) {
			// waiitng for combox change
			this.updateTimeZoneCombo(changes.timezonecombo.currentValue, changes.timezonecombo.previousValue);
			if (Object.keys(this._calendarToSync).length) {
				for (let key in this._calendarToSync) {
					if (this._calendarToSync.hasOwnProperty(key)) {
						this.fetchCalendarBusySlots(this._calendarToSync[key]);
					}
				}
			}
		}

	}

	fetchCalendarBusySlots(calendar: any): void {
		let selectedDate: any = this.data ? this.data.embeddedDate : this._dateAccordingtoTime;
		let timeMin: string = moment(selectedDate).format('YYYY-MM-DD') + 'T00:00:00.00Z';
		let timeMax: string = moment(selectedDate).format('YYYY-MM-DD') + 'T23:59:59.00Z';

		// For google calendar
		if (calendar.calendar_type == 1) {
			let timeZone = 'GMT';
			if (this._selectedTimeZoneCombo) {
				timeZone += this._selectedTimeZoneCombo;
			} else {
				let tz: number = new Date().getTimezoneOffset();
				tz = (tz / 60) * -1;
				timeZone += tz > 0 ? '+' : '';
				timeZone += tz;
			}
			this._calendarService.getGoogleAccessToken(calendar.token).subscribe((token_response: any) => {
				this._calendarService.getGoogleCalenderBusyTime(calendar.calendar_id, timeMin, timeMax, timeZone, token_response.access_token).subscribe((response: any) => {
					this._calendarBusyTimes[calendar.id] = [];
					response.calendars[calendar.calendar_id].busy.forEach((item: any) => {
						this._calendarBusyTimes[calendar.id].push({
							startHour: item.start.slice(11, 13),
							startMinute: item.start.slice(14, 16),
							endHour: item.end.slice(11, 13),
							endMinute: item.end.slice(14, 16)
						});
					});
				}); // End get free busy time
			}); // End get access token
		}

		// For Ms calendar
		if (calendar.calendar_type == 2) {
			let timeZone: any = new Date().toString().match(/\((.*)\)/)?.pop();
			if (this._selectedTimeZoneCombo) {
				let _tz: any = this._selectedTimeZoneCombo.split(':');
				let tz: string = _tz[0] + "." + (_tz[1] == "30" ? "50" : _tz[1]);
				let tz_val: number = Number(tz) * 3600;
				for (let i = 0; i < this.timezonelist.length; i++) {
					if (Number(this.timezonelist[i].timezoneVal) == tz_val) {
						timeZone = this.timezonelist[i].Name;
						break;
					}
				}
			}

			this._calendarService.getMsAccessToken(calendar.token).subscribe((token_response: any) => {
				this._eventService.broadcast('update-ms-token', { token: token_response.refresh_token });
				this._calendarService.getMsCalenderBusyTime(calendar.calendar_owner, calendar.calendar_id, timeMin, timeMax, timeZone, token_response.access_token).subscribe((response: any) => {
					this._calendarBusyTimes[calendar.id] = [];
					response.value.forEach((item: any) => {
						this._calendarBusyTimes[calendar.id].push({
							startHour: item.start.dateTime.slice(11, 13),
							startMinute: item.start.dateTime.slice(14, 16),
							endHour: item.end.dateTime.slice(11, 13),
							endMinute: item.end.dateTime.slice(14, 16)
						});
					});
				});
			}); // End get access token
		}

	}


	updateTimeZoneCombo(newValue: any, oldValue: any): void {

		// set default timezone
		if (newValue === oldValue) {
			this._defaultTimeZoneVal = newValue;
		}

		// new values check
		if (newValue) {
			this._selectedTimeZoneCombo = newValue;
			// calender options
			let d: Date = new Date();
			let utc: number = d.getTime() + (d.getTimezoneOffset() * 60000);
			let offsetTime: any = moment(new Date(utc)).utcOffset(newValue);
			this._dateAccordingtoTime = offsetTime._d;
			this.data = { embeddedDate: moment(offsetTime._d).format('MM/DD/YYYY') };
			this.minDate = {
				day: new Date(this._dateAccordingtoTime).getDate(),
				month: new Date(this._dateAccordingtoTime).getMonth() + 1,
				year: new Date(this._dateAccordingtoTime).getFullYear()
			};
		}
	}

	// click date
	onTimeSet(newDate: any): void {
		this.updateTimeSet(newDate);
		if (Object.keys(this._calendarToSync).length) {
			for (let key in this._calendarToSync) {
				if (this._calendarToSync.hasOwnProperty(key)) {
					this.fetchCalendarBusySlots(this._calendarToSync[key]);
				}
			}
		}
	}

	viewDate(date: string): Date|undefined {
		if (date) {
			const value = date.split('/');
			return new Date(parseInt(value[2], 10), parseInt(value[0], 10)-1, parseInt(value[1], 10));
		}
		return undefined;
	}

	updateTimeSet(newDate: any): void {
		let recievedDate: string = newDate;
		this._lastSelectedDate = recievedDate;
		// set all slots as not clicked
		if (this.viewSelectedTimeSlots) {
			if (this.viewSelectedTimeSlots.length !== 0) {
				this.viewSelectedTimeSlots.forEach((value1: any, key1: any) => {
					this.viewSelectedTimeSlots[key1].selected = false;
				});
			}
		}

		this._tempTimeSlots.forEach((value: any, key: any) => {
			// check already added date are available in temp array
			if (value.date === recievedDate) {
				this.viewSelectedTimeSlots[value.index].selected = true;
			}
		});
	}

	checkAvailableTime(selectedTimeSlot: any, selectedDate: any): boolean {
		// set in first time, store current time and use it as global
		let a: Date;
		if (this._todayDateSetted === undefined) {
			a = new Date(selectedDate);
			this._todayDateSetted = moment(a).format('YYYY-MM-DD');
		} else {
			let c: Date = new Date(selectedDate);
			if (this._todayDateSetted === moment(c).format('YYYY-MM-DD')) {
				a = new Date();
			} else {
				a = new Date(selectedDate);
			}
		}

		let getDate: Date = new Date();
		// calculate time for combo selected time zone
		let utcTime = a.getTime() + (a.getTimezoneOffset() * 60000);
		let offsetTime: any = moment(new Date(utcTime)).utcOffset(this._selectedTimeZoneCombo);
		let b: Date = new Date(this._dateAccordingtoTime);

		let todayDate: string = moment(this._dateAccordingtoTime).format('YYYY-MM-DD');
		let recievedDate: string = moment(this.data.embeddedDate).format('YYYY-MM-DD');

		// start time
		let startTimeHours: number = Number(selectedTimeSlot.time.slice(0, 2));
		let startTimeMinutes: number = Number(selectedTimeSlot.time.slice(3, 5));
		let startStatus: any = selectedTimeSlot.time.slice(6, 8);
		// end time
		let endTimeHours: number = Number(selectedTimeSlot.time.slice(11, 13));
		let endTimeMinutes: number = Number(selectedTimeSlot.time.slice(14, 16));
		let endStatus: any = selectedTimeSlot.time.slice(17, 19);
		let currentHours: number = b.getHours();
		let currentMinutes: number = b.getMinutes();
		// add 12 hours if start time hours between 1 and 5
		if (startTimeHours >= 1 && startTimeHours <= 12) {
			if (startStatus === 'PM' && startTimeHours !== 12) {
				startTimeHours = startTimeHours + 12;
			} else if (endStatus === 'AM') {
				if (startTimeHours === 12) {
					startTimeHours = 0;
				} else if (endTimeHours === 12) {
					endTimeHours = 0;
				}
			}
		}
		if (endTimeMinutes === 0) {
			endTimeMinutes = 60;
		}

		// check selected date equal to today date
		if (todayDate === recievedDate) {
			// calculations
			if ((currentHours > startTimeHours) || ((currentHours === startTimeHours) && (startTimeMinutes < currentMinutes))) {
				return true;
			}
		}

		if (Object.keys(this._calendarBusyTimes).length) {
			// re calculate end date time to match with calendar busy slot times
			if (endTimeHours >= 1 && endTimeHours < 12 && endStatus === 'PM') { // For 1-11PM
				endTimeHours = endTimeHours + 12;
			} else if (endTimeHours === 12 && endStatus === 'AM') { // For 12AM day/nignt paradox
				if (startStatus === 'AM') { // Day starts (12AM)
					endTimeHours = endTimeHours - 12; // For interval in < 60
				}
				if (startStatus === 'PM') { // Day ends (12AM)
					endTimeHours = 24;
				}
			}

			if (endTimeMinutes === 60) {
				endTimeMinutes = 59;
				endTimeHours = endTimeHours - 1;
			}

			if (startTimeMinutes === 0) {
				startTimeMinutes = Number('01');
			}

			for (let key in this._calendarBusyTimes) {
				if (this._calendarBusyTimes.hasOwnProperty(key)) {
					let busyTime: any = this._calendarBusyTimes[key];

					// Check calenders busy slot
					for (let i = 0; i < busyTime.length; i++) {
						if (busyTime[i].startHour >= startTimeHours && busyTime[i].startMinute < endTimeMinutes && busyTime[i].startHour <= endTimeHours) { // Calendar start hours falls in slot
							if (busyTime[i].endHour == startTimeHours && busyTime[i].endMinute == startTimeMinutes) {
								continue;
							}
							// console.log("Calendar start hours falls in slot");
							// console.log("Gmail = " + busyTime[i].startHour + ":" + busyTime[i].startMinute + "-" + busyTime[i].endHour + ":" + busyTime[i].endMinute);
							// console.log("Calendar = " + startTimeHours + ":" + startTimeMinutes + "-" + endTimeHours + ":" + endTimeMinutes);
							return true;
						}

						if (busyTime[i].endHour >= startTimeHours && busyTime[i].endMinute > startTimeMinutes && busyTime[i].endHour <= endTimeHours) { // Calendar end hours falls in slot
							// console.log("Calendar end hours falls in slot");
							// console.log("Gmail = " + busyTime[i].startHour + ":" + busyTime[i].startMinute + "-" + busyTime[i].endHour + ":" + busyTime[i].endMinute);
							// console.log("Calendar = " + startTimeHours + ":" + startTimeMinutes + "-" + endTimeHours + ":" + endTimeMinutes);
							return true;
						}

						if (startTimeHours > busyTime[i].startHour && startTimeHours < busyTime[i].endHour) { // App slot start hours is in between busy time
							// console.log("App slot start hours is in between busy time")
							// console.log("Gmail = " + busyTime[i].startHour + ":" + busyTime[i].startMinute + "-" + busyTime[i].endHour + ":" + busyTime[i].endMinute);
							// console.log("Calendar = " + startTimeHours + ":" + startTimeMinutes + "-" + endTimeHours + ":" + endTimeMinutes);
							return true;
						}

						if (endTimeHours > busyTime[i].startHour && endTimeHours < busyTime[i].endHour) { // App slot end hours is in between busy time
							// console.log("App slot end hours is in between busy time");
							// console.log("Gmail = " + busyTime[i].startHour + ":" + busyTime[i].startMinute + "-" + busyTime[i].endHour + ":" + busyTime[i].endMinute);
							// console.log("Calendar = " + startTimeHours + ":" + startTimeMinutes + "-" + endTimeHours + ":" + endTimeMinutes);
							return true;
						}

						if (busyTime[i].endHour == endTimeHours && busyTime[i].endMinute <= endTimeMinutes && busyTime[i].endHour > startTimeHours) { // Special case
							// console.log("Calendar end hours falls in slot (Just before end of the time slot)");
							// console.log("Gmail = " + busyTime[i].startHour + ":" + busyTime[i].startMinute + "-" + busyTime[i].endHour + ":" + busyTime[i].endMinute);
							// console.log("Calendar = " + startTimeHours + ":" + startTimeMinutes + "-" + endTimeHours + ":" + endTimeMinutes);
							return true;
						}

						if (busyTime[i].startHour == startTimeHours && busyTime[i].startMinute == startTimeMinutes) { // Special case
							// console.log("Calendar start hours falls in slot (Just the time slot has started)");
							// console.log("Gmail = " + busyTime[i].startHour + ":" + busyTime[i].startMinute + "-" + busyTime[i].endHour + ":" + busyTime[i].endMinute);
							// console.log("Calendar = " + startTimeHours + ":" + startTimeMinutes + "-" + endTimeHours + ":" + endTimeMinutes);
							return true;
						}

					}
				}
			}
		}

		return false;
	}

	// selected time calculation
	countTimeSlots(intervalTime: any): void {
		this.updateTimeSlotCount(intervalTime);
		if (Object.keys(this._calendarToSync).length) {
			for (let key in this._calendarToSync) {
				if (this._calendarToSync.hasOwnProperty(key)) {
					this.fetchCalendarBusySlots(this._calendarToSync[key]);
				}
			}
		}
	}

	updateTimeSlotCount(intervalTime: any): void {
		if (intervalTime) {
			this.selectedIntervalTime = intervalTime;
			this._selectedIntervalTime = intervalTime;
			let input: any;
			let startTime: number = 0;
			let timeArray: any[] = [];
			let selectedTimeSlots: any[] = [];
			let interval: any = intervalTime;
			do {
				if (startTime === 0) {
					input = 12;
				} else {
					input = startTime;
				}
				if (input) {
					let hours: number = Math.floor(input);
					input = (input - hours) * 60;
					let mins: any = Math.floor(input);
					if (hours === 0) {
						hours = 12;
					}
					mins = (mins < 10 && mins > 0) ? '0' + mins : mins;
					timeArray.push(('00' + hours).slice(-2) + ':' + ('00' + mins).slice(-2));
					startTime += interval / 60;
				}
			}
			while (startTime <= 24)

			for (let i = 0; i < (timeArray.length - 1); i++) {
				let st, et, sh, eh, sm, em;
				sh = Number(timeArray[i].slice(0, 2));
				eh = Number(timeArray[i + 1].slice(0, 2));
				sm = timeArray[i].slice(2);
				em = timeArray[i + 1].slice(2);

				if (sh >= 12) {
					if (i < 4) {
						st = 'AM';
					} else {
						st = 'PM';
					}

					if (sh > 12) {
						sh -= 12;
					}
				} else {
					st = 'AM';
				}

				if (eh >= 12) {
					if (i < 4) {
						et = 'AM';
					} else {
						if (eh === 24) {
							et = 'AM';
						} else {
							et = 'PM';
						}
					}
					if (eh > 12) {
						eh -= 12;
					}
				} else {
					et = 'AM';
				}
				let timeSlot: any = {};
				timeSlot.index = i;
				timeSlot.time = ('00' + sh).slice(-2) + sm + ' ' + st + ' - ' + ('00' + eh).slice(-2) + em + ' ' + et;
				timeSlot.selected = false;
				selectedTimeSlots.push(timeSlot);
			}
			this.viewSelectedTimeSlots = selectedTimeSlots;
		} else {
			this.selectedIntervalTime = undefined;
			this._selectedIntervalTime = undefined;
			this.viewSelectedTimeSlots = [];
		}

	}

	// click time slots
	selectTimeSlot(selectedTime: any, selectedDate: any, lengthIndex: number) {
		let date =  moment(selectedDate).format('MM/DD/YYYY');
		let status: boolean = true;
		// set time format
		if (Number(selectedTime.time.slice(0, 2) < 8)) {
			selectedTime.timeUpdated = true;
			selectedTime.updatedTIme = (Number(selectedTime.time.slice(0, 2)) + 12) + selectedTime.time.slice(2, 11) + (Number(selectedTime.time.slice(11, 13)) + 12) + selectedTime.time.slice(13);
		} else {
			selectedTime.timeUpdated = false;
			selectedTime.updatedTIme = '';
		}
		// check status updated
		this.clikedTimeSlots.forEach((value: any, key: any) => {
			if (value.date === date) {
				if (value.time.time === selectedTime.time) {
					if (value.time.selected) {
						status = false;
					} else {
						status = true;
					}
				}
			}
		});

		// set selected time object selected false/ true
		if (status) {
			this.viewSelectedTimeSlots[lengthIndex].selected = true;
		} else {
			this.viewSelectedTimeSlots[lengthIndex].selected = false;
		}

		let arrayFormat: any = {};
		arrayFormat.time = selectedTime;
		arrayFormat.date = date;
		arrayFormat.index = lengthIndex;
		if (status) {
			this.clikedTimeSlots.push(arrayFormat);
		} else {
			this.clikedTimeSlots.forEach((value: any, key: any) => {
				if (value.date === date && value.time === selectedTime) {
					this.clikedTimeSlots.splice(key, 1);
				}
			});
		}
		this._tempTimeSlots = this.clikedTimeSlots;
		// send data to controller
		this.selectedTimeArray.emit('clikedTimeSlots');
	}

}
