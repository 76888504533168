<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Create List</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		Close
	</button>
</div>
<div class="modal-body">
	<form #listData="ngForm" class="form">
        <div class="ngdialog-body">
            <div class="create-list-popup-body-wrapper form-group">
                <input type="text" class="form-control" name="listName" placeholder="Enter List name" [(ngModel)]="list.List_name" required [maxLength]="50">
            </div>
        </div>
     </form>
</div>
<div class="modal-footer">
	<button type="submit" class="ngdialog-button popup-footer-save-button" [disabled]="listData.invalid" (click)="saveList(list)">SAVE</button>
</div>
