import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { StripeService } from 'ngx-stripe';
import { PaymentService } from '../../../core/services/payment.service';
import { UserService } from '../../../core/services/user.service';
import { ViewPaymentComponent } from './view-payment.component';

@Component({
	selector: 'update-payment',
	templateUrl: './update-payment.component.html',
	styleUrls: ['./view-payment.component.scss']
})
export class UpdatePaymentComponent extends ViewPaymentComponent {

	constructor(_modal: NgbActiveModal,
		_router: Router,
		_paymentService: PaymentService,
		_userService: UserService,
		_cookieService: CookieService,
		_stripeService: StripeService) {
			super(_modal, _router, _paymentService, _userService, _cookieService, _stripeService);
		}

}
