import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	constructor(private cookieService: CookieService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.cookieService.get('token_Hire');
		if (token && req.url.includes('unnanu.com')) {
			req = req.clone({
				setHeaders: {
					'Authorization': token,
					'user_type': '2'
				}
			});
		}

		return next.handle(req);
	}
}
