import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'ellipsis'
})

export class EllipsisPipe implements PipeTransform {

	transform(text: string, length: any): string {
		if (text && text.length > length) {
			return text.substr(0, length) + '...';
		}
		return text;
	}
}
