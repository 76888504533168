import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { LinkedinService } from './linkedin.service';
@Injectable()
export class UtilityService {

	public readonly emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	public readonly url_pattern_expression = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

	public fileServerUrl: string = environment.urls.fileServerUrl;
	public jobboardUrl: string = environment.urls.jobboardUrl;
	public unnanuRecruitURL: string = environment.urls.recruitUrl;

	constructor(private _sanitizer: DomSanitizer,
		private _linkedinService: LinkedinService,
		private _deviceDetectorService: DeviceDetectorService) { }

	// get password score
    getPasswordScore(password: any): number {
		let pass = password;
		let score = 0;
		if (!pass) {
		  return score;
		}
		// award every unique letter until 5 repetitions
		let letters: any = new Object();
		for (let i = 0; i < pass.length; i++) {
		  letters[pass[i]] = (letters[pass[i]] || 0) + 1;
		  score += 5.0 / letters[pass[i]];
		}
		// bonus points for mixing it up
		let variations: any = {
		  digits: /\d/.test(pass),
		  lower: /[a-z]/.test(pass),
		  upper: /[A-Z]/.test(pass),
		  nonWords: /\W/.test(pass),
		};
		let variationCount = 0;
		for (let check in variations) {
		  variationCount += variations[check] === true ? 1 : 0;
		}
		score += (variationCount - 1) * 10;
		let passwordScore = Number(score);
		return passwordScore;
	}

	// object array convert to Array
    convertToArray(obj: any) {
		if (!(obj instanceof Object)) return obj;
		let arr = [];
		let i = 0;
		for (let key in obj) {
		  arr.push({ key: key, value: obj[key] });
		  i++;
		}
		return arr;
	}

	//Return as trust url
	trustAsURL(url: string): any {
		return this._sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	trustAsHTML(html: string): any {
		return this._sanitizer.bypassSecurityTrustHtml(html);
	}

	//Get Limited Word
	getLimitedWord(word: string, size: number): string {
		if (word) {
			if (word.length <= size) {
				return word;
			} else {
				return word.substr(0, size) + '...';
			}
		}
		return '';
	}

	convertTime(timeData: any): any {
		let a = new Date(timeData.substring(0, 19));
		let b = (new Date(a.getTime() - ((new Date()).getTimezoneOffset() * 60000))).toISOString();
		return b;
	}

	getCdnUrl(path: string): string {
		return path?.trim().length ? this.fileServerUrl + path : '';
	}

	checkImageURL(url: string): boolean {
		return url.match(/\.(jpeg|jpg|gif|png)$/) !== null;
	}

	// utc to miliseconds
	getMessageMinutes(timeData: any): any {
		let d = new Date(timeData);
		let n = d.getTime();
		let c = n / 1000;
		return c;
	}

	// get time diffrence from seconds
	getTimeDiffrence(fDate: any, sDate: any): any {
		let a = new Date(fDate);
		let b = new Date(sDate);
		return (((a.getTime() - b.getTime()) / 1000) - ((new Date()).getTimezoneOffset() * 60));
	}

	// get current date
	getCurrentDate(): any {
		let a = new Date();
		return a;
	}

	// get file extension
	getFileExt(fileName: string): any {
		return fileName.split('.').pop();
	}

	ShareonTwitter(c: any, vid: any, vtitle: string): boolean {
		let company: any = c;
		let vtitle1: string = vtitle.split(' ').join('-');
		let url: string = this.jobboardUrl + 'job/' + vid + '/jid';
		let tstring: string = `Apply here for a '${vtitle}' job opening at '${company}': ${url} #careers #jobs`;
		let turl: string = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(tstring);
		window.open(turl, "popup", "width=1100,height=900,left=300,top=150");
		return false;
	}

	ShareonFacebook(c: any, vid: any, vtitle: string): boolean {
		let company: any = c;
		let vtitle1: string = vtitle.split(' ').join('-');
		let url: string = this.jobboardUrl + 'job/' + vid + '/jid';
		let fstring: string = `Apply here for a '${vtitle}' job opening at '${company}': ${url} #careers #jobs`;
		let furl: string = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
		window.open(furl, "popup", "width=1100,height=900,left=300,top=150");
		return false;
	}

	ShareonLinkedin(c: any, vid: any, vtitle: string): boolean {
		let company: any = c;
		let vtitle1: string = vtitle.split(' ').join('-');
		let url: string = this.jobboardUrl + 'job/' + vid + '/jid';
		let lstring = `Apply here for a '${vtitle}' job opening at '${company}': ${url} #careers #jobs`;
		let articleUrl: string = encodeURIComponent(url);
		let articleTitle: string = encodeURIComponent(lstring);
		let articleSummary: string = encodeURIComponent('Unnanu Job Post');
		let articleSource: string = encodeURIComponent('Unnanu');
		let goto: string = 'http://www.linkedin.com/shareArticle?mini=true' +
			'&url=' + articleUrl +
			'&title=' + articleTitle +
			'&summary=' + articleSummary +
			'&source=' + articleSource;
		window.open(goto, "popup", "width=1100,height=900,left=300,top=150");
		return false;
	}

	SharebyEmail(c: any, vid: any, vtitle: string): boolean {
		let company: any = c;
		let vtitle1: string = vtitle.split(' ').join('-');
		let url: string = this.jobboardUrl + 'job/' + vid + '/jid';
		let subject: string = 'Apply here for a "' + vtitle + '" job opening at ' + company;
		let body: string = 'Hello, \n \n You might want to take a look at this "' + vtitle + '" job opening at "' + company + '": ' + url + ' and please apply as soon as possible. Good Luck! \n \n Thanks \n ' + company;
		let eurl: string = 'mailto:?&subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
		//window.open(eurl, "_self");
		window.open(eurl);
		return false;
	}

	SharebySMS(c: any, vid: any, vtitle: string): boolean {
		let company: any = c;
		let vtitle1: string = vtitle.split(' ').join('-');
		let url: string = this.jobboardUrl + 'job/' + vid + '/jid';
		let subject: string = 'Apply here for a "' + vtitle + '" job opening at ' + company;
		let body = 'Hello, \n \n You might want to take a look at this "' + vtitle + '" job opening at "' + company + '": ' + url + ' and please apply as soon as possible. Good Luck! \n \n Thanks \n ' + company;
		let eurl = 'sms:?&subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
		//window.open(eurl, "_self");
		window.open(eurl);
		return false;
	}

	linedinPost(vacancy: any): void {
		//console.log("vacancy:", vacancy);
		let jobRefID = vacancy.job_ref_id;
		let url = this.unnanuRecruitURL + 'jobboard/apply/' + jobRefID + '=?source=LinkedInJob';
		let vid = vacancy.vacancyid;
		let descr = vacancy.description;
		let odate = vacancy.opening_date;
		let title = vacancy.title;
		let ftime = '';
		if (vacancy.is_contract) {
			ftime = 'CONTRACT';
		}
		if (vacancy.is_full_time) {
			ftime = 'FULL_TIME';
		}
		if (vacancy.is_volunteer) {
			ftime = 'VOLUNTEER';
		}
		if (vacancy.is_part_time) {
			ftime = 'OTHER';
		}

		this._linkedinService.postLinkedinJob(environment.keys.linkedin_auth, '410417', url, descr, ftime, vid, odate, title).subscribe((response: any) => {
			//console.log("success: ", response);
		});
	}


	getTimeZoneString(selectedTimeZone: number): string {
		let parts: any = Number(Math.abs(selectedTimeZone) / 3600).toFixed(2).split('.');
		if (Number(parts[1]) === 50) {
			parts[1] = 30;
		}

		if (Number(parts[1]) > 59) {
			parts[1] = Number(parts[1]) - 60;
			parts[0] = Number(parts[0]) + 1;
		}
		if (selectedTimeZone > 0) {
			return '+' + ('0' + parts[0]).slice(-2) + ':' + ('0' + parts[1]).slice(-2);
		} else {
			return '-' + ('0' + parts[0]).slice(-2) + ':' + ('0' + parts[1]).slice(-2);
		}
	}

	isMobile(): boolean {
		return this._deviceDetectorService.isMobile();
	}
}
