<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Enable Calendar Integration</h4>
</div>
<div class="modal-body">
	<div class="pop-up-content">
		<p class="cancel-text">Unnanu Hire has an Integration to Calendars. Go to Settings to Enable and Configure this
			Feature.</p>
	  </div>
</div>
<div class="modal-footer text-right">
	<div class="button-wrapper">
		<button type="button" class="btn no-button" (click)="closeThisDialog()">Cancel</button>
		<button type="button" class="btn yes-button" [routerLink]="['/settings/calendars']">Open Settings</button>
	</div>
</div>
