<div class="search">
	<div class="search-container">
		<i class="icon icon-search-magnfying img-sprite-1" *ngIf="!hideicon"></i>
		<input class="form-control" [ngClass]="{'error' : foundError, 'input-padding': !hideicon, 'margin-bottom': addBottomMargin}" [readOnly]="readOnly"
			[(ngModel)]="searchText" placeholder="{{placeholder}}" (ngModelChange)="setKeyword();foundError = false;"
			[ngbTypeahead]="search" (selectItem)="dropdownClicked($event)" [resultTemplate]="rt" [maxlength]="100"
			tabindex="0">
		<ng-template #rt let-r="result" let-t="term">
			<ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
		</ng-template>
	</div>
</div>
