import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AutocompleteService } from '../../../core/services/autocomplete.service';

@Component({
	selector: 'search-job-title',
	templateUrl: './search-job-title.component.html',
	styleUrls: ['./search-job-title.component.scss']
})
export class SearchJobTitleComponent implements OnInit, OnChanges {

	@Input() selectedCandidates: any;
	@Input() reschedule: any = {};
	@Input() selectedtitle: string = '';
	@Input() readOnly: boolean = false;
	@Input() addBottomMargin: boolean = true;
	@Output() keywordChosen: EventEmitter<any> = new EventEmitter<any>();

	public foundError: boolean = false;
	public suggestionsDropMenu: boolean = false;
	public suggestions: any = null;

	constructor(private _autoCompleteService: AutocompleteService) { }

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.selectedtitle.currentValue) {
			this.setJobTitleKeyWord();
		}
	}

	searchJobTitle: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.searchjobTitle())
		)

	// search text call when ng change
	searchjobTitle() {
		// check typed text length
		if (this.selectedtitle?.length > 0) {
			// call autocomplete service
			return this._autoCompleteService.searchJobPosition(this.selectedtitle).pipe(map((response: any) => {
				// check response status
				if (response.status === 200) {
					this.suggestionsDropMenu = true;
					this.suggestions = response.Data.Suggestions.splice(0,10);
					return this.suggestions;
				} else {
					this.suggestionsDropMenu = false;
					this.suggestions = null;
					return [];
				}
			}));
		} else {
			return of([]);
		}
	}

	setJobTitleKeyWord(): void {
		this.selectedtitle =  this.selectedtitle ? this.selectedtitle.trim() : '';
		this.keywordChosen.emit(this.selectedtitle.trim());
	}

	// drop down clicked
	dropdownClicked(event: any): void {
		event.preventDefault();
		// assigns selected value to text input
		this.selectedtitle = event.item.name;
		this.suggestionsDropMenu = false;
		this.setJobTitleKeyWord();

		if (this.selectedCandidates) {
			this.selectedCandidates.position = this.selectedtitle;
		}

		if (this.reschedule) {
			this.reschedule.offered_title = this.selectedtitle;
		}
	}

}
