<div class="pop-up-content">
	<div *ngIf="context == 'INTERVIEW'" class="process-wrapper">
		<div class="step-wrapper continue active prev">
			<span class="step">01</span>
			Select Candidates
		</div>
		<div class="step-wrapper continue active prev">
			<span class="step">02</span>
			Interview Details
		</div>
		<div class="step-wrapper continue active prev">
			<span class="step">03</span>
			Time Selection
		</div>
		<div class="step-wrapper active last">
			<span class="step">04</span>
			Preview
		</div>
	</div>
	<div *ngIf="context == 'PROFILE'" class="process-wrapper reschedule">
		<div class="step-wrapper continue active prev">
			<span class="step">01</span>
			Interview Details
		</div>
		<div class="step-wrapper continue active prev">
			<span class="step">02</span>
			Time Selection
		</div>
		<div class="step-wrapper active">
			<span class="step">03</span>
			Preview
		</div>
	</div>
	<p class="interview-select-title">Preview Interview Request</p>
	<div class="job-details-wrapper">
		<div class="col-lg-5 col-md-5">
			<label class="job-title">Job Title</label>
			<p class="job-title-requsted">{{interviewdDetails.title | ellipsis: 35}}</p>
		</div>
		<div class="col-lg-3 col-md-3">
			<label class="job-type">Job type</label>
			<p class="job-type-requsted" *ngIf="interviewdDetails.job_type === '1' ">Employee</p>
			<p class="job-type-requsted" *ngIf="interviewdDetails.job_type === '2' ">Internship</p>
			<p class="job-type-requsted" *ngIf="interviewdDetails.job_type === '3' ">Contract</p>
			<p class="job-type-requsted" *ngIf="interviewdDetails.job_type === '4' ">Volunteer</p>
		</div>
		<div class="col-lg-4 col-md-4">
			<label class="job-title">Interview Type</label>
			<div class="message-wrapper">
				<p *ngIf="interviewdDetails.is_remote_interview">Virtual</p>
				<p *ngIf="!interviewdDetails.is_remote_interview">Onsite</p>
			</div>
		</div>
	</div>
</div>
<div class="preview-content">
	<div class="candidates-wrapper">
		<label>Candidates</label>
		<div class="inner-candidates-wrapper">
			<preview-candidates *ngFor="let candidateList of selectedCandidates" [candidate]="candidateList">
			</preview-candidates>
		</div>
	</div>
	<div class="selected-time-wrapper">
		<label>Time Slots</label>
		<div class="inner-time-wrapper">
			<time-slots *ngFor="let time of timedSelection | orderBy:['date']" [settime]="time" [previewClose]="false"
				[abbrtext]="abbrtime"></time-slots>
		</div>
	</div>
	<div class="message-content-wrapper">
		<label>Message</label>
		<div class="preview-message">
			<p class="msg-content word-wrap">{{interviewdDetails.message}}</p>
		</div>
	</div>
</div>
<div class="modal-footer pop-up-footer">
	<button type="submit" class="btn btn-back" (click)="moveBackward.emit(2)">BACK</button>
	<button type="button" class="ngdialog-button popup-footer-save-button" [disabled]="isDisabled"
		(click)="isDisabled = true; saveInterviewData.emit()">SEND</button>
</div>
