<div class="go-pro-pop-up-content">
	<form #checkoutForm="ngForm" novalidate>
		<div class="pop-up-left">
			<h2 class="go-pro-pop-title">Update Payment Information</h2>
			<p class="go-pro-pop-content">Your new payment method will be applied to next billing cycle. </p>
			<div class="card-details-wrapper">
				<div class="card-name-wrap">
					<div>
						<p>Name on card</p>
						<input class="form-control card-name" type="text" [(ngModel)]="cardName" name="name"
							required="">
					</div>
					<div class="card-thumbs">
						<div class="visa card"></div>
						<div class="master card"></div>
						<div class="discover card"></div>
						<div class="amex card"></div>
					</div>
				</div>
				<div class="card-details-wrap" style="padding-bottom: 40px;" ngxStripeCardGroup [elementsOptions]="elementsOptions">
					<div class="card-number">
						<p>Credit card number</p>
						<div class="form-control">
							<ngx-stripe-card-number [options]="cardOptions" (change)="onChange($event)">
							</ngx-stripe-card-number>
						</div>
					</div>
					<div class="card-expire">
						<p>Expires</p>
						<div class="form-control">
							<ngx-stripe-card-expiry [options]="cardOptions" (change)="onChange($event)">
							</ngx-stripe-card-expiry>
						</div>
					</div>
					<div class="card-cvv">
						<p>CVC</p>
						<div class="form-control">
							<ngx-stripe-card-cvc [options]="cardOptions" (change)="onChange($event)">
							</ngx-stripe-card-cvc>
						</div>
					</div>
				</div>
				<div class="card-address-wrap" style="position: relative; z-index: 10;">
					<div class="card-address">
						<p>Address</p>
								<input type="text" class="form-control card-address" style="z-index: 10; position: relative;"  name="address"[(ngModel)]="address" autocomplete="off"  required="">
				</div>
			  </div>
			  <div class="card-address-wrap" style="padding-top: 40px; position: relative; overflow: visible; z-index: 1;">
				<div class="card-city">
					<p>City</p>
					<input id="city" #cityInput type="text" class="form-control" name="city" [(ngModel)]="city" autocomplete="off" required="">
				</div>
				<div class="card-state">
					<p>State</p>
					<input id="state" #stateInput type="text" class="form-control" name="state" [(ngModel)]="state" autocomplete="off" required="">
				</div>
				<div class="card-country">
					<p>Country</p>
					<input id="country" #countryInput type="text" class="form-control" name="country" [(ngModel)]="country" autocomplete="off" required="">
				</div>
				<div class="card-postal">
					<p>Postal Code</p>
					<input type="text" class="form-control" pattern="\d{5}(-\d{4})?"  
					maxlength="10" name="postalCode" [(ngModel)]="postalCode" autocomplete="off" required="">
				</div>
			  </div>		
				<div class="form-check privacy-agree">
					<label class="form-check-label" style="padding-top: 10px;" [hidden]="subsErrorFound">
						<div class="form-check" style="display: flex; align-items: center;">
							<input class="form-check-input" type="checkbox" style="width: 40px; height: 30px;" name="termsRequired" [(ngModel)]="termsRequired"
									required>
							<label style="margin: 10px;"> By checking you agree to 
									<a href="{{UNNANU_LANDING_PAGE}}/tos/" target="_blank">Unnanu Terms</a> &amp;
									<a href="{{UNNANU_LANDING_PAGE}}/privacy/" target="_blank">Privacy Policy</a>
							</label>
					  </div>
					</label>
				</div>
				<label class="form-check-label" style="color: red; margin-top: 60px;" [hidden]="!subsErrorFound">* {{subsErrorFoundMessage}} </label><br>
			</div>
			<div>
			<button class="btn btn-purchase-complete" [disabled]="!checkoutForm.valid || !cardValid || responseLoad"
				(click)="sendCardDetails()" [ngClass]="{'loading' : responseLoad}" type="submit">
				<span *ngIf="!responseLoad">Update Card Information</span>
				<span *ngIf="responseLoad">
					<span class="spinner"></span> 
					Update Card Processing
			   </span>
			</button>
			<button type="reset" class="btn btn-purchase-cancel" (click)="closeThisDialog()">Cancel</button>
		</div>
		</div>
	</form>
</div>
