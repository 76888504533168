import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class CalendarService {

	private hireothUrl = environment.urls.hireothUrl;

	constructor(private _http: HttpClient) { }

	getGoogleAccessToken(token: any) {

		let data: any = {
			grant_type: 'refresh_token',
			refresh_token: token,
			client_id: environment.keys.google_client_id,
			client_secret: environment.keys.google_client_secret,
			redirect_uri: environment.keys.google_redirect_uri
		};

		return this._http.post(`https://oauth2.googleapis.com/token`, data);
	}

	getGoogleUserInfo(accessToken: any) {

		let headers = new HttpHeaders();
		headers = headers.append('Authorization', `Bearer ${accessToken}`);

		return this._http.get(`https://www.googleapis.com/oauth2/v2/userinfo`, {headers: headers});
	}

	getGoogleCalendarList(accessToken: any) {

		let headers = new HttpHeaders();
		headers = headers.append('Authorization', `Bearer ${accessToken}`);

		return this._http.get(`https://www.googleapis.com/calendar/v3/users/me/calendarList`, {headers: headers});
	}

	getGoogleCalenderBusyTime(calendar: any, timeMin: any, timeMax: any, timeZone: any, accessToken: any) {

		let headers = new HttpHeaders();
		headers = headers.append('Authorization', `Bearer ${accessToken}`);

		let data: any = {
			timeMin: timeMin,
			timeMax: timeMax,
			timeZone: timeZone,
			items: [{ id: calendar }]
		};

		return this._http.post(`https://www.googleapis.com/calendar/v3/freeBusy`, data, {headers: headers});
	}

	getMsAccessToken(token: any) {

		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', `application/x-www-form-urlencoded; charset=UTF-8`);

		let data: any = {
			scope: environment.keys.ms_scopes.join(' '), // You can use '%20' or empty space to join the scopes in a string
			grant_type: 'refresh_token',
			refresh_token: token,
			client_id: environment.keys.ms_client_id,
			redirect_uri: environment.keys.ms_redirect_uri
		};

		return this._http.post(`https://login.microsoftonline.com/common/oauth2/v2.0/token`, data, {headers: headers});

	}

	getMsCalendarList(accessToken: any) {

		let headers = new HttpHeaders();
		headers = headers.append('Authorization', `Bearer ${accessToken}`);

		return this._http.get(`https://graph.microsoft.com/v1.0/me/calendars`, {headers: headers});
	}

	getMsCalenderBusyTime(user_id: any, calendar_id: any, timeMin: any, timeMax: any, timeZone: any, accessToken: any) {

		let headers = new HttpHeaders();
		headers = headers.append('Authorization', `Bearer ${accessToken}`);
		headers = headers.append('Prefer', `outlook.timezone='${timeZone}'`);

		return this._http.get(`https://graph.microsoft.com/v1.0/users/${user_id}/calendars/${calendar_id}/calendarView?startDateTime=${timeMin}&endDateTime=${timeMax}`, {headers: headers});
	}

	addCalendar(calendars: any) {
		return this._http.post(`${this.hireothUrl}account/add/calendarId`, calendars);
	}

	removeCalendar(calendars: any) {
		return this._http.post(`${this.hireothUrl}account/delete/calendarId`, calendars);
	}

	getCalendarList() {
		return this._http.get(`${this.hireothUrl}account/get/calendarId`).pipe(map((response: any)=>{
			let calendarList = [];
			let responseObj = {};
			if (response.Data instanceof Array) {
			  for (let i = 0; i < response.Data.length; i++) {
				let index = calendarList.findIndex(function (calendar) {
				  return calendar['calendar_owner'] == response.Data[i].calendar_owner;
				});
				response.Data[i]['id'] = response.Data[i].calendar_owner + response.Data[i].calendar_id;
				if (index == -1) {
				  let temp = Object.assign({}, response.Data[i]);
				  temp['calendar_list'] = [response.Data[i]];
				  calendarList.push(temp);
				} else {
				  calendarList[index].calendar_list.push(response.Data[i]);
				}
			  }
			  responseObj = { Data: calendarList, Code: response.Code, Result: response.Result };
			} else {
			  responseObj = response;
			}
			return responseObj;
		}));
	}

}
