import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../core/services/user.service';

@Component({
	selector: 'app-header-default',
	templateUrl: './default-header.component.html',
	styleUrls: ['./default-header.component.scss']
})
export class DefaultHeaderComponent implements OnInit {

	constructor(private _userService: UserService,
		public cookieService: CookieService) { }

	ngOnInit(): void {

	}

	logout(): void {
		this._userService.logout().subscribe((response: any) => {

		});
	}

}
