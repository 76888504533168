<div class="modal-header">
	<h4 class="modal-title" id="modal-title">
		<div class="back-navigation pull-left" (click)="closeThisDialog()">
			<span class="close-pop-up pull-left">
				<img src="../../../../assets/images/back-answers.png"
					srcset="../../../../assets/images/back-answers@2x.png 2x, ../../../../assets/images/back-answers@3x.png 3x">
			</span>
			<span class="pull-left">Back to Applicants</span>
		</div>
		<div class="application-information pull-left" [hidden]="!jobApplicant_jobTitle">
			<div class="job-information pull-left">Questionnaire Answers for {{jobApplicant_jobTitle}}
				({{jobApplicant_jobref}})</div>
			<div class="applicant-name pull-left">{{jobApplicant_applicantName | ellipsis:60}}</div>
			<div class="application-date pull-left">Applied on
				{{utilityService.convertTime(jobApplicant_applicationDate) | date : 'EEE, MMM d, y @ h:mm a'}}</div>
		</div>
		<div class="clearfix"></div>
	</h4>
</div>
<div class="modal-body content">
	<div class="scrolled-shadow"></div>
	<div [hidden]="!jobApplicant_preloader" style="height:100%; width:100%;">
		<div class="rotate-preloader-fill">
			<div class="pre-loader-wrapper">
				<div class="preloader-icon"></div>
			</div>
		</div>
	</div>

	<div [hidden]="jobApplicant_preloader" class="answers-wrapper">
		<div class="question-item" *ngFor="let response of jobApplicant_Answers; let $index=index">
			<div class="question-number">{{$index + 1}}.</div>
			<div class="question-content-wrapper">
				<div class="question-body"><span [innerHtml]="response.question_body"></span>{{response.is_required?'
					*':''}}
					<span
						*ngIf="response.question_type==5 && utilityService.getCdnUrl(response.answers[1].answer)">(Attachment:
						<!-- <a href="{{getCdnUrl(response.answers[1].answer)}}">Download</a> Madhu 07/20/20 -->
						<a attachment-view value="getCdnUrl(response.answers[1].answer)"> Click to view</a>
					</span> <span style="color:#CCC;" *ngIf="response.skipped">(Skipped)</span>
				</div>
				<div class="question-response" *ngIf="!response.skipped">
					<!-- Open ended / Number -->
					<div class="response-answer" *ngIf="response.question_type==0 || response.question_type==4">
						<span [innerHtml]="response.answers[0].answer"></span> <span
							*ngIf="response.answers[0].is_correct_answer" class="correct-answer">Correct</span>
					</div>
					<!-- Attachment -->
					<div class="response-answer" *ngIf="response.question_type==5">
						{{response.answers[0].answer.file_name}}
						<!-- <a *ngIf="response.answers[0].answer.attachment_url" target="_blank" href="{{utilityService.getCdnUrl(response.answers[0].answer.attachment_url)}}">Download</a> Madhu 07/20/20-->
						<a *ngIf="response.answers[0].answer.attachment_url" attachment-view
							value="getCdnUrl(response.answers[0].answer.attachment_url)">View attachment</a>
					</div>
					<!-- Multiple options / Yes - No / Checkboxes -->
					<div class="response-multiple-value"
						*ngIf="response.question_type==1 || response.question_type==2 || response.question_type==3">
						<ul class="multiple-value-list">
							<li *ngFor="let value of response.answers">
								<span class="answer-checkbox" [ngClass]="{'selected' : value.is_selected}"></span>
								<span class="answer-label"><span [innerHtml]="value.answer"></span> <span
										*ngIf="value.is_correct_answer" class="correct-answer">Correct</span> <span
										*ngIf="value.is_expected" class="expected-answer">Expected</span></span>
							</li>
						</ul>
					</div>
				</div>
				<div class="question-expected-answer" *ngIf="response.question_type==4 && !response.skipped">
					Expected Answer : <strong>{{response.answers[0].expected_answer_desc}}</strong>
				</div>
			</div>
		</div>
	</div>
</div>
