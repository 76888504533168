<div class="modal-header">
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="pop-up-content section">
		<div class="resumeVideoItemPanel playingResumeVideo">
		  <div class="resumeVideoPlayerWrapper">
			<div class="html5videoPlayback">
			  <video src='{{sectionData.profile_video_url === undefined || sectionData.profile_video_url === "" ? "../../../../../../assets/images/profile-icon-ellipse.png" : utilityService.getCdnUrl(sectionData.profile_video_url)}}' type="video/mp4" controls autoplay></video>
			</div>
		  </div>
		</div>
		<div class="section-header">
		  <div class="section-icon">
			<img src="{{!utilityService.checkImageURL(sectionData.profile_video_url) ? '../../../../../../assets/images/profile-icon-ellipse.png' : utilityService.getCdnUrl(sectionData.profile_video_url)}}" width="43" />
		  </div>
		  <div class="header-title-wrapper">
			<p class="time-period">{{sectionData.recruit_first_name}} {{sectionData.recruit_last_name}}</p>
			<p class="position">{{sectionData.job_position}}</p>
			<p class="location" id="ngdialog2-aria-describedby"> <i class="icon-navigation img-sprite-2"></i>{{sectionData.location}}</p>
		  </div>
		</div>
	</div>
</div>
