import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../core/services/user.service';
import { EventService } from '../../core/services/event.service';
import { SocketIOService } from '../../core/services/socket-io.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '../../core/services/settings.service';
import { ProfileDataService } from '../../core/services/profile-data.service';
import { CreateEmailInviteComponent } from '../../shared/components/create-email-invite-modal/create-email-invite.component';
import { CreateTalentInviteComponent } from '../../shared/components/create-talent-invite-modal/create-talent-invite.component';
import { Router } from '@angular/router';

@Component({
	selector: 'app-header-dashboard',
	templateUrl: './dashboard-header.component.html',
	styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnInit, AfterViewInit, OnDestroy {

	public location: any = location;
	// store count of unread message
	public unreadMessagesCount: number = 0;
	public dashboard: any;
	public headerDataLoad: boolean = false;
	public unreadInterviewsCount: number = 0
	public unreadOffersCount: number = 0;
	public newMessageReceived: boolean = false;
	public newApplicationRecieved: boolean = false;
	public userType: any;
	public userName: any;

	// ara to load new and unread messages
	private _unreadMessagesArray: any[] = [];
	private _unreadInterviewsArray: any[] = [];
	private _unreadOffersArray: any[] = [];

	constructor(private _router: Router,
		private _cookieService: CookieService,
		private _socketIOService: SocketIOService,
		private _eventService: EventService,
		private _userService: UserService,
		private _settingsService: SettingsService,
		private _profileDataService: ProfileDataService,
		private _modalService: NgbModal) { }

	ngOnInit(): void {

		if (this._cookieService.check('token_Hire')) {
			// connect messaging service
			if (this._socketIOService.getConnectionStatus() === false || this._socketIOService.getConnectionStatus() === undefined) {
				this._socketIOService.connect();
			}
			this._cookieService.delete('filters');
			// call _loadDashboardHeaderData
			this._loadDashboardHeaderData();
			this._getBasicInfo();
			// get user type
			this._getUserType();
			this.userType = this._cookieService.get('account_type');
		}

		// name changed fro settings
		this._eventService.subscribe('change-username', (data: any) => {
			this.dashboard.Profile.Name = `${data.first_name} ${data.last_name}`;
		});

		// after mark as read mesages
		this._eventService.subscribe('sendMessageMarkasRead', (data: any) => {
			this._unreadMessagesArray.forEach((value: any, key: any) => {
				if (value.Conversation_id === data.Conversation_id) {
					//console.log('value.Unread_Message_Count ' + value.Unread_Message_Count);
					this.unreadMessagesCount -= value.Unread_Message_Count;
					value.Unread_Message_Count = 0;
					if (this.unreadMessagesCount === 0) {
						this.newMessageReceived = false;
					}
					//console.log('unreadMessagesCount ' + this.unreadMessagesCount);
				}
			});
		});

		// offer mark as read received
		this._eventService.subscribe('offerMarkasRead', (data: any) => {
			//console.log(data);
			this.unreadOffersCount -= 1;
		});
		this._activateSocketListeners();

		// TODO
		// catch location changed or not
		// this.$on('$routeChangeSuccess', function (event, next, current) {
		// 	if (!window.location.pathname.includes('/recruit/profile/') && !window.location.pathname.includes('/findTalent/search/')) {
		// 	  UserServices.removeCookie('filters')
		// 	}
		// });
	}

	ngAfterViewInit(): void {

	}

	ngOnDestroy() {
		this._deactivateSocketListeners();
	}

	private _loadDashboardHeaderData(): void {
		// get dashboard data old
		// this._userService.getDashboardData().subscribe((response: any) => {
		// 	// set cookie without expire date
		// 	this._userService.setCookie('username', response.Data.Profile.Name);
		// 	this.dashboard = response.Data;
		// 	// hide header data until response load
		// 	this.headerDataLoad = true;
		// });

		// get message count new
		this._userService.getHeaderData().subscribe((response: any) => {
			if (response.Code == 200) {
				this._unreadMessagesArray = response.Data.UnreadMessges;
				this._unreadInterviewsArray = response.Data.UnreadInterviews;
				this._unreadOffersArray = response.Data.UnreadOffers;

				this.unreadInterviewsCount = this._unreadInterviewsArray.length;
				this.unreadOffersCount = this._unreadOffersArray.length;
				// calculate unread mesages ammount
				this._calculateUnreadMessages();
			} else {
				this._userService.logout().subscribe((response: any) => { });
			}
		});
	}

	// calculate unread messages
	private _calculateUnreadMessages(): void {
		this._unreadMessagesArray.forEach((value: any, key: any) => {
			this.unreadMessagesCount += value.Unread_Message_Count;
		});
	}

	// get basic info
	private _getBasicInfo(): void {
		// call settings service
		this._settingsService.getAccountBasicInfo().subscribe((response: any) => {
			this._profileDataService.setUserBasicInfo(response.Data);
			this._eventService.broadcast('setUserBasicInfo', response.Data);
			this.userName = response.Data.first_name + " " + response.Data.last_name;
			//console.log(response);
			this.headerDataLoad = true;
		});
	}

	// get user type
	private _getUserType(): void {
		// call settings service
		this._settingsService.getUserType().subscribe((response: any) => {
			//console.log('getUserType');
			//console.log(response);
			this._profileDataService.setPageRespond(true);
			if (response.Code === 200) {
				// assign user type
				this._profileDataService.setUserType(response.Data.user_type);
				this._eventService.broadcast('setUserType', response.Data.user_type);
			}
		});
	}

	// logout
	logout(): void {
		this._userService.logout().subscribe((response: any) => { });
	}

	private _activateSocketListeners(): void {
		// catch newMessageReceived
		this._socketIOService.on('MessageReceived', (data: any) => {
			// show message count box
			//console.log(data);
			this.newMessageReceived = true;
			this.unreadMessagesCount += 1;

			// push to conversation array
			let indexVal = this._checkContainsConversation(data);
			if (indexVal) {
				this._unreadMessagesArray[indexVal].Unread_Message_Count += 1;
			} else {
				// create new object and push to conversation array
				let newUnreadMessage: any = {};
				newUnreadMessage.Conversation_id = data.conversationId;
				newUnreadMessage.Unread_Message_Count = 1;
				this._unreadMessagesArray.push(newUnreadMessage);
			}
			// listeninig in dashboard view
			this._eventService.broadcast('newMessageReceived', data);
		});


		// catch new applications
		this._socketIOService.on('ApplicationRecieved', (jobID: any) => {
			// show message count box
			//console.log('DashboardHeaderController : SOCKET : ApplicationRecieved for jobID : ' + jobID)
			this.newApplicationRecieved = true;
			// listeninig in dashboard view
			this._eventService.broadcast('newApplicationRecieved', jobID);
		});

		// interview decline
		this._socketIOService.on('InterviewDecline', (data: any) => {
			//console.log('interview decline');
			//console.log(data);
			this.unreadInterviewsCount += 1;
			// check user route
			if (this.getCurrentUrl() === '/interviews') {
				this._eventService.broadcast('interviewUpdate', data);
			}
		});

		// request reschedule
		this._socketIOService.on('InterviewRequestReschedule', (data: any) => {
			//console.log('interview reschedule');
			//console.log(data);
			this.unreadInterviewsCount += 1;
			// check user route
			if (this.getCurrentUrl()=== '/interviews') {
				this._eventService.broadcast('interviewUpdate', data);
			}
		});

		// intrview accepted
		this._socketIOService.on('InterviewAccepted', (data: any) => {
			//console.log('interview accepted');
			//console.log(data);
			this.unreadInterviewsCount += 1;
			// check user route
			if (this.getCurrentUrl() === '/interviews') {
				this._eventService.broadcast('interviewUpdate', data);
			}
		});

		// interview mark as read feed back
		this._socketIOService.on('FbInterviewMarkAsRead', (data: any) => {
			//console.log(data);
			this.unreadInterviewsCount -= 1;
		});

		// offer sockt on
		this._socketIOService.on('AcceptOfferAlert', (data: any) => {
			//console.log('accept offer');
			data.type = 'ACCEPTED';
			this._offerUpdateContent(data);
		});

		this._socketIOService.on('DeclineOfferAlert', (data: any) => {
			//console.log('deline offer');
			data.type = 'DECLINE';
			this._offerUpdateContent(data);
		});

		this._socketIOService.on('ResponseAttachment', (data: any) => {
			//console.log('withdraw offer');
			data.type = 'OFFER_RESPONSE';
			this._offerUpdateContent(data);
		});
	}

	private _deactivateSocketListeners(): void {
		this._socketIOService.removeListener('ApplicationRecieved');
		this._socketIOService.removeListener('MessageReceived');
		this._socketIOService.removeListener('FbInterviewMarkAsRead');
		this._socketIOService.removeListener('InterviewAccepted');

		this._socketIOService.removeListener('AcceptOfferAlert');
		this._socketIOService.removeListener('DeclineOfferAlert');
		this._socketIOService.removeListener('ResponseAttachment');
	}

	// offer request update
	private _offerUpdateContent(offerData: any): void {
		this.unreadOffersCount += 1;
		// check user route
		if (this.getCurrentUrl() === '/onboard') {
			this._eventService.broadcast('offerUpdate', offerData);
		}
	}

	// check conversation id already exist in onload array
	private _checkContainsConversation(data: any): any {
		let statusChanege = true;
		let testKey = '';
		this._unreadMessagesArray.forEach((value: any, key: any) => {
			if (statusChanege) {
				if (value.Conversation_id === data.conversationId) {
					statusChanege = false;
					testKey = key;
				}
			}
		});
		return testKey;
	}

	CreateTalent(): void {
		let modal = this._modalService.open(CreateTalentInviteComponent, { windowClass: 'jobPositions new-talent', backdrop: 'static', keyboard: false });
		modal.componentInstance.contect = 'HEADER';
		modal.result.then((closed) => {

		},
		(dismissed) => {
			//console.log('Rejected cancel application');
		});
	}

	InviteEmail(): void {
		let modal = this._modalService.open(CreateEmailInviteComponent, { windowClass: 'interview email-invite', backdrop: 'static', keyboard: false });
		modal.result.then((closed) => {

		},
		(dismissed) => {
			//console.log('Rejected cancel application');
		});
	}

	getCurrentUrl(): string {
		return this._router.url;
	}

}
