<form #addMemberForm="ngForm">
    <div class="add-member-item col-lg-12" [ngClass]="{'no-hover' : index === 0 }">
        <div class="member-no col-lg-1">
            <div class="member-no-count">{{index + 1}}</div>
            <div class="member-no-close" (click)="removeField(members, index)"></div>
        </div>
        <div class="member-info-wrapper form-group col-lg-11">
            <div class="row">
                <div class="member-email-address-wrapper col-lg-4">
                    <input class="form-control" name="emailaddress-{{index}}" [ngClass]="{'error': checkForErrors(index)}"  placeholder="Email" type="email" [(ngModel)]="members[index].email" [maxLength]="128" required>
                </div>
                <div class="member-first-name-wrapper col-lg-3">
                    <input class="form-control" [ngClass]="{'error' : object.foundError}" placeholder="First name" name="first-name{{index}}" type="text" [(ngModel)]="members[index].first_Name" [maxLength]="20" required>
                </div>
                <div class="member-last-name-wrapper col-lg-3">
                    <input class="form-control" [ngClass]="{'error' : object.foundError}" placeholder="Last name" name="last-name{{index}}" type="text" [(ngModel)]="members[index].last_Name" [maxLength]="40">
                </div>
            </div>
        </div>
    </div>
</form>
