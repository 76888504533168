import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../core/services/user.service';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'show-recruit-notes',
	templateUrl: './show-notes.component.html',
	styleUrls: ['./show-notes.component.scss']
})
export class ShowRecruitNotesComponent implements OnInit {

	@Input() userTitle: any;
	@Input() recruit_id: any = 999;
	@Input() type: string = 'Hire';

	public showAllApplicantsPreloader: boolean = true;
	public isPrivate: boolean = false;
	public showNotes: string = '';
	public teamNotes: any[] = [];

	constructor(public utilityService: UtilityService,
		private _modal: NgbActiveModal,
		private _userService: UserService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {
		this._userService.getRecruitNotes(this.recruit_id).subscribe((response: any) => {
			if (response.status === 200 && response.Code === 200) {
				let CN = response.Data.rNotes;
				if (CN !== null && CN !== undefined && CN !== '') {
					this.showNotes = 'Date: $TODAY$' + '\n\n' + CN;
				} else {
					this.showNotes = 'Date: $TODAY$' + '\n';
				}
				this.isPrivate = response.Data.rPrivate;
				this.showAllApplicantsPreloader = false;
				this.teamNotes = response.Data.teamRecruitNotes;

			} else {
				// show error message
				this._toastr.error('Something went wrong!');
			}
		});
	}

	// Method of the GetApplicantNotes popup
	saveNotesData(rNotes: any, rPrivate: any): any {
		let todaytext = moment(new Date()).format('YYYY-MM-DD @ h:mm:ss a')
		if (rNotes !== null && rNotes !== undefined) {
			rNotes = rNotes.replace(/\$TODAY\$/g, todaytext);
		}
		this._userService.saveRecruitNotes(this.recruit_id, rNotes, rPrivate).subscribe((response: any) => {
			// console.log(response);
			if (response.Code === 200) {
				this._toastr.success(`${this.type} Notes are Saved.`);
			} else {
				this._toastr.error('Something went wrong!');
			}
		});
	}


	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
