<div class="search">
	<div class="search-container">
		<input class="form-control" id="job-title-input" [ngClass]="{'error' : foundError, 'margin-bottom': addBottomMargin}" [(ngModel)]="selectedtitle"
			placeholder="Enter Job Title" (ngModelChange)="foundError = false; setJobTitleKeyWord()" [readOnly]="readOnly"
			[ngbTypeahead]="searchJobTitle" (selectItem)="dropdownClicked($event)" [resultTemplate]="rt"
			[maxlength]="100" tabindex="0">
		<ng-template #rt let-r="result" let-t="term">
			<ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
		</ng-template>
	</div>
</div>
