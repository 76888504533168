import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'profile-video-applicant',
	templateUrl: './profile-video-applicant.component.html',
	styleUrls: ['./profile-video-applicant.component.scss']
})
export class ProfileVideoApplicantComponent {

	@Input() profileVideoV2: boolean = true;
	@Input() sectionData: any = {};

	constructor(private _modal: NgbActiveModal,
				public utilityService: UtilityService) { }

	closeThisDialog() {
		this._modal.dismiss();
	}

}
