<div class="pop-up-content">
	<div class="process-wrapper" *ngIf="context == 'INTERVIEW'">
		<div class="step-wrapper continue active prev">
		  <span class="step">01</span>
		  Select Candidates
		</div>
		<div class="step-wrapper continue active prev">
		  <span class="step">02</span>
		  Interview Details
		</div>
		<div class="step-wrapper continue active">
		  <span class="step">03</span>
		  Time Selection
		</div>
		<div class="step-wrapper">
		  <span class="step">04</span>
		  Preview
		</div>
	  </div>
	<div class="process-wrapper reschedule" *ngIf="context == 'PROFILE'">
		<div class="step-wrapper continue active prev">
			<span class="step">01</span>
			Interview Details
		</div>
		<div class="step-wrapper continue active">
			<span class="step">02</span>
			Time Selection
		</div>
		<div class="step-wrapper">
			<span class="step">03</span>
			Preview
		</div>
	</div>
	<div class="timezone-wrapper time-dropdown">
		<p class="label">Time Zone</p>
		<div class="select-wrapper">
			<select class="form-control" [(ngModel)]="selectTimeZone" (ngModelChange)="onSelectTimeZone()">
				<option *ngFor="let timezone of timeZoneList" [value]="timezone.timezoneVal"
					[selected]="setSelectedTimeZone(timezone, selectTimeZone)">{{timezone.text}}</option>
			</select>
		</div>

		<div class="dropdown" ngbDropdown placement="bottom-right" container="body">
			<button class="btn btn-default sync-calendar dropdown-toggle" ngbDropdownToggle
				id="sync-calendar-list-dropdown-ui"
				(click)="!isCalendarSyncAvailable() ? showSettingsCalendar() : ''; ">
				Show Calendars
			</button>

			<div ngbDropdownMenu class="dropdown-menu sync-calendar-list-dropdown-ui"
				[attr.aria-labelledby]="'sync-calendar-list-dropdown-ui'" [hidden]="!isCalendarSyncAvailable()">

				<ul class="dropdown-ui-lists">
					<span *ngFor="let calendar of calendars | orderBy: ['calendar_type']">
						<b>{{calendar.calendar_owner}}</b>
						<ng-container *ngFor="let list of calendar.calendar_list | orderBy: ['calendar_name']">
							<li *ngIf="calendar.calendar_list.length">
								<div class="checkboxWrapper">
									<input class="form-check" type="checkbox" [(ngModel)]="checkedCalendars[list.id]"
										(click)="onCalendarCheck(calendar.calendar_list, list.calendar_id, checkedCalendars[list.id])" />
									<label class="" for=""></label>
									<span class="">{{list.calendar_name}}</span>
								</div>
							</li>
							<li *ngIf="!calendar.calendar_list.length">No calendars found</li>
						</ng-container>
					</span>
				</ul>

			</div>
		</div>
	</div>
	<p class="interview-select-title">When can you Interview?</p>
	<div class="time-calendar-select-wrapper row">
		<div class="time-dropdown col-lg-6">
			<p class="label">Interview Duration</p>
			<div class="select-wrapper">
				<select class="form-control" [(ngModel)]="slot" (ngModelChange)="getTimeSlots(slot); clikedTimeSlots.length = 0">
					<option value="">Select Duration</option>
					<option *ngFor="let option of intervals" [value]="option.value">{{option.name}}</option>
				</select>
			</div>
		</div>
	</div>
	<p class="when-description">Select Time Slot(s) for Interview Schedule. You are required to add minimum
		<span>{{selectedCandidates.length}}</span> Time Slot(s).
	</p>
</div>
<time-duration [selectedamount]="selectedCandidates.length"
	[timezonecombo]="selectedTimeZoneCombo"
	[timezonelist]="timeZoneList" [clikedTimeSlots]="clikedTimeSlots"
	(selectedTimeArray)="selectedTimeArray($event)">
</time-duration>
<div class="selected-time-wrapper">
	<div class="inner-time-wrapper">
		<time-slots *ngFor="let time of selectedTimes | orderBy:['date']" [settime]="time" [previewClose]="true" [abbrtext]="abbreviationTxt"
			(removeTimeSlots)="removeTimeSlots($event.selectedTime, $event.selectedDate)"></time-slots>
	</div>
</div>
<div class="modal-footer pop-up-footer">
	<button type="submit" class="btn btn-back" (click)="moveBackward.emit(1)">BACK</button>
	<button type="button" class="ngdialog-button popup-footer-save-button"
		(click)="moveForward.emit({tabIndex: 2, interviewData: clikedTimeSlots})"
		[disabled]="!(clikedTimeSlots.length >= selectedCandidates.length)">NEXT</button>
</div>
