<div class="modal-header">
	<h4 class="modal-title" id="modal-title">
		<span>{{candidateLists.title | ellipsis : 50}}</span> -
            {{candidateLists.created_at | date:"MMM d, y @ h:mm a"}}
	</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		Close
	</button>
</div>
<div class="modal-body">
	<div class="ngdialog-body" *ngIf="candidateLists.Candidates.length !== 0">
        <div class="view-list-popup-body-wrapper">
            <ul class="view-list-popup-ul">
                <li *ngFor="let candidate of candidateLists.Candidates | orderBy:['name']">
                    <div class="user-profile-picture">
                        <div *ngIf="candidate.image_url !== undefined && candidate.image_url !== '' "
							[style.background-image]="'url(' + candidateLists.fileServerUrl + candidate.image_url + ')'"
                            class="img"></div>
                        <div *ngIf="candidate.image_url === undefined && candidate.image_url === '' " class="img"></div>
                    </div>
                    <div class="user-profile-info">
                        <div>
                            <a href="/recruit/profile/{{candidate.id}}" target="_blank" ><span
                                class="user-profile-fname">{{candidate.name}}</span></a>
                            <span class="user-profile-location-ui-dash"></span>
                            <span class="user-profile-location">{{candidate.location}}</span>
                            <span class="user-action-buttons pull-right">
                                <button class="user-send-message-button" (click)="startConversation(candidate)">
                                    <i class="img-sprite-1 icon-send-mail-to-user"></i>
                                </button>
                                <button class="user-remove-from-list-button" (click)="removeListItems(candidate)" *ngIf="candidateLists.type != 2">
                                    <i class="img-sprite-1 icon-delete-user-from-list"></i>
                                </button>
                            </span>
                        </div>
                        <div>
                            <span class="user-profile-job">{{candidate.position}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- show empty lists -->
    <div class="ngdialog-body clearfix" *ngIf="candidateLists.Candidates.length === 0">
        <div class="view-list-popup-body-wrapper">
            <div class="view-list-empty-state">
                <p>Your List is empty.</p>
                <p>To add Talents to this List, click below and start a search.</p>
                <a href="/findTalent" target="_blank"><button class="new-talent-search-button">+ New Talent
                        Search</button></a>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer text-right" *ngIf="candidateLists.type != 2">
	<button type="button" class="ngdialog-button popup-footer-delete-button"
		(click)="deleteList(candidateLists.List_id, candidateLists.title)">DELETE LIST</button>
	<button type="button" class="ngdialog-button popup-footer-save-button"
		(click)="saveList(candidateLists.List_id); closeThisDialog()" [disabled]="changeListState">SAVE</button>
</div>
