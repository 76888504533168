import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ContextService } from '../core/services/context.service';
import { SocketIOService } from '../core/services/socket-io.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	constructor(private _contextService: ContextService,
		private _socketIOService: SocketIOService,
		private _cookieService: CookieService) { }

	ngOnInit(): void {
		if (this._cookieService.check('token_Hire') && (this._socketIOService.getConnectionStatus() === false ||
			this._socketIOService.getConnectionStatus() === undefined)) {
			this._socketIOService.connect();
		}
	}

	getHeaderContext() {
		return this._contextService.getHeaderContext();
	}

}
