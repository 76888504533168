import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!(req.body instanceof FormData) && req.url.includes('unnanu.com')) {
			req = req.clone({
				setHeaders: {
					'Content-Type': 'application/json'
				}
			});
		}

		return next.handle(req);
	}
}
