import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'zoom_id'
})

export class ZoomIdPipe implements PipeTransform {

	transform(number: string): string {
		if (number && number.length) {
			return number.substring(0, 3) + '-' + number.substring(3, 7) + '-' + number.substring(7, 11);
		}
		return number;
	}
}
