import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobBoardService } from '../../../core/services/jobBoard.service';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'show-applicant-answers',
	templateUrl: './show-applicant-answers.component.html',
	styleUrls: ['./show-applicant-answers.component.scss']
})
export class ShowApplicantAnswersComponent implements OnInit {

	@Input() jobdata: any = {};
	@Input() jobtitle: any = null;
	@Input() vacancyID: any;
	@Input() applicantName: any;
	@Input() applicationDate: any;
	@Input() recruitId: any;

	// call job board service to get all apllicants
	public jobApplicant_jobref: any;
	public jobApplicant_jobTitle: any;
	public jobApplicant_applicantName: any;
	public jobApplicant_applicationDate: any;
	public jobApplicant_Answers: any[] = [];
	public jobApplicant_preloader: boolean = true;

	constructor(public utilityService: UtilityService,
		private _modal: NgbActiveModal,
		private _sanitizer: DomSanitizer,
		private _jobBoardService: JobBoardService) { }

	ngOnInit(): void {
		this.jobApplicant_jobref = this.jobdata.job_ref_id;
		this.jobApplicant_jobTitle = this.jobtitle;
		this.jobApplicant_applicantName = this.applicantName;
		this.jobApplicant_applicationDate = this.applicationDate;

		this._jobBoardService.getApplicantResponseData(this.vacancyID, this.recruitId).subscribe((response: any) => {
			// console.log('getApplicantResponseData response')
			// console.log(response)
			// hide preloader
			this.jobApplicant_preloader = false
			if (response.status === 200 && response.Code === 200) {
				this.jobApplicant_Answers = response.Data
				this.jobApplicant_Answers.forEach((question: any, key: any) => {
					question.question_body = this._sanitizer.bypassSecurityTrustHtml(question.question_body.replace(this.utilityService.url_pattern_expression, '<a target="_blank" href="$1">$1</a>'));
					if (question.question_type == 1 || question.question_type == 2 || question.question_type == 3) {
						let checkCount = 0
						question.answers.forEach((answer: any, key: any) => {
							answer.answer = this._sanitizer.bypassSecurityTrustHtml(answer.answer.replace(this.utilityService.url_pattern_expression, '<a target="_blank" href="$1">$1</a>'));
							if (answer.is_selected) {
								checkCount += 1
							}
						});
						if (checkCount < 1) {
							question.skipped = true
						}
					} else if (question.question_type == 5) {
						if (!question.answers[0].answer || question.answers[0].answer == '') {
							question.skipped = true
						} else {
							question.answers[0].answer = JSON.parse(question.answers[0].answer)
						}
					} else if (question.question_type == 4) {
						if (!question.answers.length) {
							question.skipped = true
						} else {
							question.answers[0].answer = this._sanitizer.bypassSecurityTrustHtml(question.answers[0].answer.replace(this.utilityService.url_pattern_expression, '<a target="_blank" href="$1">$1</a>'));
						}
					} else if (question.question_type == 0) {
						if (!question.answers[0].answer) {
							question.skipped = true
						} else {
							question.answers[0].answer = this._sanitizer.bypassSecurityTrustHtml(question.answers[0].answer.replace(this.utilityService.url_pattern_expression, '<a target="_blank" href="$1">$1</a>'));
						}
					} else {

					}

				});
			}
		});

	}


	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
