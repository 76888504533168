import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { OfferService } from '../../../core/services/offer.service';
import { FileSizeExceedAlertComponent } from '../file-size-exceed-alert/file-size-exceed-alert.component';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'offer-details',
	templateUrl: './offer-details.component.html',
	styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent implements OnInit {

	@Input() offerData: any = {};
	@Input() offer_other: any;
	@Input() selectedCandidates: any[] = [];
	@Input() offerToSend: string = '';

	@Output() moveBackward: EventEmitter<any> = new EventEmitter<any>();
	@Output() moveForward: EventEmitter<any> = new EventEmitter<any>();

	// for auto complete
	public index: number = 1;
	public offer: any = {};
	public todaytext: string = 'Date: ' + moment(new Date()).format('YYYY-MM-DD @ h:mm:ss a') + '\n';
	public uploadingAttachment: boolean = false;
	public startPreloader: boolean = false;
	public offerTemplates: any[] = [];
	public templateDropDown: string = '';
	public checkOfferTemplate: boolean = false;

	public people: any[] = [
		{
			label: 'TODAY',
		},
		{
			label: 'FULL_NAME',
		},
		{
			label: 'FIRST_NAME',
		},
		{
			label: 'LAST_NAME',
		},
	];

	constructor(public utilityService: UtilityService,
				private _element: ElementRef,
				private _modalService: NgbModal,
				private _offerService: OfferService,
				private _toastr: ToastrService) { }

	ngOnInit(): void {
		this.offer.offer_message = this.todaytext;
	}

	searchPeople(searchString: string, items: any[]): any[] {
		return items.filter(item => item.label.toLowerCase().includes(searchString));
	}

	// get choosed keyword from directive
	keywordChosen(keyword: string): void {
		this.offer.job_title = keyword;
		this.offerData.job_title = keyword;
	}

	getPeopleText(item: any): any {
		if (item.label === 'TODAY') {
			return moment(new Date()).format('YYYY-MM-DD @ h:mm:ss a');
		} else {
			return '$' + item.label + '$';
		}
	}

	// remove uploaded attachment
	removeUploadedAttachment(attachmentIndex: number): void {
		this.offerData.attachments.splice(attachmentIndex, 1);
	}

	onFileChange(event: any): void {
		if (event.target.files.length && event.target.files[0].size <= 10485760 &&
			(event.target.files[0].type === 'application/pdf' ||
			event.target.files[0].type === 'application/msword' ||
			event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
			this.uploadAttachment(event.target.files[0].name, event.target.files[0]);
		} else {
			let modal = this._modalService.open(FileSizeExceedAlertComponent, { windowClass: 'alertNew', backdrop: 'static', keyboard: false });
			modal.componentInstance.errorStatus = 'offers';
			modal.result.then((closed) => {

			},
			(dismissed) => {
				this._element.nativeElement.querySelector('input[type="file"]').value = null;
			});
		}
	}

	// upload attachment
	uploadAttachment(attachmentIndex: string, filedata: any): void {
		//console.log(filedata);
		// show preloader
		this.uploadingAttachment = true;
		this._offerService.uploadAttachment(filedata).subscribe((response: any) => {
			//console.log(response);
			//console.log(attachmentIndex);
			this.uploadingAttachment = false;
			if (response.Code === 200) {
				this.offerData.attachments.push(response.Data);
			}
		});
	}

	// get templates data
	getNewOfferTemplates(checkOfferTemplate: boolean): void {
		if (checkOfferTemplate) {
			// start preloader
			this.startPreloader = true;
			this._offerService.getOfferTemplates().subscribe((response: any) => {
				// end preloader
				this.startPreloader = false;
				//console.log(response);
				if (response.Code === 200) {
					this.offerTemplates = response.Data.templates;
				} else {
					this._toastr.error('Something went wrong!');
				}
			});
		} else {
			if (this.offerTemplates) {
				this.templateDropDown = '';
				this.offerTemplates.length = 0;
				this.offer.offer_message = undefined;
				this.offerData.attachments.length = 0;
			}
		}

		this.offer.offer_message = 'Date: ' + moment(new Date()).format('YYYY-MM-DD @ h:mm:ss a') + '\n';
	}

	// set template data
	setTemplateData(templateData: string): void {
		//console.log(templateData);
		if (this.templateDropDown === '') {
			this.offer.offer_message = undefined;
			this.offerData.attachments.length = 0;
		} else {
			this.offerTemplates.forEach((val: any, key: any) => {
				if (val.template_id === Number(templateData)) {
					let todaytext: any = moment(new Date()).format('YYYY-MM-DD @ h:mm:ss a');
					if (val.template_message !== null && val.template_message !== undefined) {
						this.offer.offer_message = val.template_message.replace(/\$TODAY\$/g, todaytext);
					}
					this.offerData.attachments = val.attachments;
				}
			});
		}
	}

}
