<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Confirm "Delete" List</h4>
</div>
<div class="modal-body">
	<p>Are you sure you want to delete - "<span>{{listTitle | ellipsis : 50}}</span>" List?</p>
</div>
<div class="modal-footer text-right">
	<div class="button-wrapper">
		<button type="button" class="btn popup-footer-cancel-button" (click)="skip()">NO</button>
		<button type="button" class="btn popup-footer-yes-button" (click)="delete()">YES</button>
	</div>
</div>
