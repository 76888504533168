import * as io from 'socket.io-client';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventService } from './event.service';
import { CookieService } from 'ngx-cookie-service';

type SocketIOCallback = (data: any) => void;

@Injectable()
export class SocketIOService {

	private socketServerURL = environment.urls.socketServerURL;

	private socket: any;

	constructor(private _eventService: EventService,
		private _cookieService: CookieService) {
		this.socket = io(this.socketServerURL);
	}

	on(eventName: string, callback: SocketIOCallback) {
		if (this.socket !== undefined) {
			this.socket.on(eventName, callback);
		}
	}

	emit(eventName: string, data: any) {
		if (this.socket !== undefined) {
			this.socket.emit(eventName, data);
		}
	}

	connect() {
		if (this.socket && this.socket.connected === false) {
			let tokenData: any = {};
			tokenData.AuthToken = this._cookieService.get('token_Hire');
			tokenData.UserType = 2;
			this.socket.emit('AuthorizationToken', tokenData);
			this.socket.connected = true;
			console.log('SOCKET RECONNECTED - HIRE');
		} else {
			this.reconnect();
		}

		this._eventService.subscribe('sendMessageMarkasRead', (payload: any) => {
			let tokenData: any = {};
			tokenData.conversation_id = payload.Conversation_id;
			tokenData.token = this._cookieService.get('token_Hire');
			tokenData.userType = '2';
			this.socket.emit('MarkAsReadMessage', tokenData)
		});

		this.socket.on('SuccessMarkAsReadMsg', (data: any) => {
			//console.log('SuccessMarkAsReadMsg');
			//console.log(data);
		});

		this.socket.on('FbCreateInterview', (data: any) => {
			//console.log(data);
			//console.log('interview successfully create');
		});

		// exception found
		this.socket.on('Exception', (data: any) => {
			//console.log('Exception HIRE');
			//console.log(data);
		});

	}

	reconnect() {
		this.socket = io(this.socketServerURL, {
			forceNew: true
		});
		let tokenData: any = {};
		tokenData.AuthToken = this._cookieService.get('token_Hire');
		tokenData.UserType = 2;
		this.socket.emit('AuthorizationToken', tokenData);
		console.log('SOCKET RECONNECTED - HIRE');
	}

	disconnect() {
		if (this.socket !== undefined && this.socket.connected) {
			this.socket.close();
			this.socket = undefined;
		}
	}

	sendMessage(messageData: any) {
		//console.log(messageData)
		if (this.socket.connected) {
			this.socket.emit('SendMessage', messageData);
		} else {
		  //console.log('message cant send, connection not yet started');
		}
	}

	createInterview(interviewData: any) {
		this.socket.emit('CreateInterview', interviewData);
	}

	interviewMarkAsRead(interviewData: any) {
		let tokenData: any = {};
		tokenData.token = this._cookieService.get('token_Hire');
		tokenData.userType = '2';
		tokenData.userId = this._cookieService.get('user_id_Hire');
		tokenData.interview_content_id = interviewData;
		this.socket.emit('InterviewMarkAsRead', tokenData);
		//console.log(tokenData);
	}

	postponeInterview(interviewData: any) {
		this.socket.emit('PostponeInterview', interviewData);
		//console.log(interviewData);
	}

	removeInterview(interviewData: any) {
		this.socket.emit('CancelInterview', interviewData);
		//console.log(interviewData);
	}

	joinNewUser(teamMemberData: any) {
		if (this.socket === undefined) {
		  this.socket = io.connect(this.socketServerURL);
		  let tokenData: any = {};
		  tokenData.AuthToken = teamMemberData.Token;
		  tokenData.UserType = 2;
		  this.socket.emit('AuthorizationToken', tokenData);
		  console.log('SOCKET CONNECTED - HIRE');
		  this.socket.emit('JoinNewMember', teamMemberData);
		}
		//console.log(teamMemberData);
	}

	// make offer mark as read true or false
	offerMarkAsRead(offerData: any) {
		let tokenData: any = {};
		tokenData.token = this._cookieService.get('token_Hire');
		tokenData.userType = 2;
		tokenData.userId = this._cookieService.get('user_id_Hire');
		tokenData.offer_content_id = offerData;
		this.socket.emit('OfferMarkAsRead', tokenData);
		//console.log(tokenData);
	}

	changeOfferStatus(offerData: any) {
		let tokenData: any = {};
		tokenData.token = this._cookieService.get('token_Hire');
		tokenData.userType = 2
		tokenData.userId = this._cookieService.get('user_id_Hire');
		tokenData.offer_content_id = offerData;
		this.socket.emit('ChangeOfferStatus', tokenData);
		//console.log('CHANGE OFFER STATYS');
		//console.log(tokenData);
	}
	// withdraw offer
	withdrewOffer(offerData: any) {
		let tokenData: any = {};
		tokenData.recruit_user_id = offerData.recruit_user_id;
		tokenData.offer_content_id = offerData.offer_content_id;
		//console.log(tokenData);
		this.socket.emit('WithdrewOffer', tokenData);
	}

	// create new offer
	createNewOffer(newOfferData: any) {
		let tokenData: any = {};
		tokenData.offers = [];
		newOfferData.offer_details.forEach((value: any, key: any) => {
		  let singleOffer: any = {};
		  singleOffer.recruit_user_id = value.recruit_id;
		  singleOffer.offer_content_id = value.offer_content_id;
		  tokenData.offers.push(singleOffer);
		});
		//console.log(tokenData);
		this.socket.emit('CreateNewJobOffer', tokenData);
	}

	// onboard someone
	onboardSomeone(onboardData: any) {
		let tokenData: any = {};
		tokenData.recruit_user_id = onboardData.recruit_user_id;
		tokenData.offer_content_id = onboardData.offer_content_id;
		//console.log(tokenData);
		this.socket.emit('OnboardSomeone', tokenData);
	}

	getConnectionStatus() {
		if (this.socket) {
		  return this.socket.connected;
		} else {
		  return this.socket;
		}
	}
	destroyConnection() {
		// socket.removeListener()
	}

	removeListener(name: any) {
		if (this.socket !== undefined && this.socket.connected) {
		  this.socket.removeListener(name);
		}

		// this.socket.removeAllListeners()
	}

}
