import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../core/services/user.service';

@Component({
	selector: 'create-list',
	templateUrl: './create-list.component.html',
	styleUrls: ['./create-list.component.scss']
})
export class CreateListComponent implements OnInit {

	@Input() findTalentLists: any;

	public list: any = {};

	constructor(private _modal: NgbActiveModal,
		private _userService: UserService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {

	}

	saveList(listData: any): void {
		listData.Users = [];
		this.list = {};
		this._userService.createList(listData).subscribe((response: any) => {
			if (response.Code === 200) {
				// tempory array
				let date = new Date();
				let temporyList: any = {
					Candidates: [],
					created_at: `${date.getFullYear()}-${date.getMonth()+1}-${date.getDay()}T${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`,
					title: listData.List_name,
					List_id: response.Data.List_id
				};

				// push temporyList
				this.findTalentLists.SavedList.unshift(temporyList);
				this._toastr.success(`Job listing created`);
			} else if (response.Code === 401) {
				this._toastr.error(`List Already Exists.`);
			}
			this.list.List_name = '';
			this.closeThisDialog();
		});
	}

	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
