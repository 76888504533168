import { Injectable } from '@angular/core';

@Injectable()
export class ContextService {

	private headerContext: string = '';
	private showDashboard: boolean = false;

	constructor() { }

	getHeaderContext(): string {
		return this.headerContext;
	}

	setHeaderContext(context: string): void {
		this.headerContext = context;
	}

	getShowDashboard(): boolean {
		return this.showDashboard;
	}

	setShowDashboard(showDashboard: boolean): void {
		this.showDashboard = showDashboard;
	}
}
