import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'show-settings-calendar',
	templateUrl: './show-settings-calendar.component.html',
	styleUrls: ['./show-settings-calendar.component.scss']
})
export class ShowSettingsCalendarComponent {

	constructor(private _modal: NgbActiveModal) { }

	closeThisDialog() {
		this._modal.dismiss();
	}

}
