import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'trix-editor',
	templateUrl: './trix-editor.component.html'
})
export class TrixEditorComponent implements OnInit, OnChanges {

	@Input() editor: any;
	@Input() data: any;
	@Output() onValueChange: EventEmitter<any> = new EventEmitter<any>();

	element: any;

	constructor() {
	}

	ngOnInit() {
		this.element = document.querySelector('trix-editor');
		this.element.addEventListener('trix-initialize', (event: any) => {
			this.element.editor.loadHTML(this.data);
		});

		this.element.addEventListener('trix-change', (event: any) => {
			this.data = event.currentTarget.innerHTML;
			this.onValueChange.emit(this.data);
		});

		this.element.addEventListener('trix-attachment-add', (event: any) => {
			event.preventDefault();
		});

		this.element.addEventListener('trix-file-accept', (event: any) => {
			event.preventDefault();
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Check if it is cleared or force update
		if (!changes.data.currentValue) {
			this.element?.editor.loadHTML(this.data);
		}
	}

}
