import { Injectable } from '@angular/core';

@Injectable()
export class ProfileDataService {

	public userBasicInfo: any = {};
	public userType: any;
	public pageRespond: boolean = false;

	constructor() {

	}

	getUserBasicInfo(): any {
		return this.userBasicInfo;
	}

	getPageRespond(): boolean {
		return this.pageRespond;
	}

	getUserType(): any {
		return this.userType;
	}

	setUserBasicInfo(data: any): void {
		this.userBasicInfo = data;
	}

	setPageRespond(data: boolean): void {
		this.pageRespond = data;
	}

	setUserType(data: any): void {
		this.userType = data;
	}

}
