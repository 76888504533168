import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EventService } from "../../../core/services/event.service";
import { CalendarService } from "../../../core/services/calendar.service";
import { InterviewService } from "../../../core/services/interview.service";
import { ShowSettingsCalendarComponent } from "../show-settings-calendar/show-settings-calendar.component";
import { UtilityService } from "../../../core/services/utility.service";

@Component({
	selector: 'time-selection',
	templateUrl: './time-selection.component.html',
	styleUrls: ['./time-selection.component.scss']
})
export class TimeSelectionComponent implements OnInit {

	@Input() context: string = 'INTERVIEW';
	@Input() selectedCandidates: any[] = [];
	@Input() abbreviationTxt: string = '';
	@Input() selectedTimeZoneCombo: any = '';

	@Output() moveBackward: EventEmitter<any> = new EventEmitter<any>();
	@Output() moveForward: EventEmitter<any> = new EventEmitter<any>();
	@Output() selectedTimeZone: EventEmitter<any> = new EventEmitter<any>();

	public clikedTimeSlots: any[] = [];
	public calendars: any[] = [];
	public checkedCalendars: any = {};
	public selectTimeZone: string = '';
	public selectTimeZoneText: string = '';
	public slot: string = '';
	public timeZoneList: any[] = [];
	public intervals: any[] = [
		{
			value: 15,
			name: '15 minutes'
		},
		{
			value: 30,
			name: '30 minutes'
		},
		{
			value: 45,
			name: '45 minutes'
		},
		{
			value: 60,
			name: '60 minutes'
		},
		{
			value: 90,
			name: '90 minutes'
		}
	];
	public selectedTimes: any[] = [];
	public selectedTimesAbbr: string = '';

	constructor(private _modalService: NgbModal,
		private _utilityService: UtilityService,
		private _interviewService: InterviewService,
		private _calendarService: CalendarService,
		private _eventService: EventService) { }

	ngOnInit(): void {
		// get current time timeZoneList
		let d: Date = new Date();
		let n: number = d.getTimezoneOffset();
		// if your time zone is gmt+2, -120 will be returned.
		this.selectTimeZone = (n * 60 * -1).toString();
		this.selectTimeZoneText = 'current timezone (GMT ' + (n / 60) * -1 + ')';
		// this.slot = this.intervals[0].value;
		// this.getTimeSlots(this.slot);
		// get time zone list from service
		this._interviewService.getTimeZoneList().subscribe((response: any) => {
			// assign time zone data to scope
			this.timeZoneList = response;
			this.selectedTimeZone.emit(this.checkTimeZone(this.selectTimeZone));
		});

		this._calendarService.getCalendarList().subscribe((response: any) => {
			this.calendars = response.Data == "No Data" ? [] : response.Data;
			if (this.calendars.length) {
				for (let i = 0; i < this.calendars.length; i++) {
					this.calendars[i].calendar_list.forEach((calendar: any) => {
						this.checkedCalendars[calendar.id] = calendar.selected;
						if (calendar.selected) {
							this._eventService.broadcast('sync-calendar', { calendar: calendar, sync: calendar.selected });
						}
					});
				}
			}
		});

		this._eventService.subscribe('update-ms-token', (data: any) => {
			for (let i = 0; i < this.calendars.length; i++) {
				if (this.calendars[i].calendar_type == 2) {
					for (let j = 0; j < this.calendars[i].calendar_list.length; j++) {
						this.calendars[i].calendar_list[j].token = data.token;
					}
					this._calendarService.addCalendar(this.calendars[i].calendar_list).subscribe(() => { });
					break;
				}
			}
		});
	}

	setSelectedTimeZone(a: any, b: any): boolean {
		return Number(a.timezoneVal) === Number(b);
	}

	checkTimeZone(tzVal: any): any {
		if (this.timeZoneList) {
			let t: boolean = true;
			let x: any;
			this.timeZoneList.forEach((val: any, key: any) => {
				if (t) {
					if (val.timezoneVal === tzVal) {
						t = false;
						x = val;
					}
				}
			});
			if (x) {
				this.selectedTimeZoneCombo = this._utilityService.getTimeZoneString(Number(x.timezoneVal));
			}
			return x;
		}
	}

	getTimeSlots(slot: string): void {
		this._eventService.broadcast('countTimeSlots', slot.trim().length === 0 ? undefined : slot);
	}

	// click time slots
	selectedTimeArray(timeArrayData: any): void {
		this.selectedTimes = this.clikedTimeSlots;
		this.selectedTimesAbbr = this.abbreviationTxt;
	}

	// remove items from selection
	removeTimeSlots(selectedTime: any, selectedDate: any): void {
		this.clikedTimeSlots.forEach((value: any, key: any) => {
			if (value.date === selectedDate && value.time === selectedTime) {
				this._eventService.broadcast('removedTImeSlots', this.clikedTimeSlots[key]);
				this.clikedTimeSlots.splice(key, 1);
			}
		})
		this.selectedTimes = this.clikedTimeSlots;
	}

	// Start SR 1/6/20
	isCalendarSyncAvailable(): boolean {
		return this.calendars.length > 0
	}

	showSettingsCalendar(): void {
		this.checkedCalendars = {};
		let modal = this._modalService.open(ShowSettingsCalendarComponent, { backdrop: 'static', keyboard: false, windowClass: 'cancel-info right-info' });
	}

	onCalendarCheck(calendars: any[], selected_id: number, sync: boolean): void {
		let index: number = calendars.findIndex((calendar: any) => {
			return calendar.calendar_id == selected_id;
		});
		calendars[index].selected = sync;
		this._calendarService.addCalendar(calendars).subscribe((response: any) => {
			this._eventService.broadcast('sync-calendar', { calendar: calendars[index], sync: sync });
		});
	}

	onSelectTimeZone(): void {
		this.selectedTimeZone.emit(this.checkTimeZone(this.selectTimeZone));
	}

}
