import { NgModule } from '@angular/core';
import { UserService } from './services/user.service';
import { UtilityService } from './services/utility.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { SocketIOService } from './services/socket-io.service';
import { EventService } from './services/event.service';
import { JobBoardService } from './services/jobBoard.service';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { ContextService } from './services/context.service';
import { ResponseInterceptor } from './interceptors/response.interceptor';
import { CalendarService } from './services/calendar.service';
import { AutocompleteService } from './services/autocomplete.service';
import { MessageService } from './services/message.service';
import { OfferService } from './services/offer.service';
import { InterviewService } from './services/interview.service';
import { LinkedinService } from './services/linkedin.service';
import { PaymentService } from './services/payment.service';
import { ProfileDataService } from './services/profile-data.service';
import { SettingsService } from './services/settings.service';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomAdapter, NgbDateCustomParserFormatter } from './services/ngbDatePicker.formatter';

@NgModule({
	declarations: [],
	imports: [
	],
	exports: [
	],
	providers: [
		CookieService,
		UserService,
		SocketIOService,
		EventService,
		UtilityService,
		JobBoardService,
		ContextService,
		CalendarService,
		AutocompleteService,
		MessageService,
		OfferService,
		InterviewService,
		LinkedinService,
		PaymentService,
		ProfileDataService,
		SettingsService,
		{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
		{ provide: NgbDateAdapter, useClass: NgbDateCustomAdapter },
		{ provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
	]
})
export class CoreModule { }
