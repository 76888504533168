import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from './core/services/google-analytics.service';
import { UtilityService } from './core/services/utility.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'unnanu-hire-app-angular';

	constructor(public utilityService: UtilityService, private _ga: GoogleAnalyticsService) { }

	ngOnInit(): void {
		this._ga.initialize();
	}
}

