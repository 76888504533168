import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
	selector: '[checkList]'
})
export class CheckListDirective implements OnInit, OnChanges {

	@Input() checkList: any;

	constructor(public renderer: Renderer2, public elmRef: ElementRef) {

	}

	ngOnInit() {

	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.checkList) {
			this.handler(true);
		}
	}

	@HostListener('change')
	emelentChange() {
		this.handler(false);
	}

	handler(setup?: boolean): void {
		let checked = this.elmRef.nativeElement.checked;
		let index = this.checkList.indexOf(this.elmRef.nativeElement.value);

		if (checked && index == -1) {
			if (setup) this.elmRef.nativeElement.checked = false;
			else this.checkList.push(this.elmRef.nativeElement.value);
		} else if (!checked && index != -1) {
			if (setup) this.elmRef.nativeElement.checked = false;
			else this.checkList.splice(index, 1);
		}
	}
}
