<div class="modal-header">
	<h4 class="modal-title">
		<!-- 5/7/23/MB -->
		Invite Applicants by Email - {{ vacancy_id }} - {{ vacancy_title | ellipsis : 46}}
	</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		Close
	</button>
</div>
<div class="modal-body">
	<div class="pop-up-content">
		<div class="process-wrapper offers new" style="background-color: #32a1f7">
			<div class="step-wrapper" [ngClass]="{ active: viewStatus == 0 }">
				<span class="step"> ! </span> Candidate(s) Email
			</div>
		</div>
	</div>
	<p class="interview-select-title" style="padding-left: 20px">
		Whom do you want to send this invitation to?
	</p>
	<div class="find-from-wrapper" *ngIf="comboStatus && listData.length != 0">
		<label style="padding-left: 20px">Find from</label>
		<div class="select-wrapper">
			<ng-select [(ngModel)]="selectListCombo" (change)="selectList()" [clearable]="false">
				<ng-option value="">Select a Email List</ng-option>
				<ng-option *ngFor="let list of listData | orderBy : ['jobTitle']" value="{{ list.id }}">
					{{list.jobTitle.length > 26? (list.jobTitle | ellipsis : 26) : list.jobTitle}}
				</ng-option>
			</ng-select>
		</div>
	</div>

	<div class="find-from-wrapper">
		<div class="form" style="padding-left: 20px">
			<p>Enter email addresses as a tags and can be remove by clicking 'x'.</p>
			<div class="form-group" style="padding: 20px">
				<tag-input [(ngModel)]="inviteEmailsEnter" [addOnPaste]="true" [pasteSplitPattern]="','"
					[addOnBlur]="true" [separatorKeyCodes]="[9, 13, 188]" [validators]="validators"
					[errorMessages]="errorMessages" [inputClass]="'inviteEmailsEnter'"
					[placeholder]="'Enter email addresses'" [secondaryPlaceholder]="'Enter email addresses'"
					theme="bootstrap">
				</tag-input>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer text-right">
	<button type="button" class="ngdialog-button popup-footer-save-button"
		[disabled]="inviteEmailsEnter.length == 0 || disabled" (click)="disabled = true; sendEmailInvite()">
		SEND
	</button>
</div>
