<!-- 01 - select candidates start -->
<div class="pop-up-content">
	<div class="process-wrapper" *ngIf="showStepUpWrapper">
		<div class="step-wrapper continue active">
			<span class="step">01</span>
			Select Candidates
		</div>
		<div class="step-wrapper continue">
			<span class="step">02</span>
			Interview Details
		</div>
		<div class="step-wrapper continue">
			<span class="step">03</span>
			Time Selection
		</div>
		<div class="step-wrapper">
			<span class="step">04</span>
			Preview
		</div>
	</div>
	<p class="interview-select-title">{{title}}</p>
	<div class="find-from-wrapper" *ngIf="!comboStatus">
		<label>Find from</label>
		<div class="select-wrapper">
			<ng-select [(ngModel)]="selectListCombo" infiniteScroll [scrollWindow]="false" (scrollToEnd)="loadMoreListData()" (change)="selectList()" [clearable]="true" (search)="onSearch($event)">
				<ng-option value="">Search for a List</ng-option>
				<ng-option *ngFor="let list of listData | orderBy:['title']" value="{{list.List_id}}">{{list.title.length > 26 ? (list.title | ellipsis : 26) : list.title}}</ng-option>
			</ng-select>
		</div>
	</div>
</div>
<div class="preloader-container" *ngIf="comboStatus">
	<div class="pre-loader-wrapper">
		<i class="preloader-icon"></i>
	</div>
</div>

<!-- view data start -->
<div *ngIf="!comboStatus">
	<div class="select-all-wrapper" *ngIf="!onLoadState && (candidateList.length > 0) && !emptySelectFound">
		<div class="checkbox-wrapper">
			<input type='checkbox' name='custom-checkbox' [(ngModel)]="selectAllBox.isAllSelected"
				(change)="toggleAll(selectAllBox.isAllSelected)"
				(keyup.enter)="toggleAll(selectAllBox.isAllSelected)" />
			<label for="custom-checkbox"></label>
		</div>
		<p class="select-all">Select All</p>
	</div>
	<div class="results-wrapper" *ngIf="!onLoadState && (candidateList.length > 0) && !emptySelectFound">
		<ng-container *ngFor="let candidate of candidateList | orderBy:['name']; let $index=index" >
			<div class="results-row" *ngIf="$index % 2 == 0">
				<div class="result-item col-lg-6 col-md-6" *ngIf="candidateList[$index] !== undefined">
					<div class="checkbox-wrapper">
						<input type='checkbox' name='custom-checkbox-result-{{$index}}'
							id="custom-checkbox-result-{{$index}}" [(ngModel)]="selected[candidateList[$index].id]"
							(change)="optionToggled()" /><label for="custom-checkbox-result-{{$index}}"></label>
					</div>
					<div class="result-details">
						<div class="profile-picture pic-one">
							<div class="img"
								[style.background-image]="'url(' + utilityService.getCdnUrl(candidate.image_url) + ')'">
							</div>
						</div>
						<div class="applier">
							<h3 class="applier-name">{{candidateList[$index].name | ellipsis:42}}</h3>
							<p class="job-title">{{candidateList[$index].position}}</p>
						</div>
					</div>
				</div>
				<div class="result-item col-lg-6 col-md-6" *ngIf="candidateList[$index+1] !== undefined">
					<div class="checkbox-wrapper">
						<input type='checkbox' name='custom-checkbox-result-{{$index+1}}'
							id="custom-checkbox-result-{{$index+1}}" [(ngModel)]="selected[candidateList[$index+1].id]"
							(change)="optionToggled()" /><label for="custom-checkbox-result-{{$index+1}}"></label>
					</div>
					<div class="result-details">
						<div class="profile-picture pic-one">
							<div class="img"
								[style.background-image]="'url(' + utilityService.getCdnUrl(candidateList[$index+1].image_url) + ')'">
							</div>
						</div>
						<div class="applier">
							<h3 class="applier-name">{{candidateList[$index+1].name | ellipsis:42}}</h3>
							<p class="job-title">{{candidateList[$index+1].position}}</p>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
<!-- view data end -->

<!-- empty state -->
<div class="empty-state" *ngIf="!onLoadState && (candidateList.length === 0) && !emptySelectFound">
	<div class="select-all-wrapper empty-select">
	<p>Lists help you organize the Talents.</p>
	<p>Name your Lists as “Jave Developer”, “Contract” or something similar. You haven’t created List.</p></div>
</div>

<!-- empty state start -->
<div class="select-all-wrapper empty-select" *ngIf="onLoadState && !comboStatus || emptySelectFound && !comboStatus">
	<p>Select from an existing List to select Candidate(s)</p>
</div>

<!-- empty state end -->
<div class="modal-footer pop-up-footer">
	<button type="button" class="ngdialog-button popup-footer-save-button"
		(click)="moveForward.emit({tabIndex: 0, selectedItems: selectedItems})" [disabled]="selectedItems.length ===0">NEXT</button>
</div>
<!-- 01 - select candidates end-->
