import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TagInputModule } from 'ngx-chips';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { MentionModule } from 'angular-mentions';
import { AttachmentViewDirective } from './directives/attachment-view.directive';
import { AutoExpandTextDirective } from './directives/auto-expand-text.directive';
import { CheckListDirective } from './directives/check-list.directive';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { CodeInputModule } from 'angular-code-input';
import { InputRestrictionDirective } from './directives/input-restriction.directive';
import { CreateEmailInviteComponent } from './components/create-email-invite-modal/create-email-invite.component';
import { CreateTalentInviteComponent } from './components/create-talent-invite-modal/create-talent-invite.component';
import { ConfirmAlertComponent } from './components/confirm-alert/confirm-alert.component';
import { FileSizeExceedAlertComponent } from './components/file-size-exceed-alert/file-size-exceed-alert.component';
import { FileViewModalComponent } from './components/file-view-modal/file-view-modal.component';
import { PreloaderComponent } from './components/pre-loader/pre-loader.component';
import { AddMemberComponent } from './components/add-member/add-member.component';
import { DeleteListComponent } from './components/delete-list/delete-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ApplicantsViewDirective } from './directives/applicants-view.directive';
import { ProfileVideoApplicantComponent } from './components/profile-video-applicant/profile-video-applicant.component';
import { ShowApplicantComponent } from './components/show-applicant/show-applicant.component';
import { ShowKeywordsComponent } from './components/show-keywords/show-keywords.component';
import { ShowJobApplicantNotesComponent } from './components/show-notes/show-job-applicant-notes.component';
import { ShowApplicantAnswersComponent } from './components/show-applicant-answers/show-applicant-answers.component';
import { ViewListComponent } from './components/view-list/view-list.component';
import { ProfileVideoComponent } from './components/profile-video/profile-video.component';
import { SectionVideoComponent } from './components/section-video/section-video.component';
import { RouterModule } from '@angular/router';
import { SearchKeywordComponent } from './components/search-keyword/search-keyword.component';
import { SearchCityComponent } from './components/search-city/search-city.component';
import { ShowJobApplicantComponent } from './components/show-job-applicant/show-job-applicant.component';
import { CreateListComponent } from './components/create-list/create-list.component';
import { TimeagoModule } from 'ngx-timeago';
import { CreateOfferOtherComponent } from './components/create-offer-other/create-offer-other.component';
import { OfferDetailsComponent } from './components/offer-details/offer-details.component';
import { SearchJobTitleComponent } from './components/search-job-title/search-job-title.component';
import { PreviewSendOfferComponent } from './components/preview-send-offers/preview-send-offers.component';
import { PreviewCandidatesComponent } from './components/preview-candidates/preview-candidates.component';
import { CreateInterviewProfileComponent } from './components/create-interview-profile/create-interview-profile.component';
import { InterviewDetailsComponent } from './components/interview-details/interview-details.component';
import { ShowSettingsZoomComponent } from './components/show-settings-zoom/show-settings-zoom.component';
import { ZoomIdPipe } from './pipes/zoom-id.pipe';
import { TimeSelectionComponent } from './components/time-selection/time-selection.component';
import { ShowSettingsCalendarComponent } from './components/show-settings-calendar/show-settings-calendar.component';
import { TimeDurationComponent } from './components/time-duration/time-duration.component';
import { TimeSlotComponent } from './components/time-slots/time-slots.component';
import { PreviewSendComponent } from './components/preview-send/preview-send.component';
import { ShowRecruitNotesComponent } from './components/show-notes/show-recruit-notes.component';
import { QuestionAutoCompleteComponent } from './components/question-auto-complete/question-auto-complete.component';
import { SelectCandidatesComponent } from './components/select-candidates/select-candidates.component';
import { ShowJobNotesComponent } from './components/show-notes/show-job-notes.component';
import { NgxSortableModule } from 'ngx-sortable';
import { ViewPaymentComponent } from './components/view-payment/view-payment.component';
import { UpdatePaymentComponent } from './components/view-payment/update-payment.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxStripeModule } from 'ngx-stripe';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { environment } from 'src/environments/environment';
import { UpgradeSubscriptionDirective } from './directives/upgrade-subscription.directive';
import { TrixEditorComponent } from './components/trix-editor/trix-editor.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SearchSkillsComponent } from './components/search-skills/search-skills.component';

@NgModule({
	declarations: [
		CreateEmailInviteComponent,
		CreateTalentInviteComponent,
		ConfirmAlertComponent,
		FileSizeExceedAlertComponent,
		FileViewModalComponent,
		PreloaderComponent,
		AddMemberComponent,
		CreateListComponent,
		DeleteListComponent,
		ProfileVideoApplicantComponent,
		ProfileVideoComponent,
		SectionVideoComponent,
		ShowApplicantComponent,
		ShowJobApplicantComponent,
		ShowKeywordsComponent,
		ShowJobApplicantNotesComponent,
		ShowRecruitNotesComponent,
		ShowJobNotesComponent,
		ShowApplicantAnswersComponent,
		ViewListComponent,
		SearchKeywordComponent,
		SearchCityComponent,
		CreateOfferOtherComponent,
		OfferDetailsComponent,
		PreviewSendOfferComponent,
		PreviewCandidatesComponent,
		SearchJobTitleComponent,
		CreateInterviewProfileComponent,
		InterviewDetailsComponent,
		ShowSettingsZoomComponent,
		TimeSelectionComponent,
		ShowSettingsCalendarComponent,
		TimeDurationComponent,
		TimeSlotComponent,
		PreviewSendComponent,
		SelectCandidatesComponent,
		QuestionAutoCompleteComponent,
		ViewPaymentComponent,
		UpdatePaymentComponent,
		TrixEditorComponent,
		SearchSkillsComponent,
		AutoExpandTextDirective,
		CheckListDirective,
		AttachmentViewDirective,
		EllipsisPipe,
		FilterPipe,
		OrderByPipe,
		ZoomIdPipe,
		InputRestrictionDirective,
		ApplicantsViewDirective,
		UpgradeSubscriptionDirective
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgbModule,
		TagInputModule,
		NgxSliderModule,
		NgAddToCalendarModule,
		NgxMaskModule.forRoot(),
		CodeInputModule,
		InfiniteScrollModule,
		TimeagoModule.forChild(),
		MentionModule,
		NgxSortableModule,
		ToastrModule.forRoot({positionClass: 'toast-top-center', toastClass: "custom-toastr ngx-toastr"}),
		NgxStripeModule.forRoot(environment.keys.stripeKey),
		CurrencyMaskModule,
		NgSelectModule
	],
	exports: [
		CommonModule,
		FormsModule,
		NgbModule,
		TagInputModule,
		ReactiveFormsModule,
		NgxSliderModule,
		NgAddToCalendarModule,
		NgxMaskModule,
		CodeInputModule,
		InfiniteScrollModule,
		TimeagoModule,
		MentionModule,
		NgxSortableModule,
		ToastrModule,
		NgxStripeModule,
		CurrencyMaskModule,
		NgSelectModule,
		CreateEmailInviteComponent,
		CreateTalentInviteComponent,
		ConfirmAlertComponent,
		FileSizeExceedAlertComponent,
		FileViewModalComponent,
		PreloaderComponent,
		AddMemberComponent,
		CreateListComponent,
		DeleteListComponent,
		ProfileVideoApplicantComponent,
		ProfileVideoComponent,
		SectionVideoComponent,
		ShowApplicantComponent,
		ShowJobApplicantComponent,
		ShowKeywordsComponent,
		ShowJobApplicantNotesComponent,
		ShowRecruitNotesComponent,
		ShowJobNotesComponent,
		ShowApplicantAnswersComponent,
		ViewListComponent,
		SearchKeywordComponent,
		SearchCityComponent,
		CreateOfferOtherComponent,
		OfferDetailsComponent,
		PreviewSendOfferComponent,
		PreviewCandidatesComponent,
		SearchJobTitleComponent,
		CreateInterviewProfileComponent,
		InterviewDetailsComponent,
		ShowSettingsZoomComponent,
		TimeSelectionComponent,
		ShowSettingsCalendarComponent,
		TimeDurationComponent,
		TimeSlotComponent,
		PreviewSendComponent,
		SelectCandidatesComponent,
		QuestionAutoCompleteComponent,
		ViewPaymentComponent,
		UpdatePaymentComponent,
		TrixEditorComponent,
		SearchSkillsComponent,
		AutoExpandTextDirective,
		CheckListDirective,
		AttachmentViewDirective,
		EllipsisPipe,
		FilterPipe,
		OrderByPipe,
		ZoomIdPipe,
		InputRestrictionDirective,
		ApplicantsViewDirective,
		UpgradeSubscriptionDirective
	],
	providers: [
		EllipsisPipe,
		FilterPipe,
		OrderByPipe
	]
})
export class SharedModule { }
