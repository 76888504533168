import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { OfferService } from '../../../core/services/offer.service';
import { SocketIOService } from '../../../core/services/socket-io.service';

@Component({
	selector: 'create-offer-other',
	templateUrl: './create-offer-other.component.html',
	styleUrls: ['./create-offer-other.component.scss']
})
export class CreateOfferOtherComponent implements OnInit {

	@Input() offerProfileData: any = {};
	@Input() context: string = 'PROFILE';

	// view status
	public viewStatus: number = 1;
	public offer_other: boolean = true;
	public offerData: any = {
		candidates: [],
		attachments: []
	};
	public selectedCandidates: any[] = [];
	public abbreviationTxt: string = '';

	private _candidateDetails: any = {};
	private _userData: any = {};

	constructor(private _modal: NgbActiveModal,
		private _offerService: OfferService,
		private _socketIOService: SocketIOService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {

		if (this.context === 'PROFILE') {
			this._candidateDetails.recruit_id = this.offerProfileData.recruit_user_id;

			this._userData.image_url = this.offerProfileData.profile_url;
			this._userData.position = this.offerProfileData.recruit_position;
			this._userData.name = this.offerProfileData.recruit_name;
		}

		if (this.context === 'MESSAGE') {
			this._candidateDetails.recruit_id = this.offerProfileData.Recruit_id;

			this._userData.image_url = this.offerProfileData.Recruit_user_profile_image;
			this._userData.position = this.offerProfileData.Recruit_user_position;
			this._userData.name = this.offerProfileData.Recruit_user_name;
		}

		if (this.context === 'PAST_INTERVIEW') {
			this._candidateDetails.recruit_id = this.offerProfileData.recruit_user_id;

			this._userData.image_url = this.offerProfileData.recruit_user_profile_image_url;
			this._userData.position = this.offerProfileData.offered_title;
			this._userData.name = this.offerProfileData.recruit_user_name;
			this._userData.offer = this.offerProfileData.offer_basic;
		}

		this.offerData.candidates.push(this._candidateDetails);
		this.selectedCandidates.push(this._userData);
	}

	// move forward button clicked
	moveForward(tabIndex: number, interviewData: any): void {
		//console.log(interviewData);
		this.viewStatus = Number(tabIndex);
		if (tabIndex === 2) {
			// merge objects
			Object.assign(this.offerData, interviewData);
		}
		//console.log(this.offerData);
		if (this.offerData.job_title == null || this.offerData.job_title == undefined) {
			if (this.context === 'PROFILE') {
				this.offerData.job_title = this.offerProfileData.recruit_position;
			}

			if (this.context === 'MESSAGE') {
				this.offerData.job_title = this.offerProfileData.Recruit_user_position;
			}
			if (this.context === 'PAST_INTERVIEW') {
				this.offerData.job_title = this.offerProfileData.offered_title;
			}
		}
	}

	// move backward button click
	moveBackward(tabIndex: number): void {
		this.viewStatus = Number(tabIndex);
	}

	// send new offer
	sendNewOffer(): void {
		//console.log(this.offerData);
		this._offerService.createNewOffer(this.offerData).subscribe((response: any) => {
			//console.log(response);
			if (response.Code === 200) {
				this._toastr.success('Offer sent');
				this._socketIOService.createNewOffer(response.Data);
			} else {
				this._toastr.error('Something went wrong!');
			}
			this.closeThisDialog();
		});
	}

	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
