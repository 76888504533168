<div class="search">
	<div class="search-container">
		<tag-input [(ngModel)]="selectedSkills" [addOnPaste]="false" [maxItems]="100" [ngClass]="{'error' : foundError, 'margin-bottom': addBottomMargin}"
			[inputClass]="'skillsCloud'" [modelAsStrings]="true" [secondaryPlaceholder]="placeholder" [placeholder]="placeholder" theme='bootstrap' (onAdd)="update()" (onRemove)="update()">
			<tag-input-dropdown [autocompleteObservable]="searchSkills" [minimumTextLength]="1" [zIndex]="9999"
				[focusFirstElement]="false" [appendToBody]="true" [dynamicUpdate]="true">
			</tag-input-dropdown>
		</tag-input>
	</div>
</div>
