import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

	constructor(private userService: UserService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(map((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse && req.url.includes('unnanu.com')) {
				event = event.clone({ body: { ...event.body, status: event.status } });
			}
			return event;
		}),
		catchError((err:HttpErrorResponse) => {
			if (err instanceof HttpErrorResponse && err.status === 401 && req.url.includes('unnanu.com')) {
				this.userService.clearUserData();
			}
			return throwError(err);
		}));
	}
}
