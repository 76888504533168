import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class OfferService {
	private serverUrl = environment.urls.messageUrl;

	constructor(private _http: HttpClient) { }

	// get Offer data
	getOffers() {
		return this._http.get(`${this.serverUrl}offers/hire/get`);
	}

	getOffersById(offerContentID: any, offerRecruitID: any) {
		return this._http.get(`${this.serverUrl}offers/recruit/getDetails/${offerContentID}/${offerRecruitID}`);
	}

	updateRespStatus(id: any, status: any, comment: any) {
		let data: any = {
			interview_content_id: id,
			reschedule_message: comment
		};

		return this._http.post(`${this.serverUrl}offers/hire/updRespStat/${status}/${id}`, data);
	}

	deleteAttachment(id: any) {
		return this._http.post(`${this.serverUrl}offers/hire/deleteOnboardAttch/${id}`, {});
	}

	// get offers accepted
	getOffersDataSet(offerType: any, timeStamp: any, qry: any, page?: number) {
		return this._http.get(`${this.serverUrl}offerv12/hire/get/${offerType}?fromDateTime=${timeStamp}&qry=${qry}&page=${page}`);
	}

	getOffersDataSetCount() {
		return this._http.get(`${this.serverUrl}offerv12/hire/get/count`);
	}

	// get offers templates
	getOfferTemplates() {
		return this._http.get(`${this.serverUrl}offers/hire/templates`);
	}

	// remove offer template
	removeOfferTemplates(templateID: any) {
		return this._http.post(`${this.serverUrl}offers/hire/template/delete/${templateID}`, {});
	}

	// get onboard packages
	getOnboardPackages() {
		return this._http.get(`${this.serverUrl}offers/hire/onboarding/packages`);
	}

	// save onboard package
	saveOnboardPackages(packageData: any) {
		return this._http.post(`${this.serverUrl}offers/hire/onboarding/package/save`, packageData);
	}

	// update onboard packages
	updateOnboardPackages(packageData: any) {
		return this._http.post(`${this.serverUrl}offers/hire/onboarding/package/edit`, packageData);
	}

	// remove onboard packages
	removeOnboardPackages(packageId: any) {
		return this._http.post(`${this.serverUrl}offers/hire/onboarding/package/delete/${packageId}`, {});
	}

	// save offer template
	saveOfferTemplate(tamplateData: any) {
		return this._http.post(`${this.serverUrl}offers/hire/template/save`, tamplateData);
	}

	// update offe template
	updateOfferTemplate(tamplateData: any) {
		return this._http.post(`${this.serverUrl}offers/hire/template/edit`, tamplateData);
	}

	// accept offer
	acceptOffer(offerContentId: any) {
		return this._http.post(`${this.serverUrl}offers/hire/accept/${offerContentId}`, {});
	}

	// withdraw offer
	withdrawOffer(offerId: any) {
		return this._http.post(`${this.serverUrl}offers/hire/withdraw/${offerId}`, {});
	}

	// onboard someone
	createOnboard(onboardDara: any) {
		return this._http.post(`${this.serverUrl}offers/hire/send/onboarding`, onboardDara);
	}

	// creare new offer
	createNewOffer(offerData: any) {
		return this._http.post(`${this.serverUrl}offers/hire/create`, offerData);
	}

	getSingleOfferDetails(offerContent: any) {
		let data: any = { offer_contents: offerContent };
		return this._http.post(`${this.serverUrl}offers/hire/getDetails`, data);
	}

	// upload atatchmentd
	uploadAttachment(file: any) {
		let fd = new FormData()
		fd.append('uploaded_file', file)

		if (file.type === 'application/pdf') {
			fd.append('file_type', 'PDF')
		} else if (file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
			fd.append('file_type', 'DOC')
		}
		fd.append('guid', '')
		fd.append('cell_id', '')

		return this._http.post(`${this.serverUrl}offers/attachment/upload`, fd);
	}
	// upload attachment
	uploadResume(files: any) {

		let fd = new FormData();

		Object.values(files).forEach((val: any, key: any) => {
			fd.append('file' + key, val);
		});

		return this._http.post(`${this.serverUrl}offers/resume/upload`, fd);
	}

	// upload talent resume for signup
	uploadTalentResume(file: any) {
		let fd = new FormData();
		fd.append('uploaded_file', file);

		if (file.type === 'application/pdf') {
			fd.append('file_type', 'PDF');
		} else if (
			file.type === 'application/msword' ||
			file.type ===
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
		) {
			fd.append('file_type', 'DOC');
		}

		return this._http.post(`${this.serverUrl}offers/talent/upload`, fd);
	}

	sendResponse(attachment: any) {
		return this._http.post(`${this.serverUrl}offers/recruit/send/response/attachment`, attachment);
	}

}
