import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable()
export class AutocompleteService {
	private serverUrl = environment.urls.serverUrl;
	private serverUrlV2 = environment.urls.serverUrlV2;

	constructor(private http: HttpClient) { }

	searchJobPosition(searchText: any) {
		return this.http.get(`${this.serverUrlV2}search/autocomplete/?searchKey=${searchText}`);
	}

	getLocations(searchText: any) {
		let service = new google.maps.places.AutocompleteService();
		let result = new Observable<any[]>(observer => {
			service.getPlacePredictions({ input: searchText, types: ['(cities)'] },
				(predictions, status) => {
					if (status == google.maps.places.PlacesServiceStatus.OK) {
						observer.next(predictions);
						observer.complete();
					} else {
						observer.next([]);
						observer.complete();
					}
				});
		});
		return result;
	}

	searchQuestions(searchText: any) {
		return this.http.get(`${this.serverUrl}jobboard/vacancy/question/autocomplete?keyword=${searchText}`);
	}

}
