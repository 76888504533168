<div class="modal-header">
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="pop-up-content section" *ngIf="!profileVideoV2">
		<div class="resumeVideoItemPanel playingResumeVideo">
		  <div class="resumeVideoPlayerWrapper">
			<div class="html5videoPlayback">
			  <video src='{{sectionData.profile_url === undefined || sectionData.profile_url === "" ? "../../../../../../assets/images/profile-icon-ellipse.png" : utilityService.getCdnUrl(sectionData.profile_url)}}' type="video/mp4" controls autoplay></video>
			</div>
		  </div>
		</div>
		<div class="section-header">
		  <div class="section-icon">
			<img src="{{!utilityService.checkImageURL(sectionData.profile_url) ? '../../../../../../assets/images/profile-icon-ellipse.png' : utilityService.getCdnUrl(sectionData.profile_url)}}" width="43" />
		  </div>
		  <div class="header-title-wrapper">
			<p class="time-period">{{sectionData.recruit_name}}</p>
			<p class="position">{{sectionData.recruit_position}}</p>
			<p class="location" id="ngdialog2-aria-describedby"> <i class="icon-navigation img-sprite-2"></i>{{sectionData.recruit_location}}</p>
		  </div>
		</div>
	</div>
	<div class="pop-up-content section" *ngIf="profileVideoV2">
		<div class="resumeVideoItemPanel playingResumeVideo">
		  <div class="resumeVideoPlayerWrapper">
			<div class="html5videoPlayback">
			  <video src='{{sectionData.Profile_video_url === undefined || sectionData.Profile_video_url === "" ? "../../../../../../assets/images/profile-icon-ellipse.png" : utilityService.getCdnUrl(sectionData.Profile_video_url)}}' type="video/mp4" controls autoplay></video>
			</div>
		  </div>
		</div>
		<div class="section-header">
		  <div class="section-icon">
			<img src="{{!utilityService.checkImageURL(sectionData.Profile_video_url) ? '../../../../../../assets/images/profile-icon-ellipse.png' : utilityService.getCdnUrl(sectionData.Profile_video_url)}}" width="43" />
		  </div>
		  <div class="header-title-wrapper">
			<p class="time-period" [ngClass]="{'blur' : sectionData.Is_data_abstract}">{{sectionData.First_name}} {{sectionData.Last_name}}</p>
			<p class="position">{{sectionData.Desired_job}}</p>
			<p class="location" id="ngdialog2-aria-describedby"> <i class="icon-navigation img-sprite-2"></i>{{sectionData.Location}}</p>
		  </div>
		</div>
	</div>
</div>
