import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UtilityService } from '../../../core/services/utility.service';
import { NgZone } from '@angular/core';

@Component({
	selector: 'add-member',
	templateUrl: './add-member.component.html',
	styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

	@Input() index: any;
	@Input() object: any;
	@Input() members: any;

	@ViewChild('addMemberForm') addMemberForm: NgForm | undefined;

	constructor(public utilityService: UtilityService, private ngZone: NgZone) {}

	ngOnInit(): void {
		this.members[this.index].first_Name = '';
		this.members[this.index].last_Name = '';
		this.members[this.index].email = '';
	}

	removeField(memArray: any, index: any): void {
		if (index !== 0) {
			memArray.splice(index, 1);
		}
	}

 isEmailDomainAllowed(email: string): boolean {
  let disallowedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
  let domain = email.split('@')[1];
  if (disallowedDomains.includes(domain)) {
    return false;
  }
  return true;
}
checkForErrors(index: number): boolean {
  let email = this.members[index].email;
  let isEmailValid = this.utilityService.emailFormat.test(email);
  let isDomainAllowed = this.isEmailDomainAllowed(email);
  this.object.foundError = !isEmailValid || !isDomainAllowed;
	if (this.object.foundError) {this.ngZone.runOutsideAngular(() => {
		this.addMemberForm?.control.setErrors({ 'incorrect': true });
	});} // Madhu Check 1/2/24/MB
  return this.object.foundError;
}
}
