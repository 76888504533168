<div class="modal-header">
	<h4 class="modal-title" id="modal-title">
		{{userTitle}} - Applied Job Position(s)
	</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">Close</span>
	</button>
</div>
<div class="modal-body">
	<div class="content" *ngIf="!showAllApplicantsPreloader">
		<div class="content-item" *ngFor="let applicant of allApplicantsData">
			<div class="content-item-left pull-left">
				<p class="title">
					<a (click)="JobPopupPage(applicant.vacancy_id, applicant.job_title)">{{applicant.job_title}}
					</a>
				</p>
				<p class="type">
					<span *ngIf="applicant.is_contract">Contract</span>
					<span *ngIf="applicant.is_contract && (applicant.is_full_time || applicant.is_volunteer ||applicant.is_part_time)"> / </span>
					<span *ngIf="applicant.is_full_time">Employee</span>
					<span *ngIf="applicant.is_full_time && (applicant.is_part_time || applicant.is_volunteer)"> /
					</span>
					<span *ngIf="applicant.is_part_time">Internship</span>
					<span *ngIf="applicant.is_part_time && applicant.is_volunteer"> / </span>
					<span *ngIf="applicant.is_volunteer">Volunteer</span>
					| <span>{{applicant.job_ref_id}}</span>
				</p>
			</div>
			<div class=" content-item-right pull-right">
				<span>{{applicant.location.city}}</span><span
					*ngIf="applicant.location.state">,{{applicant.location.state}}</span>
			</div>
		</div>
	</div>
	<div class="content pull-left" *ngIf="showAllApplicantsPreloader">
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
			<div class="animated-background subtitle"></div>
		</div>
	</div>
</div>
