<div class="modal-header">
	<h4 class="modal-title" id="modal-title">
		Applicants for
		<a (click)="JobPopupPage(url)">{{jobdata.job_title | ellipsis:44 }} <span
				*ngIf="jobdata.job_ref_id">({{jobdata.job_ref_id}})</span></a>
	</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">Close</span>
	</button>
</div>
<div class="modal-body">
	<div [hidden]="showAllApplicantsPreloader" class="content pull-left" style="min-height: 300px;"
		[ngClass]="{'no-dropdowns' : noDropDowns}" (click)="noDropDowns = true; falseOtherPopups(); noDropDowns = false"
		infiniteScroll [scrollWindow]="false" (scrolled)="loadMoreApplicants()">

		<div class="applicants-type-title pull-left" style="border-top: 0; padding-left: 282px;">
			Recommended Job Applicants by Unnanu <a class="icon-thumbs-up"></a>
		</div>

		<div class="empty-case-no-appliations pull-left" [hidden]="recommendedApplicantsData.length">
			No Recommended Applicants.
		</div>

		<div class="pull-left" style="width: 100%;" [hidden]="!recommendedApplicantsData.length">
			<div class="content-item" [ngClass]="{unread : applicant.is_read === false}"
				*ngFor="let applicant of recommendedApplicantsData; let $index=index"
				(click)="addToList[$index] = false;">

				<div class="pull-left">

					<div class="profile-picture" *ngIf="applicant.profile_image_url"
						[ngStyle]="{'cursor': applicant.profile_video_url ? 'pointer' : null,
        'background-image': 'url('+utilityService.getCdnUrl(applicant.profile_image_url)+')' }"
						(click)="applicant.profile_video_url ? profileVideo(applicant) : null; applicantMarkRead(applicant, $index);"
						[ngClass]='{"has-video" : applicant.profile_video_url != ""}'>
					</div>

					<div *ngIf="!applicant.profile_image_url" class="profile-picture"
						(click)="applicantMarkRead(applicant, $index);" style="
						background-image: url('../../../../assets/images/default-profile-picture.jpg');">
					</div>

				</div>

				<div class="pull-left">

					<div (click)="goToUserProfile(applicant.recruit_id);applicantMarkRead(applicant, $index);"
						class="pull-left cursor-pointer">{{applicant.recruit_first_name + ' '
						+ applicant.recruit_last_name
						| ellipsis:25 }}
					</div>
					<br />
					<span class="date"
						(click)="applicantMarkRead(applicant, $index);">{{utilityService.convertTime(applicant.applied_at)
						| date
						: 'EEE, MMM d, y h:mm:ss a'}}
					</span>
				</div>

				<div class="pull-right options">
					<div class="options-ui-wrap pull-right" *ngIf="applicant.is_question_avail">
						<button class="user-view-answers-button btn"
							(click)="viewApplicantResponse(applicant.recruit_id, applicant.recruit_first_name + ' ' + applicant.recruit_last_name, applicant.applied_at); applicantMarkRead(applicant, $index);">
							View Answers
						</button>
					</div>
					<div class="options-ui-wrap pull-right" *ngIf="!applicant.is_question_avail">
						<button class="user-view-answers-button btn" style="cursor: not-allowed; background-color: #ccc;">No - Q&A's</button>
					</div>
					<div class="options-ui-wrap dropdown pull-right" ngbDropdown placement="bottom-right" container="body" *ngIf="!applicant.isNotifiable">
						<button class="user-add-to-list-button-large btn dropdown-toggle" style="cursor: not-allowed; background-color: #ccc;">
							<i class="icon-add-to-list-button"></i>Cannot Add
						</button>
					</div>
					<div class="options-ui-wrap dropdown pull-right" ngbDropdown placement="bottom-right" container="body" *ngIf="applicant.isNotifiable">

						<button type="button" ngbDropdownToggle id="btn-id-{{$index}}" class="user-add-to-list-button-large btn dropdown-toggle" [upgradeSubscription]="userType==='4' || userType==='5'"
							(click)="falseOtherPopupsExceptThis($index); addToList[$index] = !addToList[$index]; addToList[$index] === true ? findTalentLists($index, applicant.recruit_id,$event) : '';applicantMarkRead(applicant, $index);">
							<i class="icon-add-to-list-button"></i>Add to List
						</button>

						<div ngbDropdownMenu class="dropdown-menu add-to-list-dropdown-ui" [attr.aria-labelledby]="'btn-id-'+$index">

							<div class="dropdown-ui-search-list">

								<form class="search-lists-form-small"
									*ngIf="!preLoader[$index] && SavedList[$index]?.length > 0">

									<div class="search-keyword">

										<div class="search-keyword-container">
											<i class="icon-search-magnfying img-sprite-1"></i>
											<input class="form-control" placeholder="Search for a List"
												name="listName{{$index}}" [(ngModel)]="listName[$index]" />
										</div>

									</div>

								</form>

							</div>


							<div class="available-lists-empty-state" *ngIf="preLoader[$index] && SavedList[$index]?.length === 0">
								You haven’t created any Lists.
							</div>

							<ul class="dropdown-ui-lists" *ngIf="preLoader[$index]" style="height: 145px;" [ngClass]="{'loading' : preLoader[$index]}">
								<div class="preloader-container">
									<div class="pre-loader-wrapper">
										<i class="preloader-icon"></i>
									</div>
								</div>
							</ul>

							<ul class="dropdown-ui-lists" style="height: 145px;" *ngIf="!preLoader[$index] && SavedList[$index]?.length > 0">

								<li *ngFor="let list of SavedList[$index] | filter:'List_title':listName[$index] | orderBy:['List_title']">

									<div class="checkboxWrapper">
										<input type="checkbox" id="dd-sp-title-{{$index}}-{{applicant.recruit_id}}"
											class="form-check" [(ngModel)]="list.Already_in"
											(ngModelChange)="checkboxClicked(list.List_id, applicant.recruit_id, list.Already_in, list.List_title, true);"/>

										<label for="dd-sp-title-{{$index}}-{{applicant.recruit_id}}"></label>
										<span>{{list.List_title | ellipsis: 26}}</span>
									</div>

								</li>

							</ul>


							<div class="dropdown-create-a-new-list">


								<button class="dropdown-create-new-list-button btn" [hidden]="!newList[$index]"
									(click)="newList[$index] = false" style="padding: 0px 0px;">
									<i class="icon-dropdown-create-icon-blue img-sprite-1"></i>
									Create new List
								</button>


								<form #listData="ngForm" class="dropdown-create-new-list-form"
									[hidden]="newList[$index]" name="listData" class="form" style="padding: 3px 12px;">

									<div class="input-name-wrapper">
										<input class="form-control" type="text" name="listName" [maxLength]="50"
											[(ngModel)]="list.List_name" [required]="true" />
									</div>

									<div class="create-button-wrapper text-right">
										<button class="active-button-small-green btn" type="submit"
											[disabled]="listData.invalid"
											(click)="saveList(list, applicant.recruit_id, $index); newList[$index] = true">
											Create
										</button>
									</div>

								</form>

							</div>

						</div>

					</div>

					<div class="options-ui-wrap-score-to pull-right">
						<div class="like-to pull-right" style="margin-left: 6px;">
							<div class="rescore-ico" title="Reset Score"
								(click)="resetScore(applicant, $index); applicantMarkRead(applicant, $index)">
							</div>
						</div>
						<div class="score-to pull-right" style="margin-left: 6px;">

							<div class="score-ico" title="Unnanu Score">
								{{getScore(applicant.recruit_score)}}
							</div>
						</div>
						<div class="dlike-to pull-right" style="margin-left: 6px;">
							<div class="dlike-ico" title="Reject Candidate"
								(click)="rejectCandidate(applicant, $index); applicantMarkRead(applicant, $index)">
							</div>
						</div>
						<div class="like-to pull-right" style="margin-left: 6px;">
							<div class="like-ico" title="Candidated Liked">

							</div>
						</div>

						<div class="scoreview-to pull-right" style="margin-left: 6px;" *ngIf="applicant.resume_url">
							<div class="scoreview-ico" title="View Score Tags"
								(click)="keywordScore(applicant); applicantMarkRead(applicant, $index);">
							</div>
						</div>
						<div class="scoreview-to pull-right" style="margin-left: 6px;" *ngIf="!applicant.resume_url">
							<div class="scoreview-ico" style="cursor: not-allowed;  background-color: #ccc;" title="No Score Tags">
							</div>
						</div>
						<div class="msg-to pull-right" style="margin-left: 6px;" *ngIf="applicant.isNotifiable" [upgradeSubscription]="userType==='5'">
							<div class="msg-ico" title="Send Message" 
								(click)="startConversation(applicant); applicantMarkRead(applicant, $index);">
							</div>
						</div>
						<div class="msg-to pull-right" style="margin-left: 6px;" *ngIf="!applicant.isNotifiable">
					  	<div class="msg-ico" style="cursor: not-allowed;" title="Not available for message"></div>
				    </div>
						<div class="view-resume pull-right" style="margin-left: 6px;" *ngIf="applicant.resume_url">
							<div class="resume-ico" title="View Resume" attachmentView
								[value]="utilityService.getCdnUrl(applicant.resume_url)"
								(click)="applicantMarkRead(applicant, $index);">
							</div>
						</div>
						<div class="view-resume pull-right" style="margin-left: 6px;" *ngIf="!applicant.resume_url">
							<div class="resume-ico" style="cursor: not-allowed;" title="No Resume"></div>
						</div>
						<div class="scoreview-to pull-right">
							<div class="notesview-ico" title="View Notes"
								(click)="GetApplicantNotes(applicant); applicantMarkRead(applicant, $index);">
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
		<div class="applicants-type-title pull-left" style="padding-left: 370px;">
			New Job Applicants
		</div>
		<div class="empty-case-no-appliations pull-left" [hidden]="allApplicantsData.length">
			No New Applicants.
		</div>

		<div class="pull-left" style="width: 100%;" [hidden]="!allApplicantsData.length">
			<div class="content-item" [ngClass]="{unread : applicant.is_read === false}"
				*ngFor="let applicant of allApplicantsData | orderBy:['-recruit_score'];let $index=index"
				(click)="addToList[$index] = false;">

				<div class="pull-left">
					<div class="profile-picture" *ngIf="applicant.profile_image_url"
           [ngStyle]="{'cursor': applicant.profile_video_url ? 'pointer' : null,
        'background-image': 'url('+utilityService.getCdnUrl(applicant.profile_image_url)+')' }"
           (click)="applicant.profile_video_url ? profileVideo(applicant) : null; applicantMarkRead(applicant, $index, 1);"
           [ngClass]="{'has-video' : applicant.profile_video_url}">
          </div>
					<div *ngIf="!applicant.profile_image_url" class="profile-picture"
						(click)="applicantMarkRead(applicant, $index, 1);" style="
					  background-image: url('../../../../../../assets/images/default-profile-picture.jpg');
					"></div>
				</div>
				<div class="pull-left">

					<div (click)="goToUserProfile(applicant.recruit_id);applicantMarkRead(applicant, $index, 1);"
						class="pull-left cursor-pointer">{{applicant.recruit_first_name + ' '
						+ applicant.recruit_last_name
						| ellipsis:25 }}</div>
					<br />
					<span class="date"
						(click)="applicantMarkRead(applicant, $index, 1);">{{utilityService.convertTime(applicant.applied_at) | date: 'EEE, MMM d, y h:mm:ss a'}}</span>
				</div>

				<div class="pull-right options">
					<div class="options-ui-wrap pull-right" *ngIf="applicant.is_question_avail">
						<button class="user-view-answers-button btn"
							(click)="viewApplicantResponse(applicant.recruit_id, applicant.recruit_first_name + ' ' + applicant.recruit_last_name, applicant.applied_at); applicantMarkRead(applicant, $index, 1);">
							View Answers
						</button>
					</div>
					<div class="options-ui-wrap pull-right" *ngIf="!applicant.is_question_avail">
						<button class="user-view-answers-button btn" style="cursor: not-allowed; background-color: #ccc;">No - Q&A's</button>
					</div>
					<div class="options-ui-wrap dropdown pull-right" ngbDropdown placement="bottom-right" container="body" *ngIf="!applicant.isNotifiable">
						<button class="user-add-to-list-button-large btn dropdown-toggle" style="cursor: not-allowed; background-color: #ccc;">
							<i class="icon-add-to-list-button"></i>Cannot Add
						</button>
					</div>
					<div class="options-ui-wrap dropdown pull-right" ngbDropdown placement="bottom-right" container="body" *ngIf="applicant.isNotifiable">
						<button type="button" ngbDropdownToggle id="btn-id-{{$index}}" class="user-add-to-list-button-large btn dropdown-toggle" [upgradeSubscription]="userType==='4' || userType==='5'"
							(click)="falseOtherPopupsExceptThis($index); addToList[$index] = !addToList[$index]; addToList[$index] === true ? findTalentLists($index, applicant.recruit_id,$event) : '';applicantMarkRead(applicant, $index, 1);">
							<i class="icon-add-to-list-button"></i>Add to List
						</button>
						<div ngbDropdownMenu class="dropdown-menu add-to-list-dropdown-ui" [attr.aria-labelledby]="'btn-id-'+$index">
							<div class="dropdown-ui-search-list">
								<form class="search-lists-form-small"
									*ngIf="!preLoader[$index] && SavedList[$index]?.length > 0">
									<div class="search-keyword">
										<div class="search-keyword-container">
											<i class="icon-search-magnfying img-sprite-1"></i>
											<input class="form-control" placeholder="Search for a List"
												[(ngModel)]="listName[$index]" name="listName{{$index}}" />
										</div>
									</div>
								</form>
							</div>

							<div class="available-lists-empty-state"
								*ngIf="preLoader[$index] && SavedList[$index]?.length === 0">
								You haven’t created any Lists.
							</div>

							<ul class="dropdown-ui-lists" *ngIf="preLoader[$index]" style="height: 145px;" [ngClass]="{'loading' : preLoader[$index]}">
								<div class="preloader-container">
									<div class="pre-loader-wrapper">
										<i class="preloader-icon"></i>
									</div>
								</div>
							</ul>

							<ul class="dropdown-ui-lists" style="height: 145px;" *ngIf="!preLoader[$index] && SavedList[$index]?.length > 0">
								<li
									*ngFor="let list of SavedList[$index] | filter:'List_title':listName[$index] | orderBy:['List_title']">
									<div class="checkboxWrapper">
										<input type="checkbox" id="dd-sp-title-{{$index}}-{{applicant.recruit_id}}"
											class="form-check" [(ngModel)]="list.Already_in"
											(ngModelChange)="checkboxClicked(list.List_id, applicant.recruit_id, list.Already_in, list.List_title, true);"/>

										<label for="dd-sp-title-{{$index}}-{{applicant.recruit_id}}"></label>
										<span>{{list.List_title | ellipsis:26}}</span>
									</div>
								</li>
							</ul>

							<div class="dropdown-create-a-new-list">

								<button class="dropdown-create-new-list-button btn" [hidden]="!newList[$index]"
									(click)="newList[$index] = false" style="padding: 0px 0px;">
									<i class="icon-dropdown-create-icon-blue img-sprite-1"></i>
									Create new List
								</button>

								<form #listData="ngForm" class="dropdown-create-new-list-form"
									[hidden]="newList[$index]" name="listData" class="form" style="padding: 3px 12px;">
									<div class="input-name-wrapper">
										<input class="form-control" type="text" name="listName" limit-to="50"
											[(ngModel)]="list.List_name" [required]="true" />
									</div>
									<div class="create-button-wrapper text-right">
										<button class="active-button-small-green btn" type="submit"
											[disabled]="listData.invalid"
											(click)="saveList(list, applicant.recruit_id, $index); newList[$index] = true">
											Create
										</button>
									</div>
								</form>

							</div>
						</div>
					</div>

					<div class="options-ui-wrap-score-to pull-right">
						<div class="like-to pull-right" style="margin-left: 6px;">
							<div class="rescore-ico" title="Reset Score"
								(click)="resetScore(applicant, $index, 1); applicantMarkRead(applicant, $index, 1)">
							</div>
						</div>
						<div class="score-to pull-right" style="margin-left: 6px;">
							<div class="score-ico" title="Unnanu Score">
								{{getScore(applicant.recruit_score)}}
							</div>
						</div>
						<div class="dlike-to pull-right" style="margin-left: 6px;">
							<div class="dlike-ico" title="Reject Candidate"
								(click)="rejectCandidate(applicant, $index); applicantMarkRead(applicant, $index, 1)">
							</div>
						</div>
						<div class="like-to pull-right" style="margin-left: 6px;">
							<div class="like-ico" title="Like Candidate"
								(click)="CandidateReScore(applicant, $index, 1); applicantMarkRead(applicant, $index, 1)">
							</div>
						</div>
						<div class="scoreview-to pull-right" style="margin-left: 6px;" *ngIf="applicant.resume_url">
							<div class="scoreview-ico" title="View Score Tags"
								(click)="keywordScore(applicant); applicantMarkRead(applicant, $index, 1);">
							</div>
						</div>
						<div class="scoreview-to pull-right" style="margin-left: 6px;" *ngIf="!applicant.resume_url">
							<div class="scoreview-ico" style="cursor: not-allowed; background-color: #ccc;" title="No Score Tags">
							</div>
						</div>
						<div class="msg-to pull-right" style="margin-left: 6px;" *ngIf="applicant.isNotifiable" [upgradeSubscription]="userType==='5'">
							<div class="msg-ico" title="Send Message"
								(click)="startConversation(applicant); applicantMarkRead(applicant, $index, 1);">
							</div>
						</div>
						<div class="msg-to pull-right" style="margin-left: 6px;" *ngIf="!applicant.isNotifiable">
					  	<div class="msg-ico" style="cursor: not-allowed;" title="Not available for message"></div>
				    </div>
						<div class="view-resume pull-right" style="margin-left: 6px;" *ngIf="applicant.resume_url">
							<div class="resume-ico" title="View Resume" attachmentView
								[value]="utilityService.getCdnUrl(applicant.resume_url)"
								(click)="applicantMarkRead(applicant, $index, 1);"></div>
						</div>
						<div class="view-resume pull-right" style="margin-left: 6px;" *ngIf="!applicant.resume_url">
							<div class="resume-ico" style="cursor: not-allowed;" title="No Resume"></div>
						</div>
						<div class="scoreview-to pull-right">
							<div class="notesview-ico" title="View Notes"
								(click)="GetApplicantNotes(applicant); applicantMarkRead(applicant, $index, 1);">
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<div class="applicants-type-title pull-left" style="padding-left: 350px;">
			Rejected Job Applicants <a class="icon-thumbs-down" style="color:lightsalmon;"></a>
		</div>
		<div class="empty-case-no-appliations pull-left" [hidden]="rejectedApplicantsData.length">
			No Rejected Applicants.
		</div>

		<div class="pull-left" style="width: 100%;" [hidden]="!rejectedApplicantsData.length">
			<div class="content-item" [ngClass]="{unread : applicant.is_read === false}"
				*ngFor="let applicant of rejectedApplicantsData | orderBy:['-applied_at'] let $index=index"
				(click)="addToList[$index] = false;">

				<div class="pull-left">
					<div *ngIf="applicant.profile_image_url" class="profile-picture"
					[ngStyle]="{'cursor': applicant.profile_video_url ? 'pointer' : null,
					'background-image': 'url('+utilityService.getCdnUrl(applicant.profile_image_url)+')' }"
						[ngClass]='{"has-video" : applicant.profile_video_url != ""}'
						(click)="applicant.profile_video_url ? profileVideo(applicant) : null; applicantMarkRead(applicant, $index, 1);">
					</div>
					<div *ngIf="!applicant.profile_image_url" class="profile-picture"
						(click)="applicantMarkRead(applicant, $index, 1)"
						style="background-image: url('../../../../../../assets/images/default-profile-picture.jpg');">
					</div>
				</div>
				<div class="pull-left">
					<div (click)="goToUserProfile(applicant.recruit_id);applicantMarkRead(applicant, $index, 1);"
						class="pull-left cursor-pointer">{{applicant.recruit_first_name + ' ' + applicant.recruit_last_name | ellipsis:25 }}</div>
					<br />
					<span class="date"
						(click)="applicantMarkRead(applicant, $index, 1)">{{utilityService.convertTime(applicant.applied_at)
						|
						date : 'EEE, MMM d, y h:mm:ss a'}}</span>
				</div>

				<div class="pull-right options">
					<div class="options-ui-wrap pull-right" *ngIf="applicant.is_question_avail">
						<button class="user-view-answers-button btn"
							(click)="viewApplicantResponse(applicant.recruit_id, applicant.recruit_first_name + ' ' + applicant.recruit_last_name, applicant.applied_at); applicantMarkRead(applicant, $index, 1);">
							View Answers
						</button>
					</div>
					<div class="options-ui-wrap pull-right" *ngIf="!applicant.is_question_avail">
						<button class="user-view-answers-button btn" style="cursor: not-allowed; background-color: #ccc;">No - Q&A's</button>
					</div>
					<div class="options-ui-wrap dropdown pull-right" ngbDropdown placement="bottom-right" container="body" *ngIf="!applicant.isNotifiable">
						<button class="user-add-to-list-button-large btn dropdown-toggle" style="cursor: not-allowed; background-color: #ccc;">
							<i class="icon-add-to-list-button"></i>Cannot Add
						</button>
					</div>
					<div class="options-ui-wrap dropdown pull-right" ngbDropdown placement="bottom-right" container="body" *ngIf="applicant.isNotifiable">
						<button type="button" ngbDropdownToggle id="btn-id-{{$index}}" class="user-add-to-list-button-large btn dropdown-toggle" [upgradeSubscription]="userType==='4' || userType==='5'"
							(click)="falseOtherPopupsExceptThis($index); addToList[$index] = !addToList[$index]; addToList[$index] === true ? findTalentLists($index, applicant.recruit_id,$event) : '';applicantMarkRead(applicant, $index, 1);">
							<i class="icon-add-to-list-button"></i>Add to List
						</button>
						<div ngbDropdownMenu class="dropdown-menu add-to-list-dropdown-ui" [attr.aria-labelledby]="'btn-id-'+$index">
							<div class="dropdown-ui-search-list">
								<form class="search-lists-form-small"
									*ngIf="!preLoader[$index] && SavedList[$index]?.length > 0">
									<div class="search-keyword">
										<div class="search-keyword-container">
											<i class="icon-search-magnfying img-sprite-1"></i>
											<input class="form-control" placeholder="Search for a List"
												[(ngModel)]="listName[$index]" name="listName{{$index}}" />
										</div>
									</div>
								</form>
							</div>

							<div class="available-lists-empty-state"
								*ngIf="preLoader[$index] && SavedList[$index]?.length === 0">
								You haven’t created any Lists.
							</div>

							<ul class="dropdown-ui-lists" *ngIf="preLoader[$index]" style="height: 145px;" [ngClass]="{'loading' : preLoader[$index]}">
								<div class="preloader-container">
									<div class="pre-loader-wrapper">
										<i class="preloader-icon"></i>
									</div>
								</div>
							</ul>

							<ul class="dropdown-ui-lists" style="height: 145px;" *ngIf="!preLoader[$index] && SavedList[$index]?.length > 0">
								<li
									*ngFor="let list of SavedList[$index] | filter:'List_title':listName[$index] | orderBy:['List_title']">
									<div class="checkboxWrapper">
										<input type="checkbox" id="dd-sp-title-{{$index}}-{{applicant.recruit_id}}"
											class="form-check" [(ngModel)]="list.Already_in"
											(ngModelChange)="checkboxClicked(list.List_id, applicant.recruit_id, list.Already_in, list.List_title, true);"/>
										<label for="dd-sp-title-{{$index}}-{{applicant.recruit_id}}"></label>
										<span>{{list.List_title | ellipsis:26}}</span>
									</div>
								</li>
							</ul>
							<div class="dropdown-create-a-new-list">
								<button class="dropdown-create-new-list-button btn" [hidden]="!newList[$index]"
									(click)="newList[$index] = false" style="padding: 0px 0px;">
									<i class="icon-dropdown-create-icon-blue img-sprite-1"></i>
									Create new list
								</button>
								<form #listData="ngForm" class="dropdown-create-new-list-form"
									[hidden]="newList[$index]" name="listData" style="padding: 3px 12px;">
									<div class="input-name-wrapper">
										<input class="form-control" type="text" name="listName" limit-to="50"
											[(ngModel)]="list.List_name" [required]="true" />
									</div>
									<div class="create-button-wrapper text-right">
										<button class="active-button-small-green btn" type="submit"
											[disabled]="listData.invalid"
											(click)="saveList(list, applicant.recruit_id, $index); newList[$index] = true">
											Create
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="options-ui-wrap-score-to pull-right">
						<div class="like-to pull-right" style="margin-left: 6px;">
							<div class="rescore-ico" title="Reset Score Disabled"
								(click)="applicantMarkRead(applicant, $index, 1)">
							</div>
						</div>
						<div class="score-to pull-right" style="margin-left: 6px;">
							<div class="score-ico" title="Unnanu Score">
								{{getScore(applicant.recruit_score)}}
							</div>
						</div>
						<div class="dlike-to pull-right" style="margin-left: 6px; color: lightsalmon;">
							<div class="dlike-ico" title="Candidate Rejected">
							</div>
						</div>
						<div class="like-to pull-right" style="margin-left: 6px;">
							<div class="like-ico" title="Like Candidate"
								(click)="CandidateReScore(applicant, $index, 3); applicantMarkRead(applicant, $index, 1)">
							</div>
						</div>
						<div class="scoreview-to pull-right" style="margin-left: 6px;" *ngIf="applicant.resume_url">
							<div class="scoreview-ico" title="View Score Tags"
								(click)="keywordScore(applicant); applicantMarkRead(applicant, $index, 1);">
							</div>
						</div>
						<div class="scoreview-to pull-right" style="margin-left: 6px;" *ngIf="!applicant.resume_url">
							<div class="scoreview-ico" style="cursor: not-allowed;  background-color: #ccc;" title="No Score Tags">
							</div>
						</div>
						<div class="msg-to pull-right" style="margin-left: 6px;" *ngIf="applicant.isNotifiable" [upgradeSubscription]="userType==='5'">
							<div class="msg-ico" title="Send Message"
								(click)="startConversation(applicant);applicantMarkRead(applicant, $index, 1)">
							</div>
						</div>
						<div class="msg-to pull-right" style="margin-left: 6px;" *ngIf="!applicant.isNotifiable">
					  	<div class="msg-ico" style="cursor: not-allowed;" title="Not available for message"></div>
				    </div>
						<div class="view-resume pull-right" style="margin-left: 6px;" *ngIf="applicant.resume_url">
							<div class="resume-ico" title="View Resume" attachmentView
								[value]="utilityService.getCdnUrl(applicant.resume_url)"
								(click)="applicantMarkRead(applicant, $index, 1)"></div>
						</div>
						<div class="view-resume pull-right" style="margin-left: 6px;" *ngIf="!applicant.resume_url">
							<div class="resume-ico" style="cursor: not-allowed;" title="No Resume"></div>
						</div>
						<div class="scoreview-to pull-right">
							<div class="notesview-ico" title="View Notes"
								(click)="GetApplicantNotes(applicant); applicantMarkRead(applicant, $index, 1);">
							</div>
						</div>
					</div>
				</div>

			</div>
		</div>

		<div class="load-more-applicants-preloader" *ngIf="showLoadMoreApplicantsPreloader">
			<div class="preloader-container">
				<div class="pre-loader-wrapper">
					<div class="preloader-icon"></div>
				</div>
			</div>
		</div>

	</div>

	<div class="content pull-left" [hidden]="!showAllApplicantsPreloader">
		<div class="content-item">
			<div class="animated-background header"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
		</div>
		<div class="content-item">
			<div class="animated-background header"></div>
		</div>
	</div>

</div>
