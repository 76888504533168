import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { UtilityService } from '../../../core/services/utility.service';
import { InterviewService } from '../../../core/services/interview.service';
import { SocketIOService } from '../../../core/services/socket-io.service';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'create-interview-profile',
	templateUrl: './create-interview-profile.component.html',
	styleUrls: ['./create-interview-profile.component.scss']
})
export class CreateInterviewProfileComponent implements OnInit {

	@Input() interviewProfileData: any = {};
	@Input() context: string = 'PROFILE';

	public interviewDetailsProfile: boolean = true;
	public timeSelectionProfile: boolean = false;
	public previewSendProfile: boolean = false;
	// create user object
	public selectedCandidates: any[] = [];
	public interviewdDetails: any = {};
	public abbreviationTxt: string = '';
	public timedSelection: any = {};
	public selectedTimeZoneCombo: string = '';

	private _selectedCandidates: any = {};

	constructor(private _modal: NgbActiveModal,
		private _interviewService: InterviewService,
		private _socketIOService: SocketIOService,
		private _utilityService: UtilityService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {
		if (this.context === 'PROFILE') {
			this._selectedCandidates.id = this.interviewProfileData.recruit_user_id;
			if (this.interviewProfileData.has_video) {
				this._selectedCandidates.image_url = this.interviewProfileData.thumbnail_url;
			} else {
				this._selectedCandidates.image_url = this.interviewProfileData.profile_url;
			}
			this._selectedCandidates.location = this.interviewProfileData.recruit_location;
			this._selectedCandidates.name = this.interviewProfileData.recruit_name;
			this._selectedCandidates.position = this.interviewProfileData.recruit_position;
		}

		if (this.context === 'MESSAGE') {
			this._selectedCandidates.id = this.interviewProfileData.Recruit_id;
			this._selectedCandidates.image_url = this.interviewProfileData.Recruit_user_profile_image;
			this._selectedCandidates.name = this.interviewProfileData.Recruit_user_name;
			this._selectedCandidates.position = this.interviewProfileData.Recruit_user_position;
		}

		this.selectedCandidates.push(this._selectedCandidates);
	}

	// move forward button clicked
	moveForward(tabIndex: number, interviewData: any): void {
		if (tabIndex === 1) {
			this.interviewDetailsProfile = false;
			this.timeSelectionProfile = true;
			this.previewSendProfile = false;
			this.interviewdDetails = interviewData;
			if (this.interviewdDetails.title == null || this.interviewdDetails.title == undefined) {
				if (this.context === 'PROFILE') {
					this.interviewdDetails.title = this.interviewProfileData.recruit_position;
				}

				if (this.context === 'MESSAGE') {
					this.interviewdDetails.title = this.interviewProfileData.Recruit_user_position;
				}
			}
		} else if (tabIndex === 2) {
			this.interviewDetailsProfile = false;
			this.timeSelectionProfile = false;
			this.previewSendProfile = true;
			interviewData.abbreviationTxt = this.abbreviationTxt;
			this.timedSelection = interviewData;
		}
	}

	// move backward button clicked
	moveBackward(tabIndex: number): void {
		if (tabIndex === 0) {
			this.interviewDetailsProfile = false;
			this.timeSelectionProfile = false;
			this.previewSendProfile = false;
		} else if (tabIndex === 1) {
			this.interviewDetailsProfile = true;
			this.timeSelectionProfile = false;
			this.previewSendProfile = false;
		} else if (tabIndex === 2) {
			this.interviewDetailsProfile = false;
			this.timeSelectionProfile = true;
			this.previewSendProfile = false;
		}
	}

	// get selected time zone
	selectedTimeZone(timeZone: any): boolean {
		if (!timeZone) {
			return false;
		}
		//console.log(timeZone);
		if (typeof timeZone !== 'object') {
			timeZone = JSON.parse(timeZone);
		}

		this.abbreviationTxt = timeZone.Abbreviation;
		this.selectedTimeZoneCombo = this._utilityService.getTimeZoneString(Number(timeZone.timezoneVal));
		//console.log(this.selectedTimeZoneCombo);
		return true;
	}

	// create interview
	saveInterviewData(): void {
		// create object
		let parts: any, partsMidNight: any;
		let newInterview: any = {};
		newInterview.title = this.interviewdDetails.title;

		let todaytext: string = moment(new Date()).format('YYYY-MM-DD @ h:mm:ss a');
		if (this.interviewdDetails.message !== null && this.interviewdDetails.message !== undefined) {
			if (this.context === 'PROFILE') {
				newInterview.message = this.interviewdDetails.message
											.replace(/\$TODAY\$/g, todaytext)
											.replace(/\$FULL_NAME\$/g, this.interviewProfileData.recruit_name)
											.replace(/\$FIRST_NAME\$/g, this.interviewProfileData.recruit_name.split(' ')[0])
											.replace(/\$LAST_NAME\$/g, this.interviewProfileData.recruit_name.split(' ')[1]);
			}

			if (this.context === 'MESSAGE') {
				newInterview.message = this.interviewdDetails.message
											.replace(/\$TODAY\$/g, todaytext)
											.replace(/\$FULL_NAME\$/g, this.interviewProfileData.Recruit_user_name)
											.replace(/\$FIRST_NAME\$/g, this.interviewProfileData.Recruit_user_name.split(' ')[0])
											.replace(/\$LAST_NAME\$/g, this.interviewProfileData.Recruit_user_name.split(' ')[1]);
			}

		}

		newInterview.remote_id = this.interviewdDetails.zoomidhire;
		newInterview.is_remote_interview = this.interviewdDetails.is_remote_interview;
		newInterview.job_type = Number(this.interviewdDetails.job_type);
		newInterview.slots = [];

		this.timedSelection.forEach((value: any, key: any) => {
			let slots: any = {};
			// create date format
			let startHour: number = Number(value.time.time.slice(0, 2));
			let startMin: number = Number(value.time.time.slice(3, 5));
			let startStatus: string = value.time.time.slice(6, 8);
			let endHour: number = Number(value.time.time.slice(11, 13));
			let endMin: number = Number(value.time.time.slice(14, 16));
			let endStatus: string = value.time.time.slice(17, 19);
			let midnightSelected: boolean = false;
			// add next day if user select 12.00 am as time slot
			if (endHour === 12 && endStatus === 'AM' && startStatus === 'PM') {
				let m: any = moment.unix(moment(value.date, 'MM/DD/YYYY').unix());
				let dayAfterSelected: string = moment(new Date(new Date().setDate(m._d.getDate() + 1))).format('MM/DD/YYYY');
				parts = dayAfterSelected.split('/');
				partsMidNight = value.date.split('/');
				endHour = 0;
				midnightSelected = true;
			} else {
				parts = value.date.split('/');
			}

			// convert to 24h
			if (startStatus === 'PM' && startHour !== 12) {
				startHour = startHour + 12;
			}
			if (endStatus === 'PM' && endHour !== 12) {
				endHour = endHour + 12;
			}
			if (startStatus === 'AM' && startHour === 12) {
				startHour = 0;
			}
			if (endStatus === 'AM' && endHour === 12) {
				endHour = 0;
			}

			if (value.time.timeUpdated) {
				slots.start_time = parts[2] + '-' + parts[0] + '-' + parts[1] + 'T' + ('00' + startHour).slice(-2) + ':' + ('00' + startMin).slice(-2) + ':00' + this.selectedTimeZoneCombo;
				slots.end_time = parts[2] + '-' + parts[0] + '-' + parts[1] + 'T' + ('00' + endHour).slice(-2) + ':' + ('00' + endMin).slice(-2) + ':00' + this.selectedTimeZoneCombo;
			} else {
				if (midnightSelected) {
					slots.start_time = partsMidNight[2] + '-' + partsMidNight[0] + '-' + partsMidNight[1] + 'T' + ('00' + startHour).slice(-2) + ':' + ('00' + startMin).slice(-2) + ':00' + this.selectedTimeZoneCombo;
				} else {
					slots.start_time = parts[2] + '-' + parts[0] + '-' + parts[1] + 'T' + ('00' + startHour).slice(-2) + ':' + ('00' + startMin).slice(-2) + ':00' + this.selectedTimeZoneCombo;
				}
				slots.end_time = parts[2] + '-' + parts[0] + '-' + parts[1] + 'T' + ('00' + endHour).slice(-2) + ':' + ('00' + endMin).slice(-2) + ':00' + this.selectedTimeZoneCombo;
			}
			newInterview.slots.push(slots);
		});
		newInterview.candidates = [];
		newInterview.time_zone = this.selectedTimeZoneCombo;
		newInterview.time_zone_abbrv = this.abbreviationTxt;

		this.selectedCandidates.forEach((value: any, key: any) => {
			let candidate: any = {};
			candidate.recruit_id = value.id;
			candidate.other = value;
			newInterview.candidates.push(candidate);
		});
		//console.log('newInterview');
		//console.log(newInterview);

		this._interviewService.createInterview(newInterview).subscribe((response: any) => {
			//console.log('response of create interview');
			//console.log(response);
			if (response.Code === 200) {
				//console.log('successsfully saved');
				this._socketIOService.createInterview(newInterview);
				this._toastr.success('Interview created.');
				this.closeThisDialog();
			} else {
				this._toastr.error('Something is not right, please try again');
				this.closeThisDialog();
			}
			// close this dialog
			this.closeThisDialog();
		});
	}

	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
