import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { JobBoardService } from '../../../core/services/jobBoard.service';
import { UserService } from '../../../core/services/user.service';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'create-email-invite',
	templateUrl: './create-email-invite.component.html',
	styleUrls: ['./create-email-invite.component.scss']
})
export class CreateEmailInviteComponent implements OnInit {

	@Input() job_ID: any;
	@Input() context: string = 'HEADER';

	// view status
	public viewStatus: number = 0;
	public inviteEmailsEnter: any[] = [];
	public inviteData: any = {
		candidates: []
	};
	public vacancy_id: string = 'Unnanu';
	public vacancy_title: string = 'SignUp';
	public disabled: boolean = false;

	public comboStatus: boolean = true;
	public listData: any[] = [];
	public selectedValue: any;
	public selectListCombo: any = '';

	constructor(private _modal: NgbActiveModal,
		private _utilityService: UtilityService,
		private _userService: UserService,
		private _jobBoardService: JobBoardService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {
		this._getListData();
		if (this.context === 'HEADER') {
			this.vacancy_id = 'Unnanu';
			this.vacancy_title = 'SignUp';
		}
		if (this.context === 'JOBBOARD' && this.job_ID) {
			this._jobBoardService.getSingleJobData(this.job_ID).subscribe((response: any) => {
				if (response.status === 200 && response.Code === 200) {
					let vacancyData: any = response.Data;
					this.vacancy_id = vacancyData.job_ref_id;
					this.vacancy_title = vacancyData.job_title;
				}
			});
		}
	}

	private _getListData(): void {
		this._userService.getJobEmailList().subscribe((response: any) => {
			this.listData = response.Data;
			if (this.listData.length > 0) {
				this.selectedValue = this.listData[0].id;
			}
		});
	}

	selectList(): void {
		if (this.selectListCombo !== '') {
			this.listData.forEach((value: any, key: any) => {
				if (value.id === Number(this.selectListCombo)) {
				this.inviteEmailsEnter = value.sortDistEmails.split(',').map((email: any) => ({
					display: email,
					value: email
				}));
				}
			});
		}
	}

	private validateTag(control: AbstractControl) {
		if (!this._utilityService.emailFormat.test(control.value)) {
			return {
				'validateTag': true
			};
		}
		return null;
	}

	public validators = [this.validateTag.bind(this)];

	public errorMessages = {
		'validateTag': 'Enter a valid email.'
	}

	sendEmailInvite(): void {
		this.inviteEmailsEnter.forEach((value: any, key: any) => {
			let candidateDetails: any = {};
			candidateDetails.email = value.value;
			this.inviteData.candidates.push(candidateDetails);
		});

		if (this.context === 'HEADER') {
			this._userService.createEmailInvite(this.inviteData.candidates).subscribe((response: any) => {
				if (response.Code === 200) {
					this._toastr.success(`Invite to signup has been sent to selected emails`);
				} else {
					this._toastr.error(`Something went wrong!`);
				}
				this._modal.close(response.Data);
			});
		}

		if (this.context === 'JOBBOARD') {
			this._jobBoardService.createNewEmailInvite(this.inviteData.candidates, this.job_ID, 1).subscribe((response: any) => {
				if (response.Code === 200) {
					this._toastr.success(`Invite to apply has been sent to selected emails`);
				} else {
					this._toastr.error(`Something went wrong!`);
				}
				this._modal.close(response.Data);
			});
		}

	}

	closeThisDialog() {
		this._modal.dismiss();
	}
}
