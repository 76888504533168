import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileViewModalComponent } from '../components/file-view-modal/file-view-modal.component';

@Directive({
	selector: '[attachmentView]'
})
export class AttachmentViewDirective implements OnInit {

	@Input('value') value: any;

	public fileUrl: any;

	constructor(public renderer: Renderer2,
		public elmRef: ElementRef,
		private _modalService: NgbModal) {

	}

	ngOnInit() {
		this.fileUrl = this.value;
	}

	openModal(fileUrl: any): void {
		let modal = this._modalService.open(FileViewModalComponent, {size: 'lg'});
		modal.componentInstance.fileUrl = fileUrl;
	}

	@HostListener('click', ['$event']) onClick($event: any) {
		this.openModal(this.fileUrl);
	}
}
