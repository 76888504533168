import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilityService } from '../../../core/services/utility.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
	selector: 'preview-send-offers',
	templateUrl: './preview-send-offers.component.html',
	styleUrls: ['./preview-send-offers.component.scss']
})
export class PreviewSendOfferComponent implements OnInit {

	@Input() offerData: any = {};
	@Input() selectedCandidates: any[] = [];
	@Input() abbrtime: string = '';
	@Input() candidateList: any = {};

	@Output() moveBackward: EventEmitter<any> = new EventEmitter<any>();
	@Output() sendNewOffer: EventEmitter<any> = new EventEmitter<any>();

	public isDisabled: boolean = false;

	constructor(private _modal: NgbActiveModal, public utilityService: UtilityService) { }

	ngOnInit(): void {

	}

	disableAndDismiss(value: boolean) {
		this.isDisabled = value;
		if (value) {
			this._modal.dismiss();
		}
	}
}
