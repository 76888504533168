import { Component } from '@angular/core';

@Component({
	selector: 'pre-loader',
	templateUrl: './pre-loader.component.html',
	styleUrls: ['./pre-loader.component.scss']
})
export class PreloaderComponent {
	constructor() {}
}
