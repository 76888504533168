import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable()
export class MessageService {

	private serverUrl = environment.urls.messageUrl;
	private serverUrlV2 = environment.urls.messageUrlV2;

	constructor(private _http: HttpClient,
				private _cookieService: CookieService) { }

	// get conversations
	getConversations(lastIndex: number, searchText: string) {
		return this._http.get(`${this.serverUrlV2}message/conversation?lastIndex=${lastIndex * 10}&searchKey=${searchText}`);
	}

	getConversationsList(lastIndex: number, searchText: string) {
		return this._http.get(`${this.serverUrlV2}message/conversation/list?lastIndex=${lastIndex * 15}&searchKey=${searchText}`);
	}

	// get message
	getMessage(conId: any, lastTimestamp: any, messageCount: any) {
		return this._http.get(`${this.serverUrl}message/conversation/${conId}/?last_timestamp=${lastTimestamp}&message_count=${messageCount}`);
	}

	getConversarionById(userId: any) {
		return this._http.get(`${this.serverUrlV2}message/conversation/recruit?recruitId=${userId}`);
	}

	// mark as read
	markAsRead(conId: any) {
		let data: any = {
			conversation_id: conId
		}
		return this._http.post(`${this.serverUrl}message/read`, data);
	}

	deactiveMessage(messageId: any) {
		return this._http.post(`${this.serverUrl}message/hiresender/${messageId}/delete`, {});
	}

	createConversation(conversationData: any) {
		let data: any = {
			owner_type: 2,
			owner_id: this._cookieService.get('user_id_Hire'),
			hire_id: this._cookieService.get('user_id_Hire'),
			recruit_id: conversationData.Recruit_id
		}
		return this._http.post(`${this.serverUrl}message/newConversation`, data);
	}

	// file upload
	uploadAttachment(file: any) {

		let fd = new FormData()
		fd.append('UploadedFile', file)

		if (file.type === 'application/pdf') {
			fd.append('FileType', 'PDF')
		} else if (file.type.substring(0, 5) === 'image') {
			fd.append('FileType', 'IMAGE')
		} else if (file.type.substring(0, 5) === 'video') {
			fd.append('FileType', 'VIDEO')
		} else if (file.type.substring(0, 5) === 'audio') {
			fd.append('FileType', 'AUDIO')
		} else {
			fd.append('FileType', 'OTHERS')
		}

		return this._http.post(file.uploadUrl, fd);
	}

	//  get message templates
    getMessageTemplates() {
		return this._http.get(`${this.serverUrl}message/templates`);
	}

	// save mesage templates
	saveMessageTemplates(saveTemplateData: any) {
		return this._http.post(`${this.serverUrl}message/templates`, saveTemplateData);
	}

	// edit message templates
	editMessageTemplates(editTemplateData: any) {
		return this._http.post(`${this.serverUrl}message/templates/edit`, editTemplateData);
	}

	// delete message templates
	deleteMessageTemplates(deleteTemplateData: any) {
		return this._http.post(`${this.serverUrl}message/templates/delete`, deleteTemplateData);
	}

}
