<div class="pop-up-content">
	<div class="job-title-wrapper col-lg-6 col-md-6" style="margin-top: 0;">
		<label style="width: 76px;">Job Title</label>
		<search-job-title [selectedtitle]=" offerToSend === 'Candidates' ? selectedCandidates[0]?.position : ''"
			(keywordChosen)="keywordChosen($event)"></search-job-title>
	</div>
	<div class="job-type-wrapper" style="margin-bottom: 0;">
		<label>Job Type</label>
		<div class="select-wrapper" style="margin-bottom: 0;">
			<select class="form-control" [(ngModel)]="offer.job_type" required="true">
				<option value="undefined" selected>Select Job Type</option>
				<option [value]="1">Employee</option>
				<option [value]="2">Internship</option>
				<option [value]="3">Contract</option>
				<option [value]="4">Volunteer</option>
			</select>
		</div>
	</div>
	<div class="job-title-wrapper col-lg-8 col-md-8" style="margin-top: 14px; margin-bottom: 12px;">
		<label style="width: 76px;">Salary ($)</label>
		<input class="form-control" type="text" [(ngModel)]="offer.salary" placeholder="Enter Salary"
			currencyMask [options]="{ prefix: '$', thousands: ',', precision: 0, align:'left' }"
			style="width: 250px;" [maxlength]="10" />
		<div class="job-type-wrapper" style="margin-bottom: 0; margin-top: 0;">
			<div class="select-wrapper" style="margin-left: 0;">
				<select class="form-control" [(ngModel)]="offer.payment_type">
					<option value="undefined">Select Type</option>
					<option [value]="1">Hourly</option>
					<option [value]="2">Yearly</option>
					<option [value]="3">Weekly</option>
					<option [value]="4">Bi-Weekly</option>
					<option [value]="5">Bi-Monthly</option>
					<option [value]="6">Monthly</option>
				</select>
			</div>
		</div>
	</div>
</div>
<div class="zoom-wrapper pull-left">
	<div class="checkboxWrapper col-lg-6 pull-left">
		<input [(ngModel)]="checkOfferTemplate" class="form-check" type="checkbox"
			(ngModelChange)="getNewOfferTemplates(checkOfferTemplate)" />
		<label class="pull-left" for=""></label>
		<span class="pull-left">Use Offer Template</span>

		<div class="job-type-wrapper" [ngClass]="{'fade' : !checkOfferTemplate || startPreloader}"
			style="margin-bottom: 0; margin-top: -6px;">
			<div class="select-wrapper">
				<select class="form-control" [(ngModel)]="templateDropDown" (change)="setTemplateData(templateDropDown)"
					style="width: 150px;">
					<option value="" selected="">Select Template</option>
					<option value="{{template.template_id}}" *ngFor="let template of offerTemplates">
						{{template.template_name}}
					</option>
				</select>
				<i *ngIf="startPreloader" style="right: 24px;"></i>
			</div>
		</div>
	</div>
</div>
<div class="send-message-wrapper offers"
	[ngClass]="{'fade' : checkOfferTemplate && (templateDropDown === undefined || templateDropDown === '' )}">
	<p class="offerData-select-title">Send a message to Candidate(s)</p>
	<div class="form-group col-lg-7 col-md-7">
		<textarea id="{{'offer.message' + index}}" class="form-control" [rows]="10" [(ngModel)]="offer.offer_message"
			required="true" [maxlength]="5000" placeholder="Offer content limit is 5,000 characters" [mention]="people"
			[mentionConfig]="{triggerChar:'$',maxItems:10,labelKey:'label', mentionSelect: getPeopleText, mentionFilter: searchPeople}">
		</textarea>
	</div>
	<div class="keyword-details-wrapper col-lg-5 col-md-7">
		<p>
			Keywords can be used to fill in the appropriate values from the
			Candidate’s profile when the message is sent. The available keywords are:
		</p>
		<div class="table-wrapper">
			<table>
				<tr>
					<td>$TODAY$</td>
					<td class="keyword-description">DO NOT DELETE $TODAY$</td>
				</tr>
				<tr>
					<td>$FULL_NAME$</td>
					<td class="keyword-description">Seeker's Full Name</td>
				</tr>
				<tr>
					<td>$FIRST_NAME$</td>
					<td class="keyword-description">Seeker's First Name</td>
				</tr>
				<tr>
					<td>$LAST_NAME$</td>
					<td class="keyword-description">Seeker's Last Name</td>
				</tr>
			</table>
		</div>
	</div>
</div>
<div class="wrapper">
	<div class="content-left pull-left">
		<label>Attachment</label><i class="info pull-left" placement="top" ngbTooltip="Attach documents to support this Offer (Offer letter and paperwork for Onboarding)."></i>
	</div>
	<div class="content-right updated pull-left">
		<div class="wrapper" style="padding: 0;" *ngFor="let attachement of offerData.attachments; let $index=index"
			[ngClass]="{'w-border' : $index !== 0}">
			<button class="btn btn-upload edit pull-left" attachmentView
				[value]="utilityService.getCdnUrl(attachement.attachment_url)">{{attachement.file_name}}</button>
			<i class="close-new" (click)="removeUploadedAttachment($index)"></i>
		</div>

		<div class="wrapper" [ngClass]="{'w-border' : offerData.attachments.length > 0}" style="padding-left: 0;">
			<label class="custom-file" *ngIf="!uploadingAttachment">
				<input id="file" class="custom-file-input" type="file"
					[ngClass]="{'fade' : checkOfferTemplate && (templateDropDown === undefined || templateDropDown === '' )}"
					(change)="onFileChange($event)" accept=".pdf,.docx,.doc" />
				<button class="btn btn-upload pull-left">+ Upload Document</button>
			</label>
			<label class="custom-file uploading" *ngIf="uploadingAttachment">
				<button class="btn btn-upload uploading pull-left">
					Uploading <i></i>
				</button>
			</label>
		</div>
	</div>
</div>
<div class="modal-footer pop-up-footer">
	<button type="button" class="btn btn-back" (click)="moveBackward.emit(0)" *ngIf="!offer_other">
		BACK
	</button>
	<button type="button" class="ngdialog-button popup-footer-save-button"
		(click)="moveForward.emit({tabIndex: 2, interviewData: offer})"
		[disabled]="offer.offer_message?.length === 0 || offer.salary?.length === 0 || offer.payment_type?.length === 0 || offer.job_title?.length === 0 || !offer.job_type">
		NEXT
	</button>
</div>
