import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { EventService } from '../../../core/services/event.service';
import { UserService } from '../../../core/services/user.service';

@Component({
	selector: 'search-skills',
	templateUrl: './search-skills.component.html',
	styleUrls: ['./search-skills.component.scss']
})
export class SearchSkillsComponent implements OnInit, OnChanges {

	@Input() placeholder: string = '';
	@Input() searchedSkill: string = '';
	@Input() setvalidate: any;
	@Input() addBottomMargin: boolean = true;
	@Output() skillChosen: EventEmitter<string> = new EventEmitter<string>();

	public foundError: any;
	public selectedSkills: any;


	constructor(private _eventService: EventService,
		private _userService: UserService) { }

	ngOnInit(): void {

		this.foundError = this.setvalidate;
		// set selected skill
		if (this.searchedSkill?.length) {
			this.selectedSkills = this.searchedSkill.split(',');
		}

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.setvalidate?.isFirstChange() && changes.setvalidate?.currentValue) {
			this.foundError = true;
		}
	}

	searchSkills = (query: string): Observable<any> => {
		return this._userService.searchSkills(encodeURIComponent(query));
	}


	update(): void {
		if (this.selectedSkills.length) {
			this.searchedSkill = this.selectedSkills.join(',');
		} else {
			this.searchedSkill = '';
		}
		this.skillChosen.emit(this.searchedSkill);
	}

}
