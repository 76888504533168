import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Message {
	type: string;
	payload: any;
}

type MessageCallback = (payload: any) => void;

@Injectable()
export class EventService {
	private handler = new Subject<Message>();

	broadcast(type: string, payload: any) {
		this.handler.next({ type, payload });
	}

	subscribe(type: string, callback: MessageCallback): Subscription {
		return this.handler.pipe(
			filter((message: Message) => message.type === type),
			map((message: Message) => message.payload)
		).subscribe(callback);
	}
}
