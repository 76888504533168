import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class LinkedinService {

	constructor(private _http: HttpClient) {

	}

	getLinkedinAuth() {
		let data: any = {
			response_type: 'code',
			client_id: environment.keys.linkedin_client_id,
			scope: 'r_liteprofile',
			redirect_uri: environment.keys.linkedin_redirect_uri
		};

		return this._http.post(`https://www.linkedin.com/oauth/v2/authorization`, data);
	}

	getLinkedinAccessCode(accesscode: any) {
		let data: any = {
			grant_type: 'authorization_code',
			client_id: environment.keys.linkedin_client_id,
			client_secret: environment.keys.linkedin_client_secret,
			code: accesscode,
			redirect_uri: environment.keys.linkedin_redirect_uri
		};

		return this._http.post(`https://www.linkedin.com/oauth/v2/accessToken`, data);
	}

	postLinkedinJob(aToken: any, cid: any, url: any, descr: any, ftime: any, vid: any, odate: any, title: any) {

		let headers = new HttpHeaders();
		headers = headers.append('Authorization', `Bearer ${aToken}`);
		headers = headers.append('x-restli-method', `batch_create`);
		headers = headers.append('Access-Control-Allow-Origin', `*`);

		let data: any = {
			elements: [{
				integrationContext: 'urn:li:organization:' + cid,
				companyApplyUrl: url,
				description: descr,
				employmentStatus: ftime,
				externalJobPostingId: vid,
				listedAt: odate,
				state: 'LISTED',
				title: title,
				countryCode: 'us',
				postalCode: '78701'
			}]
		};

		return this._http.post(`https://api.linkedin.com/v2/simpleJobPostings`, data, {headers: headers});
	}
}
