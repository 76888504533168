import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'preview-send',
	templateUrl: './preview-send.component.html',
	styleUrls: ['./preview-send.component.scss']
})
export class PreviewSendComponent implements OnInit {

	@Input() context: string = 'INTERVIEW';
	@Input() interviewdDetails: any = {};
	@Input() selectedCandidates: any[] = [];
	@Input() timedSelection: any[] = [];
	@Input() abbrtime: string = '';

	@Output() moveBackward: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveInterviewData: EventEmitter<any> = new EventEmitter<any>();

	public isDisabled: boolean = false;

	constructor() { }

	ngOnInit(): void {

	}

}
