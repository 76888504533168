<div class="modal-header">
	<h4 class="modal-title" id="modal-title">New Interview Request</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">Close</span>
	</button>
</div>
<div class="modal-body">
	<!-- 01 - interview details start -->
	<interview-details [hidden]="!interviewDetailsProfile" [selectedCandidates]="selectedCandidates" [context]="'PROFILE'"
		(moveForward)="moveForward($event.tabIndex, $event.interviewData)"></interview-details>
	<!-- 01 - interview details end  -->

	<!-- 02 - time selection start -->
	<time-selection [hidden]="!timeSelectionProfile" [context]="'PROFILE'" [selectedCandidates]="selectedCandidates"
		[abbreviationTxt]="abbreviationTxt" [selectedTimeZoneCombo]="selectedTimeZoneCombo"
		(moveForward)="moveForward($event.tabIndex, $event.interviewData)" (moveBackward)="moveBackward($event)"
		(selectedTimeZone)="selectedTimeZone($event)"></time-selection>
	<!-- 02 - time selection end -->

	<!-- 03 - preview send start -->
	<preview-send [hidden]="!previewSendProfile" [interviewdDetails]="interviewdDetails" [context]="'PROFILE'"
		[selectedCandidates]="selectedCandidates" [timedSelection]="timedSelection" [abbrtime]="abbreviationTxt"
		(moveBackward)="moveBackward($event)" (saveInterviewData)="saveInterviewData()"></preview-send>
	<!-- 03 - preview send end -->
</div>
