// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	urls: {
		// serverUrl: 'http://localhost:4358/api/v1/',
		// serverUrlV2: 'http://localhost:4358/api/v12/',
		serverUrl: 'https://uat-hire-api-v5.unnanu.com/api/v1/',
		serverUrlV2: 'https://uat-hire-api-v5.unnanu.com/api/v12/',
		// hireothUrl: 'http://localhost:4358/api/v1/',
		hireothUrl: 'https://uat-hire-oth-v5.unnanu.com/api/v1/',
		messageUrl: 'https://uat-common-api-v5.unnanu.com/api/v1/',
		messageUrlV2: 'https://uat-common-api-v5.unnanu.com/api/v12/',
		// messageUrl: 'http://localhost:64501/api/v1/',
		// messageUrlV2: 'http://localhost:64501/api/v12/',
		mockableUrl: 'http://mockable.io/',
		paymentServerUrl: 'https://uat-payments-v5.unnanu.com/api/v1/',
		// paymentServerUrl: 'http://localhost:25497/api/v1/',
		socketServerURL: 'https://uat-realtime-v5.unnanu.com/',
		fileServerUrl: 'https://cdn.unnanu.com',
		settingsServerUrl: 'https://cdn.unnanu.com',
		jobboardUrl: 'https://uat.jobs.unnanu.com/',
		recruitUrl: 'https://uat.app.unnanu.com/',
		hireUrl: 'https://uat.hireapp.unnanu.com',
		UNNANU_LANDING_PAGE: 'https://uat.talent.unnanu.com',
		HIRE_LANDING_PAGE: 'https://uat-hire.unnanu.com/pricing',
		HIRE_LANDING_BASE: 'https://uat-hire.unnanu.com/',
	},
	keys: {
		stripeKey: 'pk_test_nUzZCbhMh3VAUiT09Mbz1ftm',
		google_client_id:
			'179779178843-cik41cd628og1meleo75rlamd6kr0cm4.apps.googleusercontent.com',
		google_client_secret: 'XzUmmOKjDvvX6A6KLZz38D-o',
		google_redirect_uri: window.location.origin,
		ms_client_id: 'c00b62a1-7e15-4cc7-8132-b72045438731',
		// Get ms client from Azure - Select single page application
		ms_redirect_uri: window.location.origin,
		ms_scopes: [
			'offline_access',
			'User.Read',
			'Calendars.ReadWrite',
			'Calendars.ReadWrite.Shared',
		],
		ga_key: null,
		linkedin_client_id: '771yd540xvpw8n',
		linkedin_client_secret: 'w2BXU2Gp1EXd3lhZ',
		linkedin_redirect_uri: window.location.origin,
		linkedin_auth:
			'AQXNkMuU2LzIrU9gLOm2KdjOtqwtvSvXE6drvCOaS9KM5K34Se_cXq7cBXVrl0mb4eKnFBOLwK6Tp94HjV-wUvC0z2O4DRzsDBxFZxgyWlL8X5TWIPZRZCrpTU5nlmfSny2Y3vRX8brfvzOdDTPV7Qmn25m_E-M_Em_sPjZvlTs5b5f0aTqcTMdfimrkISX825tBIrOSDqzkGaDKwU1FEiyabyxn2ElZ7Axx42yGzrWCZ7XQ9UUlfKsaVc-saCPLitAcEKK5CxNLJ-a4_py1IpE4-LXzfUbL0ZgxhSGhvmq4dkxHtyRt3areqtjkS1FYxMnzkla4-6YDiFaDQ1b1LEeuWGKhog',
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
