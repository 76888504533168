import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmAlertComponent } from '../components/confirm-alert/confirm-alert.component';

@Directive({
	selector: '[upgradeSubscription]'
})
export class UpgradeSubscriptionDirective implements OnInit {

	@Input() upgradeSubscription: boolean = false;

	constructor(private _modalService: NgbModal,
		private _router: Router) {

	}

	ngOnInit(): void {
	}

	openModal(): void {
		let modal = this._modalService.open(ConfirmAlertComponent, { windowClass: 'dlt', backdrop: 'static', keyboard: false });
		modal.componentInstance.header = `Upgrade Subscription`;
		modal.componentInstance.contentType = 'Text';
		modal.componentInstance.msg = `Please upgrade your subscription to use this feature.`;
		modal.componentInstance.showYesButton = true;
		modal.componentInstance.showNoButton = true;
		modal.componentInstance.yesButtonText = `Upgrade Now`;
		modal.componentInstance.noButtonText = `Cancel`;
		modal.componentInstance.noButtonClass = `btn-deactivate`;
		modal.result.then((closed: any) => {
			this._modalService.dismissAll();
			this._router.navigate(['/subscription', 'update']);
		},
			(dismissed: any) => {

			});
	}

	@HostListener('mousedown', ['$event']) onMouseDown(event: any) {
		if (this.upgradeSubscription) {
			const currentItem = event.currentTarget as HTMLElement;
      		currentItem.style.pointerEvents = 'none';
			this.openModal();
			setTimeout(() => {
				currentItem.style.removeProperty('pointer-events');
			}, 200);
		}
	}
}
