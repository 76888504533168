import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { JobBoardService } from '../../../core/services/jobBoard.service';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'show-job-notes',
	templateUrl: './show-notes.component.html',
	styleUrls: ['./show-notes.component.scss']
})
export class ShowJobNotesComponent implements OnInit {

	@Input() userTitle: any;
	@Input() vacancyID: any;

	public showAllApplicantsPreloader: boolean = true;
	public isPrivate: boolean = false;
	public showNotes: string = '';
	public teamNotes: any[] = [];

	constructor(public utilityService: UtilityService,
		private _modal: NgbActiveModal,
		private _jobBoardService: JobBoardService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {
		this._jobBoardService.getJobNotes(this.vacancyID).subscribe((response: any) => {
			if (response.status === 200 && response.Code === 200) {
				let JN = response.Data.jobNotes;
				if (JN !== null && JN !== undefined && JN !== '') {
					this.showNotes = 'Date: $TODAY$' + '\n\n' + JN;
				} else {
					this.showNotes = 'Date: $TODAY$' + '\n';
				}
				this.isPrivate = response.Data.jprivate;
				this.showAllApplicantsPreloader = false;
				this.teamNotes = response.Data.teamJobNotes;

			} else {
				// show error message
				this._toastr.error('Something went wrong!');
			}
		});
	}

	// Method of the GetApplicantNotes popup
	saveNotesData(showNotes: any, isPrivate: any): any {
		let todaytext = moment(new Date()).format('YYYY-MM-DD @ h:mm:ss a');

		if (showNotes !== null && showNotes !== undefined) {
			showNotes = showNotes.replace(/\$TODAY\$/g, todaytext);
		}

		this._jobBoardService.saveJobNotes(this.vacancyID, showNotes, isPrivate).subscribe((response: any) => {
			// console.log(response);
			if (response.Code === 200) {
				this._toastr.success('Job Notes are Saved.');
			} else {
				this._toastr.error('Something went wrong!');
			}
			this.closeThisDialog();
		});
	}


	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
