<div class="modal-header">
	<h4 class="modal-title" id="modal-title">New Offer</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		<span aria-hidden="true">Close</span>
	</button>
</div>
<div class="modal-body">
	<div class="pop-up-content">
		<div class="process-wrapper offers new">
			<div class="step-wrapper continue" [ngClass]="{'active' : viewStatus >= 1, 'prev' : viewStatus > 1}">
				<span class="step">01</span> Offer Details
			</div>
			<div class="step-wrapper" [ngClass]="{'active' : viewStatus === 2}">
				<span class="step">02</span> Preview &amp; Send
			</div>
		</div>
	</div>
	<!-- 01 - select candidates end -->
	<offer-details [hidden]="viewStatus === 2" [offerData]="offerData" [selectedCandidates]="selectedCandidates" [offer_other]="offer_other"
		(moveForward)="moveBackward($event)" (moveForward)="moveForward($event.tabIndex, $event.interviewData)"></offer-details>
	<!-- 04 - preview send start -->
	<preview-send-offers [hidden]="viewStatus === 1" [abbrtime]="abbreviationTxt"
		[offerData]="offerData" [selectedCandidates]="selectedCandidates" (moveBackward)="moveBackward($event)" (sendNewOffer)="sendNewOffer()"></preview-send-offers>
</div>
