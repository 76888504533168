<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Enable Virtual Interviews</h4>
</div>
<div class="modal-body">
	<div class="pop-up-content">
		<p class="cancel-text">Unnanu Hire has an Integration to conduct Virtual Interviews with the Candidates. Go to
		  Settings to Enable and Configure this Feature.</p>
	  </div>
</div>
<div class="modal-footer text-right">
	<div class="button-wrapper">
		<button type="button" class="btn no-button" (click)="closeThisDialog()">Cancel</button>
		<button type="button" class="btn yes-button" (click)="openSettings()">Open Settings</button>
	</div>
</div>
