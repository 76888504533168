<div class="modal-header">
	<h4 class="modal-title">{{Header_title}}</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeThisDialog()">
		Close
	</button>
</div>
<div class="modal-body">
	<div class="pop-up-content">
		<div class="process-wrapper offers new" style="background-color: #32a1f7;">
		  <div class="step-wrapper" [ngClass]="{'active' : viewStatus == 0}">
			<span class="step" style="margin-left: 20px; color: white; font-style: bold;"> Candidate Information: </span>
		  </div>
		</div>
	  </div>
		<div style="display: flex; align-items: center; justify-content: space-between; padding: 20px; font-size: medium;">
			<p class="interview-select-title" style="margin: 0; font-weight: bold;">Enter candidate details below:</p>
			<label *ngIf="vacancyid > 0" style="display: flex; align-items: center;">Bypass Questionnaire
				<input type="checkbox" id="bypassQuestionnaire" name="bypassQuestionnaire" [(ngModel)]="talentData.bypass" style="margin: 10px; width: 20px; height: 20px; cursor: pointer;" />
			</label>
		</div>
	  <form #talentDetailsForm="ngForm">
		<div class="change-data clearfix" style="padding-left: 40px; width: 500px;">
		  <div class=" form-group">
			<label class="form-label">Candidate First Name:</label>
			<div class="input-wrapper">
			  <input type="text" class="form-control" name="talentFirstName" [(ngModel)]="talentData.talentFirstName" pattern="^(\w\w+)\s(\w+)$"
				placeholder="Enter candidate first name" [maxlength]="100" required>
			</div>
		  </div>
		  <div class=" form-group">
			<label class="form-label">Candidate Last Name:</label>
			<div class="input-wrapper">
			  <input type="text" class="form-control" name="talentLastName" [(ngModel)]="talentData.talentLastName" pattern="^(\w\w+)\s(\w+)$"
				placeholder="Enter candidate last name" [maxlength]="100" required>
			</div>
		  </div>
		  <div class="form-group">
			<label class="form-label">Email Address:</label>
			<div class="input-wrapper">
			  <input type="email" class="form-control" name="talentEmail" [(ngModel)]="talentData.talentEmail"
				pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" placeholder="Enter email address">
			</div>
		  </div>
		  <div class="form-group">
			<label class="form-label">Contact Phone:</label>
			<div class="input-wrapper">
			  <input type="text" class="form-control" name="talentPhone" [(ngModel)]="talentData.talentPhone" [mask]="'(000) 000-0000'">
			</div>
		  </div>
		  <div class="header pull-left"
			*ngIf="talentData.talentFirstName?.length && talentData.talentLastName?.length && talentData.talentEmail?.length && talentData.talentPhone?.length">
			<div class="question-file-upload">
			  <div class="question-file-upload-label" style="padding-top: 10px;">Attach a resume here
			  </div>
			  <div class="question-file-upload-ui">
				<label class="custom-file pull-right" style="margin-top: -30px;">
				  <input id="file" class="custom-file-input" type="file" (change)="onFileChange($event)"
					accept=".pdf,.docx,.doc">
				  <button class="btn btn-upload pull-left">+ Upload Resume</button>
				</label>
				<label class="custom-file pull-right" style="margin-top: -41px;" *ngIf="uploadingAttachment">
				  <button class="btn btn-upload uploading pull-left">Uploading<i></i></button>
				</label>
				<div *ngIf='templateData.attachments?.length==1'>
				  <button class="btn btn-upload edit pull-left" style="margin-top: -41px; width: 333px;" attachmentView
					[value]="utilityService.getCdnUrl(templateData.attachments[0].attachment_url)">{{filename}}'s Resume
					Attached</button>
				  <i class="close-new pull-right" style="margin-top: -28px;" (click)="removeUploadedAttachment()"></i>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	</form>
	<div class="text-right" *ngIf="talentData.talentFirstName?.length && talentData.talentLastName?.length && talentData.talentEmail?.length && talentData.talentPhone?.length">
		<button type="button" class="popup-footer-save-button" style="padding-top: 20px;"
			[disabled]="talentData.talentFirstName?.length == 0 || talentData.talentLastName?.length == 0 || talentData.talentEmail?.length == 0 || templateData.attachments?.length !== 1 || sendbutton"
			(click)="sendTalentEmailInvite(talentData)">
			SEND
	  </button>
	</div>
</div>
