<div class="pop-up-content">
	<div *ngIf="context == 'INTERVIEW'" class="process-wrapper">
		<div class="step-wrapper continue active prev">
			<span class="step">01</span>
			Select Candidates
		</div>
		<div class="step-wrapper continue active">
			<span class="step">02</span>
			Interview Details
		</div>
		<div class="step-wrapper continue">
			<span class="step">03</span>
			Time Selection
		</div>
		<div class="step-wrapper" style="width: 195px">
			<span class="step">04</span>
			Preview
		</div>
	</div>
	<div *ngIf="context == 'PROFILE'" class="process-wrapper reschedule">
		<div class="step-wrapper continue active">
			<span class="step">01</span>
			Interview Details
		</div>
		<div class="step-wrapper continue">
			<span class="step">02</span>
			Time Selection
		</div>
		<div class="step-wrapper">
			<span class="step">03</span>
			Preview
		</div>
	</div>
	<p class="interview-select-title">What position or a role are you hiring for?</p>
	<div class="job-title-wrapper col-lg-6 col-md-6">
		<label>Job Title</label>
		<search-job-title [selectedtitle]="selectedCandidates[0]?.position || ''"
			(keywordChosen)="setJobTitleKeyWord($event)"></search-job-title>
	</div>
	<div class="job-type-wrapper">
		<label>Job Type</label>
		<div class="select-wrapper">
			<select class="form-control" [(ngModel)]="interview.job_type" required="true">
				<option value="undefined" selected>Select Job Type</option>
				<option [value]="1">Employee</option>
				<option [value]="2">Internship</option>
				<option [value]="3">Contract</option>
				<option [value]="4">Volunteer</option>
			</select>
		</div>
	</div>
</div>
<div class="zoom-wrapper pull-left">
	<div class="checkboxWrapper col-lg-6 pull-left">
		<input class="form-check" type="checkbox" [(ngModel)]="interview.is_remote_interview" (ngModelChange)="onVirtualInterviewChange()">
		<label class="pull-left" for=""></label>
		<span class="pull-left">Virtual Interview</span><i placement="top" ngbTooltip="Enabling will utilize Zoom Integration for Virtual Interview."></i>
	</div>
	<div class="zoom-pmi-wrapper pull-left" *ngIf="interview.is_remote_interview && getRemoteZoomID()">
		<label class="zoom-label pull-left">Zoom PMI</label>
		<div *ngIf="!showZoomEdit" class="pull-left">
			<div class="zoom-code pull-left">
				{{interview.zoomidhire | zoom_id}}
			</div>
			<i class="edit pull-left" (click)="showZoomEdit = true; zoomidhireCopy = interview.zoomidhire"></i>
		</div>
		<div *ngIf="showZoomEdit" class="pull-left">
			<div class="form-group pull-left">
				<input class="form-control pull-left" type="text" [(ngModel)]="zoomidhireCopy" [mask]="zoom_pattern"
					[placeHolderCharacter]="' '">
			</div>
			<button class="btn btn-save pull-left"
				(click)="zoomidhireCopy.length >= 9 ? interview.zoomidhire = zoomidhireCopy : '' ; showZoomEdit = false"></button>
			<button class="btn btn-cancel pull-left"
				(click)="zoomidhireCopy = interview.zoomidhire; showZoomEdit = false"></button>
		</div>
	</div>
</div>
<div class="send-message-wrapper">
	<p class="interview-select-title">Send a Message to Interviewee(s)</p>
	<div class="form-group col-lg-7 col-md-7">
		<textarea id="{{'interview.message' + index}}" class="form-control" [rows]="10" [(ngModel)]="interview.message"
			required="true" [maxlength]="5000" placeholder="Interview Message limit is 5,000 characters"
			[mention]="people" [mentionConfig]="{triggerChar:'$',maxItems:10,labelKey:'label', mentionSelect: getPeopleText, mentionFilter: searchPeople}">
		</textarea>
	</div>
	<div class="keyword-details-wrapper col-lg-5 col-md-7">
		<p>Keywords can be used to fill in the appropriate values from the Candidate’s profile when the message is sent.
			The
			available keywords are:</p>
		<div class="table-wrapper">
			<table>
				<tbody>
					<tr>
						<td>$TODAY$</td>
						<td class="keyword-description">DO NOT DELETE $TODAY$</td>
					</tr>
					<tr>
						<td>$FULL_NAME$</td>
						<td class="keyword-description">Seeker's Full Name</td>
					</tr>
					<tr>
						<td>$FIRST_NAME$</td>
						<td class="keyword-description">Seeker's First Name</td>
					</tr>
					<tr>
						<td>$LAST_NAME$</td>
						<td class="keyword-description">Seeker's Last Name</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
<div class="modal-footer pop-up-footer">
	<button type="button" class="btn btn-back" *ngIf="context == 'INTERVIEW'" (click)="moveBackward.emit(0)">BACK</button>
	<button type="button" class="ngdialog-button popup-footer-save-button"
		(click)="moveForward.emit({tabIndex: 1, interviewData: interview})"
		[disabled]="interview.message.length === 0 || selectedJobTitle.length === 0 || interview.job_type === undefined">NEXT</button>
</div>
