<div class="date-and-time-select-wrapper" [ngClass]="{'disabled': selectedIntervalTime === undefined}">
	<div class="date-select">
		<ngb-datepicker #dp [(ngModel)]="data.embeddedDate" (dateSelect)="onTimeSet(data.embeddedDate)" [minDate]="minDate"></ngb-datepicker>
	</div>
	<div class="time-select">
		<div class="selected-date-wrapper">
			<p class="selected-date"> {{ viewDate(data.embeddedDate) | date:'EEEE, MMMM d, y' }}</p>
			<p class="pager"><span class="current">{{('00' + clikedTimeSlots.length).slice(-2)}}</span>/{{
				selectedamount > 99 ? ('00' + selectedamount).slice(-3) : ('00' + selectedamount).slice(-2) }}</p>
		</div>
		<div class="time-selector-wrapper">
			<ng-container *ngFor="let time of viewSelectedTimeSlots; let $index=index">
				<div class="time-tile" [hidden]="checkAvailableTime(time, data.embeddedDate)"
					[ngClass]="{'active' : time.selected === true, 'disabled' : checkAvailableTime(time, data.embeddedDate)}"
					[(ngModel)]="timeslotSelect" ngDefaultControl
					(click)="selectTimeSlot(time, data.embeddedDate, $index)">
						{{time.time}}
					</div>
			</ng-container>
		</div>
	</div>
</div>
