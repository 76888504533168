import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { AutocompleteService } from '../../../core/services/autocomplete.service';
import { EventService } from '../../../core/services/event.service';

@Component({
	selector: 'search-keyword',
	templateUrl: './search-keyword.component.html',
	styleUrls: ['./search-keyword.component.scss']
})
export class SearchKeywordComponent implements OnInit, OnChanges {

	@Input() placeholder: string = '';
	@Input() searchedKeyword: string = '';
	@Input() setvalidate: any;
	@Input() hideicon: boolean = false;
	@Input() readOnly: boolean = false;
	@Input() addBottomMargin: boolean = true;
	@Output() keywordChosen: EventEmitter<string> = new EventEmitter<string>();

	public foundError: any;
	public searchText: any;

	public suggestionsDropMenu: boolean = false;
	public suggestions: any = null;


	constructor(private _eventService: EventService,
		private _autoCompleteService: AutocompleteService) { }

	ngOnInit(): void {

		this.foundError = this.setvalidate;
		// set selected keyword
		if (this.searchedKeyword?.length) {
			this.searchText = this.searchedKeyword;
		}

		this._eventService.subscribe('keyword-null', () => {
			this.foundError = true;
		});

		this.setKeyword();

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.setvalidate?.isFirstChange() && changes.setvalidate?.currentValue) {
			this.foundError = true;
		}
	}

	search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.searchJobPosition())
		)

	// search text call when ng change
	searchJobPosition() {
		// check typed text length
		if (this.searchText?.length > 0) {
			return this._autoCompleteService.searchJobPosition(encodeURIComponent(this.searchText)).pipe(map((response: any) => {
				// check response status
				if (response.status === 200) {
					this.suggestionsDropMenu = true;
					this.suggestions = response.Data.Suggestions.splice(0,10);
					return this.suggestions;
				} else {
					this.suggestionsDropMenu = false;
					this.suggestions = null;
					return [];
				}
			}));
		} else {
			return of([]);
		}
	}

	setKeyword(): void {
		this.searchText = this.searchText ? this.searchText.trim() : undefined;
		this.keywordChosen.emit(this.searchText);
	}

	// drop down clicked
	dropdownClicked(event: any): void {
		event.preventDefault();
		// assigns selected value to text input
		this.searchText = event.item.name;
		// this.suggestionsDropMenu = false
		this.setKeyword();
	}


}
