import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'section-video',
	templateUrl: './section-video.component.html',
	styleUrls: ['./section-video.component.scss']
})
export class SectionVideoComponent implements OnInit {

	@Input() videoSection: any;
	@Input() sectionData: any = {};

	constructor(private _modal: NgbActiveModal,
				public utilityService: UtilityService) { }

	ngOnInit(): void {
		//console.log(this.sectionData);
		this.sectionData = this.sectionData;
		// check section video
		if (this.videoSection === 'EDU') {
			this.videoSection = 1;
		} else if (this.videoSection === 'CERTI') {
			this.videoSection = 2;
		} else {
			this.videoSection = 3;
		}
	}

	closeThisDialog() {
		this._modal.dismiss();
	}

}
