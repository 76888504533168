import { Injectable } from '@angular/core';
import { Router, CanLoad, Route, UrlSegment, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanLoadAuthGuard implements CanLoad {

  constructor(private _cookieService: CookieService, private _router: Router) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
	const incomingUrl: string = segments.reduce((url: string, segment: UrlSegment) => {
		return `${url}/${segment.path}`;
	  }, '');
	if (incomingUrl?.includes('/jobboard/get')) { // For jobboard iframe
		return true;
	} else {
		if (this._cookieService.check('token_Hire')) {
			return true;
	  } else {
		  this._router.navigate(['/'], { queryParams: { redirectpath: incomingUrl } });
		   return false;
	  }
	}

  }
}
