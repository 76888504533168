import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';

@Injectable()
export class SettingsService {

	private serverUrl = environment.urls.serverUrl;
	private paymentServerUrl = environment.urls.paymentServerUrl;

	constructor(private _http: HttpClient) {

	}

	// get user type
    getUserType() {
		return this._http.get(`${this.serverUrl}account/userType`);
	}

	// get account basic info
	getAccountBasicInfo() {
		return this._http.get(`${this.serverUrl}account/basicInfo`);
	}

	// get account basic info
	getAccountBasicLinkedinInfo() {
		return this._http.get(`${this.serverUrl}account/basicLinkedinInfo`);
	}

	// Linkedin Company
	ChangeCompanyId(companyId: any) {
		return this._http.post(`${this.serverUrl}account/linkedin/${companyId}`, {});
	}

	// get member list
	getMemberList() {
		return this._http.get(`${this.serverUrl}account/getMembers`);
	}

	// delete member
	deleteMember(memberID: any) {
		return this._http.post(`${this.serverUrl}account/member/remove/${memberID}`, {});
	}

	// change email
	changeEmail(emailData: any) {

		let data: any = {
			current_password: new Md5().appendStr(emailData.current_password).end(),
			new_email: emailData.new_email
		};

		return this._http.post(`${this.serverUrl}account/changeEmail`, data);
	}

		// change phone
		changePhone(emailData: any) {

			let data: any = {
				current_password: new Md5().appendStr(emailData.current_password).end(),
				new_phone: emailData.new_phone
			};
	
			return this._http.post(`${this.serverUrl}account/changePhone`, data);
		}

	// change password
	changePassword(passwordData: any) {

		let data: any = {
			current_password: new Md5().appendStr(passwordData.current_password).end(),
			new_password: new Md5().appendStr(passwordData.new_password).end()
		};

		return this._http.post(`${this.serverUrl}account/changePassword`, data);
	}

	// upload logo
	uploadCompanyLogo(uploadLogoData: any) {
		let fd = new FormData();
		fd.append('UploadedFile', uploadLogoData)

		return this._http.post(`${this.serverUrl}account/uploadCompanyPhoto`, fd);
	}

	// check payment status
	checkPaymentStatus() {
		return this._http.get(`${this.paymentServerUrl}subscription/hire/checkStatus`);
	}

	// get payment methods
	getPaymentMethods() {
		return this._http.get(`${this.paymentServerUrl}subscription/hire/payment/methods`);
	}

	// resubscribe
	resubscribePayment() {
		return this._http.post(`${this.paymentServerUrl}subscription/hire/payment/reactivate`, {});
	}

	// change name
	changeUserName(nameData: any) {
		return this._http.post(`${this.serverUrl}account/changeName`, nameData);
	}

	// change name
	changeLinkedin(nameData: any) {
			return this._http.post(`${this.serverUrl}account/changeLinkedin`, nameData);
	}

	// change name
	changeOther(nameData: any) {
		return this._http.post(`${this.serverUrl}account/changeOther`, nameData);
	}

	// make admin user profile
	makeAdminUserProfile(memData: any) {
		return this._http.post(`${this.serverUrl}account/member/changeAccountType`, memData);
	}

	// revoke invitation
	revokeInvitation(memData: any) {
		return this._http.post(`${this.serverUrl}account/invitation/invoke`, memData);
	}

	// deactivate user account
	deactivateUserProfile() {
		return this._http.post(`${this.serverUrl}account/member/deactivate`, {});
	}

	// delete comapany data
	companyDeactivate() {
		return this._http.post(`${this.serverUrl}account/deactivate`, {});
	}

	// cancel subscription
	cancelSubscription() {
		return this._http.post(`${this.paymentServerUrl}subscription/hire/payment/cancel`, {});
	}

}
