import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { ShowSettingsZoomComponent } from '../show-settings-zoom/show-settings-zoom.component';

@Component({
	selector: 'interview-details',
	templateUrl: './interview-details.component.html',
	styleUrls: ['./interview-details.component.scss']
})
export class InterviewDetailsComponent implements OnInit {

	@Input() context: string = 'INTERVIEW';
	@Input() selectedCandidates: any[] = [];
	@Input() zoomidhire: string = '';

	@Output() moveBackward: EventEmitter<any> = new EventEmitter<any>();
	@Output() moveForward: EventEmitter<any> = new EventEmitter<any>();

	// for auto complete
	public index: number = 1;
	public interview: any = {};
	public selectedJobTitle: string = '';
	public zoomidhireOri: string = '';
	public zoomidhireCopy: string = '';
	public zoom_pattern: string = '999-99999999';
	public showZoomEdit: boolean = false;

	public people: any[] = [
		{
			label: 'TODAY',
		},
		{
			label: 'FULL_NAME',
		},
		{
			label: 'FIRST_NAME',
		},
		{
			label: 'LAST_NAME',
		},
	];

	constructor(private _modalService: NgbModal,
		private _cookieService: CookieService) { }

	ngOnInit(): void {
		this.interview.zoomidhire = this._cookieService.get('zoom_Hire');
		this.interview.message = 'Date: $TODAY$'+'\n';
		this.zoomidhireOri = `${this.zoomidhire}`;
		this.selectedJobTitle = this.selectedCandidates[0]?.position || '';
	}

	searchPeople(searchString: string, items: any[]): any[] {
		return items.filter(item => item.label.toLowerCase().includes(searchString));
	}

	setJobTitleKeyWord(val: string): void {
		//console.log(val);
		this.selectedJobTitle = val;
		this.interview.title = val;
	}

	getPeopleText(item: any): any {
		if (item.label === 'TODAY') {
			return moment(new Date()).format('YYYY-MM-DD @ h:mm:ss a');
		} else {
			return '$' + item.label + '$';
		}
	}

	// get remote id zoom
	getRemoteZoomID(): boolean {
		return this._cookieService.check('zoom_Hire');
	}

	onVirtualInterviewChange(): void {
		if (this.interview.is_remote_interview && !this.getRemoteZoomID()) {
			this.showSettingsZoom();
		} else {
			this.zoomidhireCopy = this.interview.zoomidhire;
		}
	}

	// show zoom settings
	showSettingsZoom(): void {
		this.interview.is_remote_interview = false;
		let modal = this._modalService.open(ShowSettingsZoomComponent, { backdrop: 'static', keyboard: false, windowClass: 'cancel-info right-info' });
	}

}
