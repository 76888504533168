import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../../core/services/user.service';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'view-list',
	templateUrl: './view-list.component.html',
	styleUrls: ['./view-list.component.scss']
})
export class ViewListComponent implements OnInit {

	@Input() object: any = {};
	@Output() onDeleteList: EventEmitter<any> = new EventEmitter<any>();
	@Output() onUpdateMainList: EventEmitter<boolean> = new EventEmitter<boolean>();

	public fileServerUrl: string = environment.urls.fileServerUrl;

	public changeListState: boolean = true;
	public changeListWarning: boolean = true;
	public candidateLists: any = {};

	private _tempRemoveList: any[] = [];

	constructor(private _modal: NgbActiveModal,
		private _userService: UserService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {
		this.object.fileServerUrl = this.fileServerUrl;
		this.candidateLists = { ...this.object };
	}

	// call delete list
	deleteList(id: any, titile: any): void {
		this.onDeleteList.emit({ id, titile, object: this.object });
	}

	// remove list item
	removeListItems(candidate: any): void {
		this.changeListState = false;
		this.changeListWarning = false;
		let index = this.candidateLists.Candidates.indexOf(candidate);
		this._tempRemoveList.push(this.candidateLists.Candidates[index]);
		this.candidateLists.Candidates.splice(index, 1);
	}

	// show page error
	remindSaveList(): void {
		this._toastr.error("Are you sure you want to close this dialog?");
		this.changeListWarning = false;
	}

	// save list
	saveList(listID: any): void {
		// create array
		let listData: any = {};
		listData.New_users = [];
		listData.Removed_users = [];
		listData.Search_list_id = listID;

		this._tempRemoveList.forEach(function (value, key) {
			let Removed_users: any = {};
			Removed_users.User_id = value.id;
			listData.Removed_users.push(Removed_users);
		});

		// call updateList service
		this._userService.updateList(listData).subscribe((response: any) => {
			// check response
			if (response.status === 200) {
				this.onUpdateMainList.emit(true);
			}
			this._toastr.success("List saved");
			this.closeThisDialog();
		});
	}

	// start new conversation
	startConversation(userData: any): void {
		let date = new Date();
		// clear local storage before add data
		localStorage.clear();
		// set values in local storage
		localStorage.setItem(<string>new Md5().appendStr(date.toISOString().slice(0, -5)).end(), JSON.stringify(userData));
		// change location path to messages
		window.open(("/messages/" + new Md5().appendStr(date.toISOString().slice(0, -5)).end()), '_blank');
	}

	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
