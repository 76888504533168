import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {

	private _env = environment;
	private _renderer2: Renderer2;

	constructor(private _rendererFactory2: RendererFactory2,
		@Inject(DOCUMENT) private _document: Document,
		private _router: Router,) {
		this._renderer2 = this._rendererFactory2.createRenderer(null, null);
	}

	initialize(): void {
		if (this._env.production && this._env.keys.ga_key) {
			this._insertGAScript();
			this._trackPageViews().subscribe();
		}
	}

	private _insertGAScript(): void {
		const script: HTMLScriptElement = this._renderer2.createElement('script');
		script.type = 'text/javascript';
		script.onload = this._insertGARunScript.bind(this);
		script.src = `https://www.googletagmanager.com/gtag/js?id=${this._env.keys.ga_key}`;
		script.text = '';
		this._renderer2.appendChild(this._document.head, script);
	}

	private _insertGARunScript(): void {
		const script: HTMLScriptElement = this._renderer2.createElement('script');
		script.type = 'text/javascript';
		script.text = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${this._env.keys.ga_key}', { send_page_view: false });`;
		this._renderer2.appendChild(this._document.head, script);
	}

	private _trackPageViews(): Observable<void> {
		return this._router.events.pipe(
			map((event) => {
				if (event instanceof NavigationEnd) {
					gtag('config', this._env.keys.ga_key, { page_path: event.urlAfterRedirects });
				}
			})
		);
	}

}
