<div class="modal-header">
	<h4 class="modal-title" id="modal-title">{{header}}</h4>
</div>
<div class="modal-body">
	<div *ngIf="contentType === 'Text'">{{msg}}</div>
	<div *ngIf="contentType === 'HTML'" [innerHTML]="msg"></div>
	<textarea *ngIf="showMessageBox" class="form-control" id="reschedule-text-area" rows="5" [ngClass]="{error: remaining() < 0}"
      [(ngModel)]="message" [maxlength]="2000"></textarea>
    <span *ngIf="showMessageBox && remaining() < 0">{{ remaining() }}</span>
</div>
<div class="modal-footer text-right">
	<div class="button-wrapper">
		<button *ngIf="showNoButton" type="button" class="btn popup-footer-cancel-button {{noButtonClass}}" (click)="no()">{{noButtonText}}</button>
		<button *ngIf="showYesButton" type="button" class="btn popup-footer-yes-button {{yesButtonClass}}" (click)="yes()">{{yesButtonText}}</button>
	</div>
</div>
