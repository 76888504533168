import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { JobBoardService } from '../../../core/services/jobBoard.service';
import { UtilityService } from '../../../core/services/utility.service';

@Component({
	selector: 'show-keywords',
	templateUrl: './show-keywords.component.html',
	styleUrls: ['./show-keywords.component.scss']
})
export class ShowKeywordsComponent implements OnInit {

	@Input() userTitle: any;
	@Input() vacancyID: any;
	@Input() applicant: any;

	public showAllApplicantsPreloader: boolean = true;
	public keywords: any;
	public tagcount: any;
	public ttagscount: any;
	public tagsresumescore: any;

	constructor(public utilityService: UtilityService,
		private _modal: NgbActiveModal,
		private _jobBoardService: JobBoardService,
		private _toastr: ToastrService) { }

	ngOnInit(): void {
		// call job board service to get all apllicants
		this._jobBoardService.keywordCount(this.vacancyID, this.applicant.recruit_id).subscribe((response: any) => {
			if (response.status === 200 && response.Code === 200) {
				this.keywords = response.Data.tagscorelist;
				this.tagcount = response.Data.tagcount;
				this.ttagscount = response.Data.ttagscount;
				this.tagsresumescore = response.Data.tagsresumescore;
				this.showAllApplicantsPreloader = false;
			} else {
				// show error message
				this._toastr.error('No Keywords to Count');
			}
		});
	}

	closeThisDialog(): void {
		this._modal.dismiss();
	}

}
