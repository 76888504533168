import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	public HIRE_LANDING_BASE = environment.urls.HIRE_LANDING_BASE;

	public currentYear = new Date().getFullYear();

	constructor(private _router: Router) { }

	ngOnInit(): void {

	}

	getCurrentUrl(): string {
		return this._router.url;
	}


}
