import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'filter',
	pure: false
})

export class FilterPipe implements PipeTransform {

	transform(array: any[], prop?: string | undefined, term?: string | boolean): any[] {
		//check if search term is undefined
		if (term === undefined) return array;
		//return updates array
		return array.filter((item) => {
			if (typeof term === 'string') {
				return prop ? item[prop].toLowerCase().includes(term.toLowerCase()) : item.toLowerCase().includes(term.toLowerCase());
			}
			if (typeof term === 'boolean') {
				return prop ? item[prop] === term : item === term;
			}
		});
	}
}
