import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class JobBoardService {

	private serverUrl = environment.urls.serverUrl;
	private hireothUrl = environment.urls.hireothUrl;

	constructor(private _http: HttpClient) { }

	getjobList(keyword: any, place: any, page: any, user_id: any) {

		let searchParameters = '';
		keyword ? searchParameters += '&q=' + keyword.toString() : '';
		place ? searchParameters += '&location=' + place.toString() : '';
		user_id ? searchParameters += '&filterUserId=' + user_id.toString() : '';

		return this._http.get(`${this.serverUrl}jobboard/vacancy/getAllVacancies?page=${page.toString()}${searchParameters}`);
	}

	getarchivedjobList(keyword: any, place: any, page: any, user_id: any) {

		let searchParameters = '';
		keyword ? searchParameters += '&q=' + keyword.toString() : '';
		place ? searchParameters += '&location=' + place.toString() : '';
		user_id ? searchParameters += '&filterUserId=' + user_id.toString() : '';

		return this._http.get(`${this.serverUrl}jobboard/vacancy/getArchiveVacancies?page=${page.toString()}${searchParameters}`);
	}

	getSingleJobData(jobID: any) {
		return this._http.get(`${this.serverUrl}jobboard/vacancy/getDetails/${jobID}`);
	}

	generateAiJobDescr(title: any) {
		return this._http.get(`${this.hireothUrl}jobboard/getaiDescr/${title.toString()}`);
	}

	searchPreviousJobs(jobID: any, job_title: any, start: any, end: any, keyword: any) {
		let searchQuery = '';
		if (jobID) {
			searchQuery += '&vacancyId=' + jobID;
		} else {
			searchQuery += '&vacancyId=0';
		}
		if (job_title) {
			searchQuery += '&title=' + job_title;
		}
		if (keyword) {
			searchQuery += '&keyword=' + keyword;
		}
		return this._http.get(`${this.serverUrl}jobboard/vacancy/autocomplete?from=${start}&count=${end}${searchQuery}`);
	}

	publishJobPosition(jobID: any) {
		return this._http.post(`${this.serverUrl}jobboard/vacancy/${jobID}/publish`, {});
	}

	unpublishJobPosition(jobID: any) {
		return this._http.post(`${this.serverUrl}jobboard/vacancy/${jobID}/unpublish`, {});
	}

	autoInviteCandidate(jobID: any) {
		return this._http.post(`${this.hireothUrl}jobboard/vacancy/${jobID}/schedule`, {});
	}


	createNewInvite(inviteData: any, jobID: any, source: any) {
		return this._http.post(`${this.hireothUrl}jobboard/vacancy/${source}/invite/${jobID}`, inviteData);
	}

	createNewEmailInvite(inviteData: any, jobID: any, source: any) {
		return this._http.post(`${this.hireothUrl}jobboard/vacancy/${source}/einvite/${jobID}`, inviteData);
	}

	republishJobPosition(jobData: any) {
		return this._http.post(`${this.serverUrl}jobboard/vacancy/republish`, jobData);
	}

	getAllApplicantsData(jobID: any, page: any) {
		return this._http.get(`${this.serverUrl}jobboard/vacancy/getApplicants/${jobID}?page=${page}`);
	}

	applicantMarkRead(jobID: any, recruitID: any) {

		let data: any = {
			vacancy_id: jobID,
			recruit_id: recruitID
		};

		return this._http.post(`${this.serverUrl}jobboard/vacancy/${jobID}/read/${recruitID}`, data);
	}

	rescoreCandidate(jobID: any, recruitID: any, status: any) {

		let data: any = {
			vacancy_id: jobID,
			recruit_id: recruitID,
			status: status
		};

		return this._http.post(`${this.hireothUrl}jobboard/vacancy/${jobID}/rescore/${recruitID}`, data);
	}

	resetscoreCandidate(jobID: any, recruitID: any, status: any) {

		let data: any = {
			vacancy_id: jobID,
			recruit_id: recruitID,
			status: status
		};

		return this._http.post(`${this.hireothUrl}jobboard/vacancy/${jobID}/resetscore/${recruitID}`, data);
	}

	keywordCount(jobID: any, recruitID: any) {
		return this._http.get(`${this.hireothUrl}jobboard/vacancy/${jobID}/keyCount/${recruitID}`);
	}

	ResumekeywordCount(jobID: any, attachData: any) {
		return this._http.post(`${this.hireothUrl}jobboard/vacancy/${jobID}/RkeyCount`, attachData);
	}

	candidateSearch(jobID: any) {
		return this._http.get(`${this.hireothUrl}jobboard/vacancy/sTalent/${jobID}`);
	}

	resumesSearch(jobID: any) {
		return this._http.get(`${this.hireothUrl}jobboard/vacancy/rTalent/${jobID}`);
	}

	getJobNotes(jobID: any) {
		return this._http.get(`${this.hireothUrl}jobboard/vacancy/getJobNotes/${jobID}`);
	}

	saveJobNotes(jobID: any, showNotes: any, isPrivate: any) {

		let data: any = {
			vacancy_id: jobID,
			jnotes: showNotes,
			jprivate: isPrivate
		};

		return this._http.post(`${this.hireothUrl}jobboard/vacancy/pnotes/${jobID}`, data);
	}

	getJobApplicantNotes(jobID: any, recruitID: any) {
		return this._http.get(`${this.hireothUrl}jobboard/vacancy/${jobID}/getJobAppNotes/${recruitID}`);
	}

	saveJobApplicantNotes(jobID: any, recruitID: any, showNotes: any, isPrivate: any) {

		let data: any = {
			vacancy_id: jobID,
			recruit_id: recruitID,
			jnotes: showNotes,
			jprivate: isPrivate
		};

		return this._http.post(`${this.hireothUrl}jobboard/vacancy/${jobID}/pnotes/${recruitID}`, data);
	}

	rejectCandidate(jobID: any, recruitID: any) {

		let data: any = {
			vacancy_id: jobID,
			recruit_id: recruitID
		};

		return this._http.post(`${this.hireothUrl}jobboard/vacancy/${jobID}/reject/${recruitID}`, data);
	}

	getJobInvites(jobID: any) {
		return this._http.get(`${this.hireothUrl}jobboard/vacancy/getJobInvites/${jobID}`);
	}

	getLnkdSearch(jobID: any) {
		return this._http.get(`${this.hireothUrl}jobboard/vacancy/getLnkdSearch/${jobID}`);
	}

	removeLnProfile(sourceID: any) {
		return this._http.post(`${this.hireothUrl}jobboard/vacancy/removeLnPro/${sourceID}`, {});
	}

	createInvitesList(jobID: any) {
		return this._http.post(`${this.hireothUrl}jobboard/vacancy/createInvitesList/${jobID}`, {});
	}

	removeCandidate(jobID: any, candidate: any) {

		let data: any = {
			resumeurl: candidate.resume
		};

		return this._http.post(`${this.hireothUrl}jobboard/vacancy/${jobID}/rRemove?doc=${candidate.storage.toString()}`, data);
	}

	checkEmailExists(email: any) {
		return this._http.get(`${this.hireothUrl}account/recruit/CheckEmail?doc=${email.toString()}`);
	}

	sendTalentCreateInvite(jobID: any, talentData: any) {
		return this._http.post(`${this.hireothUrl}account/recruit/${jobID}/SendEmail`, talentData);
	}

	sendTalentCreateInvite2(talentData: any) {
		return this._http.post(`${this.hireothUrl}account/recruit/0/SendEmail`, talentData);
	}

	getApplicantResponseData(jobID: any, recruitID: any) {
		return this._http.get(`${this.serverUrl}jobboard/vacancy/${jobID}/user/${recruitID}/response`);
	}

	getAllQuestionsData(questionsStartFrom: any, questionsLoadCount: any) {
		return this._http.get(`${this.serverUrl}jobboard/vacancy/questions?from=${questionsStartFrom}&count=${questionsLoadCount}`);
	}

	addJobPositionData(jobData: any) {
		return this._http.post(`${this.serverUrl}jobboard/vacancy/create`, jobData);
	}

	editJobPositionData(jobData: any, viewStatus: any, jobID: any, removeApplicants: any) {
		let edit_job_endpoint;
		if (viewStatus == 1) {
			if (removeApplicants) {
			edit_job_endpoint = `jobboard/vacancy/${jobID}/form/edit?removeApplicants=true`
			} else {
			edit_job_endpoint = `jobboard/vacancy/${jobID}/form/edit?removeApplicants=false`
			}
		} else {
			edit_job_endpoint = `jobboard/vacancy/basic/edit`
		}

		return this._http.post(`${this.serverUrl}${edit_job_endpoint}`, jobData);
	}

	validateJobQuestionsData(questionsData: any, jobID: any) {
		return this._http.post(`${this.serverUrl}jobboard/vacancy/${jobID}/form/validate`, questionsData);
	}

	archiveJobPositionData(jobID: any) {
		return this._http.post(`${this.serverUrl}jobboard/vacancy/archive/${jobID}`, {});
	}

	deleteJobPositionData(jobID: any) {
		return this._http.post(`${this.serverUrl}jobboard/vacancy/remove/${jobID}`, {});
	}

	getAllJobs(apiKey: any) {
		return this._http.get(`${this.serverUrl}jobboard/vacancy/getCompanyVacancies/${apiKey}`);
	}

	linkedinPost(data: any) {
		return this._http.post(`https://api.linkedin.com/v2/simpleJobPostings`, data);
	}

	fetchJobScoreTags(jobID: any) {
		return this._http.get(`${this.hireothUrl}jobboard/vacancy/fetchtags/${jobID}`);
	}

}
