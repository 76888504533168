import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: 'time-slots',
	templateUrl: './time-slots.component.html',
	styleUrls: ['./time-slots.component.scss']
})
export class TimeSlotComponent implements OnInit {

	@Input() settime: any = {};
	@Input() previewClose: boolean = true;
	@Input() abbrtext: string = '';

	@Output() removeTimeSlots: EventEmitter<any> = new EventEmitter<any>();

	constructor() { }

	ngOnInit(): void {

	}

}
