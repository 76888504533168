<div class="pop-up-content">
	<p class="interview-select-title">Preview Offer</p>
	<div class="job-details-wrapper offers">
		<div class="col-lg-6 col-md-6">
			<label class="job-title">Job Title</label>
			<p class="job-title-requsted">
				{{offerData.job_title | ellipsis: 30}}
			</p>
		</div>
		<div class="col-lg-6 col-md-6">
			<label class="job-type">Job Type</label>
			<p class="job-type-requsted" *ngIf="offerData.job_type === '1' ">
				Employee
			</p>
			<p class="job-type-requsted" *ngIf="offerData.job_type === '2' ">
				Internship
			</p>
			<p class="job-type-requsted" *ngIf="offerData.job_type === '3' ">
				Contract
			</p>
			<p class="job-type-requsted" *ngIf="offerData.job_type === '4' ">Volunteer</p>
		</div>
		<div class="col-lg-6 col-md-6" style="margin-top: 5px;">
			<label class="job-title">Salary</label>
			<p class="job-title-requsted">
				{{offerData.salary | currency}}
				<span *ngIf="offerData.payment_type === '1'">(per Hour)</span>
				<span *ngIf="offerData.payment_type === '2'">(per Year)</span>
				<span *ngIf="offerData.payment_type === '3'">(per Week)</span>
				<span *ngIf="offerData.payment_type === '4'">(per Bi-Weekly)</span>
				<span *ngIf="offerData.payment_type === '5'">(per Semi-Monthly)</span>
				<span *ngIf="offerData.payment_type === '6'">(per Month)</span>
			</p>
		</div>
	</div>
</div>
<div class="preview-content offers">
	<div class="candidates-wrapper">
		<label>Candidate(s)</label>
		<div class="inner-candidates-wrapper">
			<preview-candidates *ngFor="let candidateList of selectedCandidates" [candidate]="candidateList">
			</preview-candidates>
		</div>
	</div>
	<div class="message-content-wrapper offers">
		<label>Message</label>
		<div class="preview-message">
			<!-- <p class="title">Hi $candidate</p> -->
			<p class="msg-content word-wrap">{{offerData.offer_message}}</p>
		</div>
		<div class="attachements">
			<div *ngFor="let attachement of offerData.attachments">
				<a href="{{utilityService.getCdnUrl(attachement.attachment_url)}}" download>
					<p *ngIf="attachement.attachment_type === 1">
						<span class="text"></span>{{attachement.file_name}}
					</p>
				</a>
				<a href="{{utilityService.getCdnUrl(attachement.attachment_url)}}" download>
					<p *ngIf="attachement.attachment_type === 2">
						<span class="pdf"></span>{{attachement.file_name}}
					</p>
				</a>
				<a href="{{utilityService.getCdnUrl(attachement.attachment_url)}}" download>
					<p *ngIf="attachement.attachment_type === 6">
						<span class="doc"></span>{{attachement.file_name}}
					</p>
				</a>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer pop-up-footer">
	<button type="submit" class="btn btn-back" (click)="moveBackward.emit(1)" tabindex="0">
		BACK
	</button>
	<button type="button" class="ngdialog-button popup-footer-save-button" [disabled]="isDisabled"
		(click)="this.disableAndDismiss(true); isDisabled = true; sendNewOffer.emit()" tabindex="0">
		SEND
	</button>
</div>
