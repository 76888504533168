<header>
	<!-- Gradient background -->
	<div class="header-background"></div>
	<!-- End gradient background -->
	<!-- Navbar -->
	<nav class="navbar navbar-unnanu-hire-app">
		<div class="home-container container">
			<div class="navbar-header topNavigationBar app-langing-topnavbar">
				<a class="navbar-brand" [routerLink]="['/']"><img src="../../../assets/images/logo-unnanu-hire.png"
						width="130"></a>
			</div>
			<div id="navbar" class="navbar-collapse collapse" *ngIf="cookieService.check('token_Hire')">
				<ul class="account-menu nav navbar-nav navbar-right">
					<li><a (click)="logout()">Sign Out</a></li>
				</ul>
			</div>
		</div>
	</nav>
	<!-- End navbar -->
</header>
