import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'file-size-exceed-alert',
	templateUrl: './file-size-exceed-alert.component.html',
	styleUrls: ['./file-size-exceed-alert.component.scss']
})
export class FileSizeExceedAlertComponent {

	@Input() msg: string = '';
	@Input() errorStatus: string = '';

	constructor(public modal: NgbActiveModal) { }

	close() {
		this.modal.dismiss();
	}

}
