import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';

@Injectable()
export class InterviewService {

	private messageUrl = environment.urls.messageUrl;
	private serverUrl = environment.urls.serverUrl;

	constructor(private http: HttpClient) { }


	createInterview(interviewData: any) {
		return this.http.post(`${this.messageUrl}interviews/hire/create`, interviewData);
	}

	getInterviewsDataSet(interviewType: any, scrollNo: any) {
		return this.http.get(`${this.messageUrl}interviewsV12/hire/get/${interviewType}?lastIndex=${(scrollNo * 10)}`);
	}

	getInterviewDataSetCount() {
		return this.http.get(`${this.messageUrl}interviewsV12/hire/get/count`);
	}

	getInterviewData() {
		return this.http.get(`${this.messageUrl}interviews/hire/get`);
	}

	getTimeZoneList() {
		// check current date to get corect timezone data list
		let timeZoneJson = '';
		let secondSunMarch = new Date(new Date().getFullYear(), 2, 7 + (7 - new Date(new Date().getFullYear(), 2, 7).getDay()));
		let firstSunNov = new Date(new Date().getFullYear(), 10, (7 - new Date(new Date().getFullYear(), 2, 0).getDay()));
		let currentDate = moment(moment(new Date()).format('DD/MM/YYYY'), 'DD/MM/YYYY');

		let startDate = moment(moment(secondSunMarch).format('DD/MM/YYYY'), 'DD/MM/YYYY');
		let endDate = moment(moment(firstSunNov).format('DD/MM/YYYY'), 'DD/MM/YYYY');
		if (startDate <= currentDate && currentDate <= endDate) {
			timeZoneJson = '../../../assets/data/timezones_daylight.json';
		} else {
			timeZoneJson = '../../../assets/data/timezones_standard.json';
		}
		return this.http.get(timeZoneJson).pipe(map((response: any) => {
			delete response['status'];
			return Object.values(response);
		}));
	}

	saveRescheduleData(rescheduleData: any) {
		return this.http.post(`${this.messageUrl}interviews/hire/reschedule`, rescheduleData);
	}

	cancelInterview(interviewData: any) {
		return this.http.post(`${this.messageUrl}interviews/hire/cancel`, interviewData);
	}

	removeInterview(interviewData: any) {
		return this.http.post(`${this.messageUrl}interviews/hire/remove`, interviewData);
	}

	getPastInterviewData() {
		return this.http.get(`${this.messageUrl}interviews/hire/past/get`);
	}

	getPastInterviewDataV2(fromID: any, userID: any) {
		return this.http.get(`${this.messageUrl}interviewsV12/hire/past/get?from=${(fromID * 10)}&userId=${userID}`);
	}

	getPastInterviewUserList() {
		return this.http.get(`${this.messageUrl}interviewsV12/hire/companyMembers/get`);
	}

	getSingleInterviewData(interviewID: any) {
		return this.http.get(`${this.messageUrl}interviews/getDetails/${interviewID}`);
	}

	updateZoomId(zoomId: any) {

		let data: any = {
			'remote_id': zoomId
		};

		return this.http.post(`${this.serverUrl}account/add/remoteId`, data);
	}

}
